import { Box, Button, Flex, FormControl, Heading, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { SKALPSLogo } from "src/components/shared/SKALPSLogo"
import { ErrorMessageField } from "src/components/ui/ErrorMessageField"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { ILoginFormFields } from "."

interface Props {
	handleSubmit: FormikOnSubmit<ILoginFormFields>
}

export const LoginFormView: FC<Props> = ({ handleSubmit }) => (
	<Box
		padding={20}
		borderWidth={1}
		borderRadius={15}
		backgroundColor="white"
		boxShadow="1px 1px 30px #eee"
	>
		<Formik<ILoginFormFields>
			initialValues={{ username: "", password: "" }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ handleChange, isSubmitting }) => (
				<Stack as={Form} maxWidth={"sm"}>
					<Box textAlign={"center"}>
						<SKALPSLogo />
						<Heading as="h1" size="md" color={"gray.600"}>
							Login to continue
						</Heading>

						<br />
					</Box>
					<Flex direction={"column"} gridGap={"2"}>
						{/* Username */}
						<FormControl>
							<InputLabel label="Username" />
							<Input
								name="username"
								onChange={handleChange}
								isRequired
								autoFocus
								maxLength={10}
							/>
							<ErrorMessage component={ErrorMessageField} name="username" />
						</FormControl>
						{/* Password */}
						<FormControl>
							<InputLabel label="Password" />
							<Input
								type="password"
								name="password"
								isRequired
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="password" />
						</FormControl>
					</Flex>
					<Box py="2">
						{/* Login Button */}
						<Button
							colorScheme={"blue"}
							type="submit"
							disabled={isSubmitting}
							isLoading={isSubmitting}
							width={"full"}
						>
							Login
						</Button>
					</Box>
				</Stack>
			)}
		</Formik>
	</Box>
)
