import { IHallBookingReceipt } from "src/domain/entities/hallBookingReceipt"
import { HallBookingReceiptCategorySlugs } from "src/utils/enums"
import { hallBookingReceiptListApi } from "../../api/hallBookingReceipt/hallBookingReceiptListApi"

type PayloadShape = Parameters<typeof hallBookingReceiptListApi>[0]

export async function hallBookingReceiptListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const hallReceipt = await hallBookingReceiptListApi(
		{
			...payload,
			categorySlugs: [
				HallBookingReceiptCategorySlugs.PARTY_PLOT_BHADA_DEPOSIT_RECEIPT,
				HallBookingReceiptCategorySlugs.VAADI_BHADA_DEPOSIT_RECEIPT,
			],
		},
		token,
	)
	return hallReceipt as NonNullable<IHallBookingReceipt[]>
}
