import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IContact } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"

export const ContactListView: FC<{
	list: IContact[]
	onUpdate: (contact: IContact) => void
	onDelete: (contact: IContact) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Sr No.</Th>
				<Th>Name</Th>
				<Th>Contact No.</Th>
				<Th>Category</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((contact, i) => (
				<Tr key={i}>
					<Td>{i + 1}</Td>
					<Td>{getFullName(contact)}</Td>
					<Td>{contact.mobile}</Td>
					<Td>{contact.category?.name}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(contact)} />
						<DeleteIconButton onClick={() => onDelete(contact)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
