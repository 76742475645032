import { useCallback, useState } from "react"
import { IIncomeCategory } from "src/domain/entities"
import { incomeCategoryListService } from "src/domain/services/incomeCategory/incomeCategoryListService"
import { useAuth } from ".."

export function useIncomeCategoryListService(): {
	incomeCategoryList: IIncomeCategory[]
	isLoading: boolean
	fetchIncomeCategoryList: (
		filter?: Parameters<typeof incomeCategoryListService>[0],
	) => Promise<IIncomeCategory[]>
} {
	const { token } = useAuth()
	const [incomeCategoryList, setIncomeCategoryList] = useState<IIncomeCategory[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchIncomeCategoryList = useCallback(
		async (filter: Parameters<typeof incomeCategoryListService>[0] = {}) => {
			setIsLoading(true)
			const data = await incomeCategoryListService(filter, token)
			setIncomeCategoryList(data)
			setIsLoading(false)
			return data
		},
		[token],
	)

	return { incomeCategoryList, isLoading, fetchIncomeCategoryList }
}
