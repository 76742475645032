import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, SetStateAction } from "react"
import { FormDynamicField } from "src/components/shared/FormDynamicField"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IIncome, IIncomeCategory } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IIncomeUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	income: IIncome
	incomeCategoryList: IIncomeCategory[]
	handleSubmit: FormikOnSubmit<IIncomeUpdateFormFields>
	selectedIncomeCategory?: IIncomeCategory
	setSelectedIncomeCategory: Dispatch<SetStateAction<IIncomeCategory | undefined>>
}

export const IncomeUpdateDrawerFormView: FC<Props> = ({
	income,
	handleSubmit,
	incomeCategoryList,
	selectedIncomeCategory,
	setSelectedIncomeCategory,
	...rest
}) => (
	<Formik<IIncomeUpdateFormFields>
		initialValues={{
			categoryId: selectedIncomeCategory?.id,
			date: income.date,
			incomeNo: income.incomeNo,
			fields:
				selectedIncomeCategory?.fields
					?.sort((a, b) => a.position - b.position)
					?.map((field) => {
						const value = income.fields?.find(
							(f) => f.field.id === field.id,
						)?.value
						return {
							fieldId: field.id,
							value,
						}
					}) ?? [],
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const incomeCategoryOptions = incomeCategoryList.map((incomeCategory) => ({
				label: incomeCategory.name,
				value: incomeCategory.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Income"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* IncomeCategory */}
							<FormControl>
								<InputLabel label="IncomeCategory" />
								<ReactSelect
									name="categoryId"
									value={incomeCategoryOptions.find(
										(option) => option.value === values.categoryId,
									)}
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
										setSelectedIncomeCategory(
											incomeCategoryList.find(
												(category) =>
													category.id ===
													(newValue as SelectOption).value,
											),
										)
									}}
									options={incomeCategoryOptions}
									isSearchable
								/>
							</FormControl>
							{/* Income No. */}
							<FormControl>
								<InputLabel label="Income No." />
								<Input
									name="incomeNo"
									maxLength={50}
									value={values.incomeNo}
									required
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="incomeNo"
								/>
							</FormControl>
						</Flex>
						<Flex gridRowGap={2} direction="column">
							{selectedIncomeCategory?.fields
								?.sort((a, b) => a.position - b.position)
								?.map((field, i) => {
									return (
										<FormDynamicField
											key={field.id}
											field={field}
											value={values.fields?.[i]}
											controlName={`fields[${i}]`}
											handleChange={handleChange}
											setFieldValue={setFieldValue}
										/>
									)
								})}
						</Flex>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
