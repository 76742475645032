import { IIncome } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	categoryId?: string
	search?: string
	searchFields?: string[]
	searchCategoryId?: string
	categorySlugs?: string[]
	pagination?: {
		page: number
		limit: number
	}
	fetch?: {
		category: boolean
	}
}

export async function incomeListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/income/list/v1"
	const requester = new ApiRequester<RequestDataShape, IIncome[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
