import { useCallback, useState } from "react"
import { IContactCategory } from "src/domain/entities"
import { contactCategoryListService } from "src/domain/services/contactCategory/contactCategoryListService"
import { useAuth } from ".."

export function useContactCategoryListService(): {
	contactCategoryList: IContactCategory[]
	isLoading: boolean
	fetchContactCategoryList: (
		filter?: Parameters<typeof contactCategoryListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [contactCategoryList, setContactCategoryList] = useState<IContactCategory[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchContactCategoryList = useCallback(
		async (filter: Parameters<typeof contactCategoryListService>[0] = {}) => {
			setIsLoading(true)
			const data = await contactCategoryListService(filter, token)
			setContactCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { contactCategoryList, isLoading, fetchContactCategoryList }
}
