import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IItemCategoryAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IItemCategoryAddFormFields>
}

export const ItemCategoryAddDrawerFormView: FC<Props> = ({ handleSubmit, ...rest }) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IItemCategoryAddFormFields>
			initialValues={{ name: "", note: "", slug: "" }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Item Category"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridColumnGap={2}>
								{/* Name */}
								<FormControl>
									<InputLabel label="Category Name" />
									<Input
										name="name"
										placeholder="Name"
										maxLength={50}
										required
										autoFocus
										value={values.name}
										onChange={handleNameChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name"
									/>
								</FormControl>
								{/* Slug */}
								<FormControl>
									<InputLabel label="Slug" />
									<Input
										name="slug"
										placeholder="Slug"
										maxLength={50}
										required
										value={values.slug}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="slug"
									/>
								</FormControl>
							</Flex>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
