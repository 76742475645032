import { useCallback, useState } from "react"
import { IItemCategory } from "src/domain/entities"
import { itemCategoryListService } from "src/domain/services/itemCategory/itemCategoryListService"
import { useAuth } from ".."

export function useItemCategoryListService(): {
	itemCategoryList: IItemCategory[]
	isLoading: boolean
	fetchItemCategoryList: (
		filter?: Parameters<typeof itemCategoryListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [itemCategoryList, setItemCategoryList] = useState<IItemCategory[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchItemCategoryList = useCallback(
		async (filter: Parameters<typeof itemCategoryListService>[0] = {}) => {
			setIsLoading(true)
			const data = await itemCategoryListService(filter, token)
			setItemCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { itemCategoryList, isLoading, fetchItemCategoryList }
}
