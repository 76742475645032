import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IIncome } from "src/domain/entities"
import { IncomeListView } from "."

interface Props {
	list: IIncome[]
	isLoading: boolean
	onUpdate: (income: IIncome) => void
	onDelete: (income: IIncome) => void
	onAddClick: () => void
}

export const IncomeListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No incomes found." onAddClick={onAddClick} />
	}

	return <IncomeListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
