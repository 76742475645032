import { ComponentProps, FC, useCallback } from "react"
import { IStandard } from "src/domain/entities"
import { standardAddService } from "src/domain/services/standard/standardAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IStandardAddFormFields, StandardAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof StandardAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (standard: IStandard) => void
}

export const StandardAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IStandardAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const standard = await standardAddService(values, token)
				onSuccess(standard)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <StandardAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
