import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { useReceiptListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { IReceipt } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ReceiptAddDrawerFormController } from "./ReceiptAddDrawerForm"
import { ReceiptDeleteDialogController } from "./ReceiptDeleteDialogController"
import { ReceiptListController } from "./ReceiptList"
import { ReceiptUpdateDrawerFormController } from "./ReceiptUpdateDrawerForm"

export const ReceiptsPage: FC = () => {
	const { receiptList, isLoading, fetchReceiptList } = useReceiptListService()
	const [isReceiptDeleteDialogOpen, setIsReceiptDeleteDialogOpen] = useState(false)
	const receiptAddDrawerDisclosure = useDisclosure()
	const receiptUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchReceipts = useCallback(async () => {
		fetchReceiptList({
			fetch: { student: true, receiptItems: true, standard: true, payments: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
		})
	}, [fetchReceiptList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchReceipts()
	}, [fetchReceipts])

	const updateReceiptRef = useRef<IReceipt>()
	const deleteReceiptRef = useRef<IReceipt>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Receipts
					</Text>
					<Button
						size="sm"
						onClick={receiptAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Receipt
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				</Flex>
			</Box>
			<ReceiptListController
				list={receiptList}
				isLoading={isLoading}
				onUpdate={(receipt: IReceipt) => {
					updateReceiptRef.current = receipt
					receiptUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(receipt: IReceipt) => {
					deleteReceiptRef.current = receipt
					setIsReceiptDeleteDialogOpen(true)
				}}
				onAddClick={receiptAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={receiptList.length === 0}
			/>
			{receiptAddDrawerDisclosure.isOpen ? (
				<ReceiptAddDrawerFormController
					{...receiptAddDrawerDisclosure}
					onSuccess={() => fetchReceipts()}
				/>
			) : null}
			{updateReceiptRef.current && receiptUpdateDrawerDisclosure.isOpen ? (
				<ReceiptUpdateDrawerFormController
					{...receiptUpdateDrawerDisclosure}
					receipt={updateReceiptRef.current}
					onSuccess={() => fetchReceipts()}
				/>
			) : null}
			{deleteReceiptRef.current && (
				<ReceiptDeleteDialogController
					isOpen={isReceiptDeleteDialogOpen}
					setIsOpen={setIsReceiptDeleteDialogOpen}
					receipt={deleteReceiptRef.current}
					onSuccess={() => fetchReceipts()}
				/>
			)}
		</DashboardWrapper>
	)
}
