import { ComponentProps, FC, useCallback } from "react"
import { ITenure } from "src/domain/entities"
import { tenureAddService } from "src/domain/services/tenure/tenureAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { ITenureAddFormFields, TenureAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof TenureAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (tenure: ITenure) => void
}

export const TenureAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ITenureAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const tenure = await tenureAddService(values, token)
				onSuccess(tenure)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ term: { en: message } })
			}
		},
		[onSuccess, rest, token],
	)

	return <TenureAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
