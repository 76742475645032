import { ComponentProps, FC, useCallback } from "react"
import { IItemCategory } from "src/domain/entities"
import { itemCategoryAddService } from "src/domain/services/itemCategory/itemCategoryAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IItemCategoryAddFormFields, ItemCategoryAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof ItemCategoryAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (itemCategory: IItemCategory) => void
}

export const ItemCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IItemCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const itemCategory = await itemCategoryAddService(values, token)
				onSuccess(itemCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <ItemCategoryAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
