import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, SetStateAction } from "react"
import { FormDynamicField } from "src/components/shared/FormDynamicField"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IExpenseCategory } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IExpenseAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IExpenseAddFormFields>
	expenseCategoryList: IExpenseCategory[]
	expenseNextNumber: number
	getGujaratiSuggestions: (value: string) => Promise<string[]>
	selectedExpenseCategory?: IExpenseCategory
	setSelectedExpenseCategory: Dispatch<SetStateAction<IExpenseCategory | undefined>>
}

export const ExpenseAddDrawerFormView: FC<Props> = ({
	expenseNextNumber,
	handleSubmit,
	expenseCategoryList,
	getGujaratiSuggestions,
	selectedExpenseCategory,
	setSelectedExpenseCategory,
	...rest
}) => {
	return (
		<Formik<IExpenseAddFormFields>
			initialValues={{
				categoryId: selectedExpenseCategory?.id ?? "",
				expenseNo: expenseNextNumber,
				date: dayjs().format("YYYY-MM-DD") as any,
				fields:
					selectedExpenseCategory?.fields?.map((field) => ({
						fieldId: field.id,
						value: "",
					})) ?? [],
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				return (
					<DrawerForm
						size="lg"
						headerLabel="Add Expense"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
							{/* ExpenseCategory */}
							<FormControl>
								<InputLabel label="ExpenseCategory" />
								<ReactSelect
									name="categoryId"
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
										setSelectedExpenseCategory(
											expenseCategoryList.find(
												(category) =>
													category.id ===
													(newValue as SelectOption).value,
											),
										)
									}}
									options={expenseCategoryList.map(
										(expenseCategory) => ({
											label: expenseCategory.name,
											value: expenseCategory.id,
										}),
									)}
									isSearchable
								/>
							</FormControl>
							<Flex gridColumnGap={2}>
								{/* Date */}
								<FormControl>
									<InputLabel label="Date" />
									<Input
										type="date"
										name="date"
										maxLength={50}
										defaultValue={values.date}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="receiptDate"
									/>
								</FormControl>
								{/* Expense No. */}
								<FormControl>
									<InputLabel label="Expense No." />
									<Input
										name="expenseNo"
										maxLength={50}
										required
										autoFocus
										value={values.expenseNo}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="expenseNo"
									/>
								</FormControl>
							</Flex>
							<Flex gridRowGap={2} direction="column">
								{selectedExpenseCategory?.fields
									?.sort((a, b) => a.position - b.position)
									.map((field, i) => {
										return (
											<FormDynamicField
												key={field.id}
												field={field}
												value={values.fields[i]}
												controlName={`fields[${i}]`}
												handleChange={handleChange}
												setFieldValue={setFieldValue}
											/>
										)
									})}
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
