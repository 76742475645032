import dayjs from "dayjs"
import { expenseUpdateApi } from "../../api/expense/expenseUpdateApi"

type PayloadShape = Parameters<typeof expenseUpdateApi>[0]

export async function expenseUpdateService(payload: PayloadShape, token: string) {
	if (payload.update.expenseNo) payload.update.expenseNo = +payload.update.expenseNo

	if (payload.update.date) {
		payload.update.date = dayjs(payload.update.date).unix() * 1000
	}
	if (payload.update.fields) {
		payload.update.fields = payload.update.fields
			.map((field) => ({
				fieldId: field.fieldId,
				value: field.value,
			}))
			.filter((field) => field.value !== "")
	}

	return await expenseUpdateApi(payload, token)
}
