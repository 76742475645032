import { useCallback, useState } from "react"
import { IHallBookingReceipt } from "src/domain/entities"
import { hallBookingReceiptListService } from "src/domain/services/hallBookingReceipt/hallBookingReceiptListService"
import { useAuth } from ".."

export function useHallBookingReceiptListService(): {
	hallBookingReceiptList: IHallBookingReceipt[]
	isLoading: boolean
	fetchHallBookingReceiptList: (
		filter?: Parameters<typeof hallBookingReceiptListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [hallBookingReceiptList, setHallBookingReceiptList] = useState<
		IHallBookingReceipt[]
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchHallBookingReceiptList = useCallback(
		async (filter: Parameters<typeof hallBookingReceiptListService>[0] = {}) => {
			setIsLoading(true)
			const data = await hallBookingReceiptListService(filter, token)
			setHallBookingReceiptList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { hallBookingReceiptList, isLoading, fetchHallBookingReceiptList }
}
