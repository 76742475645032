import { Box, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { ILocation } from "src/domain/entities"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ILocationUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	location: ILocation
	handleSubmit: FormikOnSubmit<ILocationUpdateFormFields>
}

export const LocationUpdateDrawerFormView: FC<Props> = ({
	location,
	handleSubmit,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<ILocationUpdateFormFields>
			initialValues={{ name: location.name, note: location.note }}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Update Location"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Name */}
							<FormControl>
								<InputLabel label="Location Name" />
								<Input
									name="name"
									placeholder="Name"
									maxLength={50}
									required
									autoFocus
									value={values.name}
									onChange={handleNameChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="name" />
							</FormControl>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									value={values.note}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
