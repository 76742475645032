import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { useItemListService } from "src/hooks"
import { useItemCategoryListService } from "src/hooks/itemCategory"
import { IItem } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ItemAddDrawerFormController } from "./ItemAddDrawerForm"
import { ItemDeleteDialogController } from "./ItemDeleteDialogController"
import { ItemListController } from "./ItemList"
import { ItemUpdateDrawerFormController } from "./ItemUpdateDrawerForm"

export const ItemsPage: FC = () => {
	const { itemList, isLoading, fetchItemList } = useItemListService()
	const [isItemDeleteDialogOpen, setIsItemDeleteDialogOpen] = useState(false)
	const { itemCategoryList, fetchItemCategoryList } = useItemCategoryListService()
	const itemAddDrawerDisclosure = useDisclosure()
	const itemUpdateDrawerDisclosure = useDisclosure()
	const [selectedItemCategory, setSelectedItemCategory] = useState<SelectOption | null>(
		{
			label: "All",
			value: null,
		},
	)
	const itemCategoryOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...itemCategoryList.map((itemCategory) => ({
				label: itemCategory.name,
				value: itemCategory.id,
			})),
		]
	}, [itemCategoryList])

	const fetchItems = useCallback(() => {
		fetchItemList({
			fetch: { category: true },
			categoryId: selectedItemCategory?.value ?? undefined,
		})
	}, [fetchItemList, selectedItemCategory])

	useEffect(() => {
		fetchItems()
	}, [fetchItems])

	useEffect(() => {
		fetchItemCategoryList()
	}, [fetchItemCategoryList])

	const updateItemRef = useRef<IItem>()
	const deleteItemRef = useRef<IItem>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Items
					</Text>
					<Button
						size="sm"
						onClick={itemAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Item
					</Button>
				</Flex>
				<Box maxW={"230px"}>
					<InputLabel label="ItemCategory" />
					<ReactSelect
						onChange={(newValue) => {
							setSelectedItemCategory(
								itemCategoryOptions.find(
									(el) => el.value === newValue?.value,
								) ?? itemCategoryOptions[0],
							)
						}}
						value={selectedItemCategory}
						options={itemCategoryOptions}
						isSearchable={false}
					/>
				</Box>
			</Box>
			<ItemListController
				list={itemList}
				isLoading={isLoading}
				onUpdate={(item: IItem) => {
					updateItemRef.current = item
					itemUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(item: IItem) => {
					deleteItemRef.current = item
					setIsItemDeleteDialogOpen(true)
				}}
				onAddClick={itemAddDrawerDisclosure.onOpen}
			/>
			<ItemAddDrawerFormController
				{...itemAddDrawerDisclosure}
				onSuccess={() => fetchItems()}
			/>
			{itemAddDrawerDisclosure.isOpen ? (
				<ItemAddDrawerFormController
					{...itemAddDrawerDisclosure}
					onSuccess={() => fetchItems()}
				/>
			) : null}
			{updateItemRef.current && (
				<ItemUpdateDrawerFormController
					{...itemUpdateDrawerDisclosure}
					item={updateItemRef.current}
					onSuccess={() => fetchItems()}
				/>
			)}
			{deleteItemRef.current && (
				<ItemDeleteDialogController
					isOpen={isItemDeleteDialogOpen}
					setIsOpen={setIsItemDeleteDialogOpen}
					item={deleteItemRef.current}
					onSuccess={() => fetchItems()}
				/>
			)}
		</DashboardWrapper>
	)
}
