import { IContact } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	categoryId?: string
	search?: string
	fetch?: {
		category: boolean
	}
	pagination?: {
		page: number
		limit: number
	}
}

export async function contactListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/contact/list/v1"
	const requester = new ApiRequester<RequestDataShape, IContact[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
