import { useCallback, useState } from "react"
import { profileListService } from "src/domain/services/profile/profileListService"
import { useAuth } from "../useAuth"

export function useProfileListService(): {
	profileList: any[]
	isLoading: boolean
	error: null | Error
	fetchProfileList: (
		filter?: Parameters<typeof profileListService>[0],
	) => Promise<any[]>
} {
	const { token } = useAuth()
	const [profileList, setProfileList] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchProfileList = useCallback(
		async (filter: Parameters<typeof profileListService>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await profileListService(filter, token)
				setProfileList(data)
				return data
			} catch (error) {
				setError(error as Error)
				return []
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { profileList, isLoading, error, fetchProfileList }
}
