import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IPayment } from "src/domain/entities"
import { paymentUpdateService } from "src/domain/services/payment/paymentUpdateService"
import { useAuth, useReceiptListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IPaymentUpdateFormFields, PaymentUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof PaymentUpdateDrawerFormView>,
	"handleSubmit" | "receiptList"
> & {
	onSuccess?: (payment: IPayment) => void
}

export const PaymentUpdateDrawerFormController: FC<Props> = ({
	payment,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { receiptList, fetchReceiptList } = useReceiptListService()

	const handleSubmit = useCallback<FormikOnSubmit<IPaymentUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedPayment = await paymentUpdateService(
					{ id: payment.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedPayment)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ paymentDate: message })
			}
		},
		[onSuccess, rest, token, payment.id],
	)

	useEffect(() => {
		fetchReceiptList({ fetch: { student: true } })
	}, [fetchReceiptList])

	return (
		<PaymentUpdateDrawerFormView
			payment={payment}
			handleSubmit={handleSubmit}
			receiptList={receiptList}
			{...rest}
		/>
	)
}
