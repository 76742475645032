import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IIncomeCategory } from "src/domain/entities"
import { IncomeCategoryListView } from "."

interface Props {
	list: IIncomeCategory[]
	isLoading: boolean
	onUpdate: (incomeCategory: IIncomeCategory) => void
	onDelete: (incomeCategory: IIncomeCategory) => void
	onAddClick: () => void
}

export const IncomeCategoryListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No Income Categories found."
				onAddClick={onAddClick}
			/>
		)
	}

	return <IncomeCategoryListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
