import { IAccount } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: string
		accountNo?: string
		bankName?: string
		ifscCode?: string
		note?: string
	}
}

export async function accountUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/account/update/v1"
	const requester = new ApiRequester<RequestDataShape, IAccount>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
