import dayjs from "dayjs"
import { paymentUpdateApi } from "../../api/payment/paymentUpdateApi"

type PayloadShape = Parameters<typeof paymentUpdateApi>[0]

export async function paymentUpdateService(payload: PayloadShape, token: string) {
	if (payload.update?.paymentAmount)
		payload.update.paymentAmount = +payload.update?.paymentAmount

	if (payload.update?.paymentDate)
		payload.update.paymentDate = dayjs(payload.update?.paymentDate).unix() * 1000
	return await paymentUpdateApi(payload, token)
}
