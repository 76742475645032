import { ComponentProps, FC, useCallback } from "react"
import { IAccount } from "src/domain/entities"
import { accountUpdateService } from "src/domain/services/account/accountUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IAccountUpdateFormFields, AccountUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof AccountUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (account: IAccount) => void
}

export const AccountUpdateDrawerFormController: FC<Props> = ({
	account,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IAccountUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedAccount = await accountUpdateService(
					{ id: account.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedAccount)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, account.id],
	)

	return (
		<AccountUpdateDrawerFormView
			account={account}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
