import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ICommitteeMember } from "src/domain/entities"
import { committeeMemberUpdateService } from "src/domain/services/committeeMember/committeeMemberUpdateService"
import { useAuth, useProfileListService, useTenureListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ICommitteeMemberUpdateFormFields, CommitteeMemberUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof CommitteeMemberUpdateDrawerFormView>,
	| "handleSubmit"
	| "tenureList"
	| "profileList"
	| "setProfileSearchText"
	| "getGujaratiSuggestions"
> & {
	onSuccess?: (committeeMember: ICommitteeMember) => void
}

export const CommitteeMemberUpdateDrawerFormController: FC<Props> = ({
	committeeMember,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { tenureList, fetchTenureList } = useTenureListService()
	const { profileList, fetchProfileList } = useProfileListService()

	const handleSubmit = useCallback<FormikOnSubmit<ICommitteeMemberUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedCommitteeMember = await committeeMemberUpdateService(
					{ id: committeeMember.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedCommitteeMember)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ profileId: message })
			}
		},
		[onSuccess, rest, token, committeeMember.id],
	)

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchTenureList()
	}, [fetchTenureList])

	const [profileSearchText, setProfileSearchText] = useState(
		committeeMember.profile?.firstName?.en ?? "",
	)

	useEffect(() => {
		fetchProfileList({
			search: profileSearchText,
			fetch: { surname: {} },
			pagination: { limit: 15, page: 1 },
		})
	}, [fetchProfileList, profileSearchText])

	return (
		<CommitteeMemberUpdateDrawerFormView
			committeeMember={committeeMember}
			handleSubmit={handleSubmit}
			tenureList={tenureList}
			profileList={profileList}
			setProfileSearchText={setProfileSearchText}
			getGujaratiSuggestions={getGujaratiSuggestions}
			{...rest}
		/>
	)
}
