import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IStudent } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"

export const StudentListView: FC<{
	list: IStudent[]
	onUpdate: (student: IStudent) => void
	onDelete: (student: IStudent) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th width={"15%"}>Student No.</Th>
				<Th>Name</Th>
				<Th>Standard</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((student, i) => (
				<Tr key={i}>
					<Td>{student.studentNo}</Td>
					<Td>{getFullName(student)}</Td>
					<Td>
						{student.standard?.name}
						{student.standard?.semester && (
							<Text as="span" color={"gray.600"}>
								, Semester - {student.standard?.semester}
							</Text>
						)}
					</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(student)} />
						<DeleteIconButton onClick={() => onDelete(student)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
