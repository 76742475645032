import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, SetStateAction, useEffect } from "react"
import { FormDynamicField } from "src/components/shared/FormDynamicField"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IIncomeCategory } from "src/domain/entities"
import { useContactListService, useShopListService } from "src/hooks"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IIncomeAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IIncomeAddFormFields>
	incomeCategoryList: IIncomeCategory[]
	incomeNextNumber: number
	getGujaratiSuggestions: (value: string) => Promise<string[]>
	selectedIncomeCategory?: IIncomeCategory
	setSelectedIncomeCategory: Dispatch<SetStateAction<IIncomeCategory | undefined>>
}

export const IncomeAddDrawerFormView: FC<Props> = ({
	incomeNextNumber,
	handleSubmit,
	incomeCategoryList,
	getGujaratiSuggestions,
	selectedIncomeCategory,
	setSelectedIncomeCategory,
	...rest
}) => {
	const { contactList, fetchContactList } = useContactListService()
	const { shopList, fetchShopList } = useShopListService()

	useEffect(() => {
		fetchContactList()
		fetchShopList({ fetch: { currentRentee: true } })
	}, [fetchContactList, fetchShopList])

	return (
		<Formik<IIncomeAddFormFields>
			initialValues={{
				categoryId: selectedIncomeCategory?.id ?? "",
				incomeNo: incomeNextNumber,
				date: dayjs().format("YYYY-MM-DD") as any,
				fields:
					selectedIncomeCategory?.fields?.map((field) => ({
						fieldId: field.id,
						value: "",
					})) ?? [],
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				return (
					<DrawerForm
						size="lg"
						headerLabel="Add Income"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
							{/* IncomeCategory */}
							<FormControl>
								<InputLabel label="IncomeCategory" />
								<ReactSelect
									name="categoryId"
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
										setSelectedIncomeCategory(
											incomeCategoryList.find(
												(category) =>
													category.id ===
													(newValue as SelectOption).value,
											),
										)
									}}
									options={incomeCategoryList.map((incomeCategory) => ({
										label: incomeCategory.name,
										value: incomeCategory.id,
									}))}
									isSearchable
								/>
							</FormControl>
							<Flex gridColumnGap={2}>
								{/* Date */}
								<FormControl flex={1}>
									<InputLabel label="Date" />
									<Input
										type="date"
										name="date"
										maxLength={50}
										defaultValue={values.date}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="receiptDate"
									/>
								</FormControl>
								{/* Income No. */}
								<FormControl flex={1}>
									<InputLabel label="Income No." />
									<Input
										name="incomeNo"
										maxLength={50}
										required
										autoFocus
										value={values.incomeNo}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="incomeNo"
									/>
								</FormControl>
							</Flex>

							<Flex gridRowGap={2} direction="column">
								{selectedIncomeCategory?.fields
									?.sort((a, b) => a.position - b.position)
									.map((field, i) => {
										return (
											<FormDynamicField
												key={field.id}
												field={field}
												value={values.fields[i]}
												controlName={`fields[${i}]`}
												handleChange={handleChange}
												setFieldValue={(fieldName, payload) => {
													if (field.name === "Contact") {
														const findContact =
															contactList.find(
																(e) =>
																	e.id ===
																	payload.value,
															)
														const findAddressFieldIndex =
															selectedIncomeCategory?.fields?.findIndex(
																(e) =>
																	e.name ===
																	"Contact address",
															)

														if (
															findAddressFieldIndex !==
																undefined &&
															findAddressFieldIndex !== -1
														) {
															const addressField =
																selectedIncomeCategory
																	?.fields?.[
																	findAddressFieldIndex
																]

															setFieldValue(
																`fields[${findAddressFieldIndex}]`,
																{
																	fieldId:
																		addressField?.id,
																	value: findContact?.address,
																},
															)
														}
													} else if (field.name === "Shop") {
														const findShop = shopList.find(
															(e) => e.id === payload.value,
														)

														// Set automatic rent per month
														const findRentPerMonthFieldIndex =
															selectedIncomeCategory?.fields?.findIndex(
																(e) =>
																	e.name ===
																	"Rent per month",
															)

														if (
															findRentPerMonthFieldIndex !==
																undefined &&
															findRentPerMonthFieldIndex !==
																-1
														) {
															const rentPerMonthField =
																selectedIncomeCategory
																	?.fields?.[
																	findRentPerMonthFieldIndex
																]

															setFieldValue(
																`fields[${findRentPerMonthFieldIndex}]`,
																{
																	fieldId:
																		rentPerMonthField?.id,
																	value: findShop?.rent,
																},
															)
														}

														// Set automatic amount
														const findAmountFieldIndex =
															selectedIncomeCategory?.fields?.findIndex(
																(e) =>
																	e.name === "Amount",
															)

														if (
															findAmountFieldIndex !==
																undefined &&
															findAmountFieldIndex !== -1
														) {
															const amountField =
																selectedIncomeCategory
																	?.fields?.[
																	findAmountFieldIndex
																]

															setFieldValue(
																`fields[${findAmountFieldIndex}]`,
																{
																	fieldId:
																		amountField?.id,
																	value:
																		(findShop?.rent ??
																			0) * 12,
																},
															)
														}

														// Set automatic contact
														const findContactFieldIndex =
															selectedIncomeCategory?.fields?.findIndex(
																(e) =>
																	e.name === "Contact",
															)

														if (
															findContactFieldIndex !==
																undefined &&
															findContactFieldIndex !== -1
														) {
															const contactField =
																selectedIncomeCategory
																	?.fields?.[
																	findContactFieldIndex
																]

															setFieldValue(
																`fields[${findContactFieldIndex}]`,
																{
																	fieldId:
																		contactField?.id,
																	value: contactList
																		.map((c) => ({
																			label: getFullName(
																				c,
																			),
																			value: c,
																		}))
																		.find(
																			(c) =>
																				c.value
																					.id ===
																				findShop
																					?.currentRentee
																					?.id,
																		)?.value,
																},
															)
														}
													}

													setFieldValue(fieldName, payload)
												}}
											/>
										)
									})}
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
