import { IExpenseCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	fetch?: {
		fields: boolean
	}
}

export async function expenseCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/expense/category/list/v1"
	const requester = new ApiRequester<RequestDataShape, IExpenseCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
