import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	code?: string
	search?: string
	educationCategoryIds?: string[]
	occupationCategoryIds?: string[]
	occupationType?: OccupationType
	reviewedById?: string
	surnameId?: string
	subCasteId?: string
	isAlive?: boolean
	isBornNonPatidar?: boolean
	isMarriedToNonPatidar?: boolean
	isReviewPending?: boolean
	isMatrimonyProfileList?: boolean
	isVerified?: boolean
	getEligibleMatrimonyProfile?: boolean
	maxRelationCount?: number
	maritalStatuses?: MaritalStatus[]
	gender?: Gender
	nativeGeographicalLocations?: {
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	currentGeographicalLocations?: {
		countryIds?: string[]
		stateIds?: string[]
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	locations?: {
		nativeGeographicalLocations?: {
			districtIds: string[]
			talukaIds: string[]
			villageIds: string[]
		}
		currentGeographicalLocations?: {
			countryIds: string[]
			stateIds: string[]
			districtIds: string[]
			talukaIds: string[]
			villageIds: string[]
		}
	}
	range?: {
		age?: {
			min: number
			max: number
		}
	}
	fetch?: {
		nativeVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		currentVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		occupations?: {
			occupationCategory?: boolean
		}
		educations?: {
			educationCategory?: boolean
		}
		surname?: {
			subCaste?: boolean
		}
		reviewedBy?: {
			details?: boolean
		}
		updatedBy?: {
			details?: boolean
		}
		createdBy?: {
			details?: boolean
		}
		acquiredBy?: {
			details?: boolean
		}
		mobile?: boolean
	}
	sortingFields?: {
		firstName?: {
			orderBy: "ASC" | "DESC"
		}
		age?: {
			orderBy: "ASC" | "DESC"
		}
		relationCount?: {
			orderBy: "ASC" | "DESC"
		}
	}
	pagination?: {
		page: number
		limit: number
	}
}

export async function profileListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/profile/list/v1?client=browser"
	const requester = new ApiRequester<RequestDataShape, any[]>(endpoint)
	return await requester.sendRequest(payload, token)
}

export enum Gender {
	MALE = "male",
	FEMALE = "female",
}

export enum MaritalStatus {
	SINGLE = "single",
	MARRIED = "married",
	ENGAGED = "engaged",
	SEPARATED = "separated",
	DIVORCED = "divorced",
	WIDOW = "widow",
}

export enum OccupationType {
	SELF_EMPLOYED = "self_employed",
	SALARIED = "salaried",
}
