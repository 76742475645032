import { ILocation } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	note?: string
}

export async function locationAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/location/add/v1"
	const requester = new ApiRequester<RequestDataShape, ILocation>(endpoint)
	return await requester.sendRequest(payload, token)
}
