import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IStandard } from "src/domain/entities"
import { StandardListView } from "."

interface Props {
	list: IStandard[]
	isLoading: boolean
	onUpdate: (standard: IStandard) => void
	onDelete: (standard: IStandard) => void
	onAddClick: () => void
}

export const StandardListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No standards found." onAddClick={onAddClick} />
	}

	return <StandardListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
