import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useExpenseCategoryListService } from "src/hooks/expenseCategory"

import { IExpenseCategory } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ExpenseCategoryAddDrawerFormController } from "./ExpenseCategoryAddDrawerForm"
import { ExpenseCategoryDeleteDialogController } from "./ExpenseCategoryDeleteDialogController"
import { ExpenseCategoryListController } from "./ExpenseCategoryList"
import { ExpenseCategoryUpdateDrawerFormController } from "./ExpenseCategoryUpdateDrawerForm"

export const ExpenseCategoriesPage: FC = () => {
	const { expenseCategoryList, isLoading, fetchExpenseCategoryList } =
		useExpenseCategoryListService()
	const [isExpenseCategoryDeleteDialogOpen, setIsExpenseCategoryDeleteDialogOpen] =
		useState(false)
	const expenseCategoryAddDrawerDisclosure = useDisclosure()
	const expenseCategoryUpdateDrawerDisclosure = useDisclosure()

	const fetchExpenseCategories = useCallback(() => {
		fetchExpenseCategoryList({
			fetch: { fields: true },
		})
	}, [fetchExpenseCategoryList])

	useEffect(() => {
		fetchExpenseCategories()
	}, [fetchExpenseCategories])

	const updateExpenseCategoryRef = useRef<IExpenseCategory>()
	const deleteExpenseCategoryRef = useRef<IExpenseCategory>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Expense Categories
					</Text>
					<Button
						size="sm"
						onClick={expenseCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Expense Category
					</Button>
				</Flex>
			</Box>
			<ExpenseCategoryListController
				list={expenseCategoryList}
				isLoading={isLoading}
				onUpdate={(expenseCategory: IExpenseCategory) => {
					updateExpenseCategoryRef.current = expenseCategory
					expenseCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(expenseCategory: IExpenseCategory) => {
					deleteExpenseCategoryRef.current = expenseCategory
					setIsExpenseCategoryDeleteDialogOpen(true)
				}}
				onAddClick={expenseCategoryAddDrawerDisclosure.onOpen}
			/>
			{expenseCategoryAddDrawerDisclosure.isOpen ? (
				<ExpenseCategoryAddDrawerFormController
					{...expenseCategoryAddDrawerDisclosure}
					onSuccess={() => fetchExpenseCategories()}
				/>
			) : null}
			{updateExpenseCategoryRef.current &&
				expenseCategoryUpdateDrawerDisclosure.isOpen && (
					<ExpenseCategoryUpdateDrawerFormController
						{...expenseCategoryUpdateDrawerDisclosure}
						expenseCategory={updateExpenseCategoryRef.current}
						onSuccess={() => fetchExpenseCategories()}
					/>
				)}
			{deleteExpenseCategoryRef.current && isExpenseCategoryDeleteDialogOpen && (
				<ExpenseCategoryDeleteDialogController
					isOpen={isExpenseCategoryDeleteDialogOpen}
					setIsOpen={setIsExpenseCategoryDeleteDialogOpen}
					expenseCategory={deleteExpenseCategoryRef.current}
					onSuccess={() => fetchExpenseCategories()}
				/>
			)}
		</DashboardWrapper>
	)
}
