import { CommitteeMemberCategory, ICommitteeMember, MultiLangText } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	designation: MultiLangText
	category: CommitteeMemberCategory
	profileId: string
	tenureId: string
}

export async function committeeMemberAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/committee/member/add/v1"
	const requester = new ApiRequester<RequestDataShape, ICommitteeMember>(endpoint)
	return await requester.sendRequest(payload, token)
}
