import { shopUpdateApi } from "../../api/shop/shopUpdateApi"

type PayloadShape = Parameters<typeof shopUpdateApi>[0]

export async function shopUpdateService(payload: PayloadShape, token: string) {
	if (payload.update?.rent) payload.update.rent = +payload.update.rent

	if (payload.update?.gstPercentage)
		payload.update.gstPercentage = +payload.update?.gstPercentage

	return await shopUpdateApi(payload, token)
}
