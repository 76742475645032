import { IShop } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	fetch?: {
		currentRentee: boolean
	}
	pagination?: {
		page: number
		limit: number
	}
}

export async function shopListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/shop/list/v1"
	const requester = new ApiRequester<RequestDataShape, IShop[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
