import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { useIncomeCategoryListService, useIncomeListService } from "src/hooks"
import { IncomeCategorySlugs } from "src/utils/enums"
import { fetchGujaratiSuggestions, formatDate } from "src/utils/helpers"

import { BsFileEarmarkRuled } from "react-icons/bs"
import { IIncome } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { IncomeAddDrawerFormController } from "./IncomeAddDrawerForm"
import { IncomeDeleteDialogController } from "./IncomeDeleteDialogController"
import { IncomeListController, getIncomeAmount, getIncomeContact } from "./IncomeList"
import { IncomeUpdateDrawerFormController } from "./IncomeUpdateDrawerForm"

export const IncomesPage: FC = () => {
	const { incomeList, isLoading, fetchIncomeList } = useIncomeListService()
	const { incomeCategoryList, fetchIncomeCategoryList } = useIncomeCategoryListService()
	const [isIncomeDeleteDialogOpen, setIsIncomeDeleteDialogOpen] = useState(false)
	const incomeAddDrawerDisclosure = useDisclosure()
	const incomeUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [selectedIncomeCategory, setSelectedIncomeCategory] =
		useState<SelectOption | null>({
			label: "All",
			value: null,
		})
	const incomeCategoryOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...incomeCategoryList.map((incomeCategory) => ({
				label: incomeCategory.name,
				value: incomeCategory.id,
			})),
		]
	}, [incomeCategoryList])

	const fetchIncomes = useCallback(() => {
		fetchIncomeList({
			fetch: { category: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
			categoryId: selectedIncomeCategory?.value ?? undefined,
		})
	}, [fetchIncomeList, pagination.currentPage, searchText, selectedIncomeCategory])

	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const handleExportToCSV = useCallback(async () => {
		const allIncomes = await fetchIncomeList({ fetch: { category: true } })

		const csvRows = [
			["Income No.", "Contact", "Income Category", "Amount", "Date"],
			...allIncomes.map((income) => {
				const rows = []
				rows.push(`"${income.incomeNo}"`)
				rows.push(`"${getIncomeContact(income)}"`)
				rows.push(`"${income.category?.name}"`)
				rows.push(`"${getIncomeAmount(income)}"`)
				rows.push(`"${formatDate(income.date)}"`)

				return rows
			}),
		]

		const csvContent = csvRows.map((row) => row.join(",")).join("\n")
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
		const link = document.createElement("a")
		const url = URL.createObjectURL(blob)
		link.setAttribute("href", url)
		link.setAttribute("download", "incomes.csv")
		link.style.visibility = "hidden"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}, [fetchIncomeList])

	useEffect(() => {
		fetchIncomeCategoryList({
			slugs: [
				IncomeCategorySlugs.LIFETIME_MEMBER_RECEIPT,
				IncomeCategorySlugs.OTHER_INCOME_RECEIPT,
				IncomeCategorySlugs.OTHER_PAYMENT_RECEIPT,
				IncomeCategorySlugs.SHOP_RENT_RECEIPT,
				IncomeCategorySlugs.TRUST_FUND_RECEIPT,
			],
		})
		fetchIncomes()
	}, [fetchIncomes, fetchIncomeCategoryList])

	const updateIncomeRef = useRef<IIncome>()
	const deleteIncomeRef = useRef<IIncome>()

	return (
		<DashboardWrapper>
			<Box padding={2} pb={0}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Incomes
					</Text>
					<Button
						size="sm"
						onClick={incomeAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Income
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
					</Box>
					<Box minW={"230px"}>
						<InputLabel label="IncomeCategory" />
						<ReactSelect
							onChange={(newValue) => {
								setSelectedIncomeCategory(
									incomeCategoryOptions.find(
										(el) => el.value === newValue?.value,
									) ?? incomeCategoryOptions[0],
								)
							}}
							value={selectedIncomeCategory}
							options={incomeCategoryOptions}
							isSearchable={false}
						/>
					</Box>
					<Box>
						<Button
							onClick={handleExportToCSV}
							leftIcon={<BsFileEarmarkRuled />}
							colorScheme="green"
						>
							Export
						</Button>
					</Box>
				</Flex>
				{gujaratiSuggestions.length ? (
					<Box mt={1}>
						{gujaratiSuggestions.map((el) => (
							<Tag
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				) : null}
			</Box>
			<IncomeListController
				list={incomeList}
				isLoading={isLoading}
				onUpdate={(income: IIncome) => {
					updateIncomeRef.current = income
					incomeUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(income: IIncome) => {
					deleteIncomeRef.current = income
					setIsIncomeDeleteDialogOpen(true)
				}}
				onAddClick={incomeAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={incomeList.length === 0}
			/>
			{incomeAddDrawerDisclosure.isOpen ? (
				<IncomeAddDrawerFormController
					{...incomeAddDrawerDisclosure}
					onSuccess={() => fetchIncomes()}
				/>
			) : null}
			{updateIncomeRef.current && incomeUpdateDrawerDisclosure.isOpen && (
				<IncomeUpdateDrawerFormController
					{...incomeUpdateDrawerDisclosure}
					income={updateIncomeRef.current}
					onSuccess={() => fetchIncomes()}
				/>
			)}
			{deleteIncomeRef.current && (
				<IncomeDeleteDialogController
					isOpen={isIncomeDeleteDialogOpen}
					setIsOpen={setIsIncomeDeleteDialogOpen}
					income={deleteIncomeRef.current}
					onSuccess={() => fetchIncomes()}
				/>
			)}
		</DashboardWrapper>
	)
}
