import { AddIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	FormControl,
	IconButton,
	Input,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC } from "react"
import { BsTrash2Fill } from "react-icons/bs"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IItem, IStudent } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IReceiptAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IReceiptAddFormFields>
	studentList: IStudent[]
	itemList: IItem[]
	receiptItems: { itemId: string; amount: number }[]
	dispatchReceiptItems: Dispatch<{
		type: string
		index: number
		payload: {
			itemId: string
			amount: number
		}
	}>
	receiptNextNumber: number
}

export const ReceiptAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	studentList,
	itemList,
	receiptItems,
	dispatchReceiptItems,
	receiptNextNumber,
	...rest
}) => {
	return (
		<Formik<IReceiptAddFormFields>
			initialValues={{
				receiptNo: receiptNextNumber,
				studentId: "",
				receiptDate: dayjs().format("YYYY-MM-DD") as any,
				receiptItems: [],
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const itemOptions = itemList.map((item) => ({
					label: item.name,
					value: item.id,
				}))
				return (
					<DrawerForm
						size="lg"
						headerLabel="Add Receipt"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
							<Flex gridColumnGap={2}>
								{/* Receipt No. */}
								<FormControl flex={1}>
									<InputLabel label="Receipt No." />
									<Input
										name="receiptNo"
										maxLength={50}
										type="number"
										required
										autoFocus
										value={values.receiptNo}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="receiptNo"
									/>
								</FormControl>
								{/* Date */}
								<FormControl flex={1}>
									<InputLabel label="Date" />
									<Input
										type="date"
										name="receiptDate"
										maxLength={50}
										defaultValue={values.receiptDate}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="receiptDate"
									/>
								</FormControl>
								{/* Student */}
								<FormControl flex={2}>
									<InputLabel label="Student" />
									<ReactSelect
										name="studentId"
										onChange={(newValue) => {
											setFieldValue(
												"studentId",
												(newValue as SelectOption).value,
											)
										}}
										options={studentList.map((student) => ({
											label: getFullName(student),
											value: student.id,
										}))}
										isSearchable
									/>
								</FormControl>
							</Flex>
							<Box>
								<Text fontWeight="bold" fontSize={16}>
									Items
								</Text>

								<Table
									backgroundColor={"gray.100"}
									borderRadius={8}
									marginTop={2}
								>
									<Thead>
										<Tr>
											<Th
												pr={0}
												pl={2}
												width={"5%"}
												style={{
													textTransform: "none",
													whiteSpace: "nowrap",
												}}
											>
												Sr No.
											</Th>
											<Th width={"60%"}>Item</Th>
											<Th width={"20%"} pl={0} pr={0}>
												Amount
											</Th>
											<Th pr={2} pl={0} isNumeric>
												Action
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{receiptItems.map((rItem, index) => (
											<Tr key={index}>
												<Td pr={0}>{index + 1}</Td>
												<Td>
													<ReactSelect
														name={`receiptItems[${index}].itemId`}
														onChange={(newValue) => {
															setFieldValue(
																`receiptItems[${index}].itemId`,
																(newValue as SelectOption)
																	.value,
															)

															const findItem =
																itemList.find(
																	(item) =>
																		item.id ===
																		(
																			newValue as SelectOption
																		).value,
																)

															dispatchReceiptItems({
																type: "update",
																index,
																payload: {
																	itemId: (
																		newValue as SelectOption
																	).value as string,
																	amount: findItem
																		? findItem.rate
																		: rItem.amount,
																},
															})
														}}
														value={itemOptions.find(
															(el) =>
																el.value === rItem.itemId,
														)}
														options={itemOptions}
														isSearchable
													/>
												</Td>
												<Td pl={0} pr={0}>
													<Input
														backgroundColor={"white"}
														type="number"
														borderColor={"gray.300"}
														value={rItem.amount}
														name={`receiptItems[${index}].amount`}
														onChange={(e) => {
															setFieldValue(
																`receiptItems[${index}].amount`,
																e.target.value,
															)

															dispatchReceiptItems({
																type: "update",
																index,
																payload: {
																	itemId: rItem.itemId,
																	amount: +e.target
																		.value,
																},
															})
														}}
													/>
												</Td>
												<Td pr={0}>
													<IconButton
														aria-label="delete"
														variant="ghost"
														icon={<BsTrash2Fill />}
														size={"sm"}
														mx="1"
														colorScheme={"red"}
														_hover={{
															backgroundColor: "red.100",
														}}
														onClick={() => {
															dispatchReceiptItems({
																type: "remove",
																index,
																payload: rItem,
															})
														}}
													/>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
								<Button
									leftIcon={<AddIcon fontSize={10} />}
									backgroundColor={"gray.200"}
									_hover={{ backgroundColor: "gray.300" }}
									size={"sm"}
									marginTop={2}
									onClick={() => {
										dispatchReceiptItems({
											type: "add",
											index: receiptItems.length,
											payload: {
												itemId: "",
												amount: 0,
											},
										})
									}}
								>
									Add
								</Button>
							</Box>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
