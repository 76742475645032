import { incomeNumberGetApi } from "src/domain/api/income/incomeNumberGetApi"
import { hallBookingReceiptNumberGetApi } from "../../api/hallBookingReceipt/hallBookingReceiptNumberGetApi"

type PayloadShape = Parameters<typeof hallBookingReceiptNumberGetApi>[0]

export async function hallBookingReceiptNumberGetService(
	payload: PayloadShape,
	token?: null | string,
) {
	const { incomeNo } = await incomeNumberGetApi(payload, token)
	return { hallBookingReceiptNo: incomeNo }
}
