import { IStandard } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	semester?: string
}

export async function standardAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/standard/add/v1"
	const requester = new ApiRequester<RequestDataShape, IStandard>(endpoint)
	return await requester.sendRequest(payload, token)
}
