import { FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IItem, IItemCategory } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IItemUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	item: IItem
	categoryList: IItemCategory[]
	handleSubmit: FormikOnSubmit<IItemUpdateFormFields>
}

export const ItemUpdateDrawerFormView: FC<Props> = ({
	item,
	handleSubmit,
	categoryList,
	...rest
}) => (
	<Formik<IItemUpdateFormFields>
		initialValues={{
			name: item.name,
			rate: item.rate,
			categoryId: item.category?.id,
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const categoryOptions = categoryList.map((category) => ({
				label: category.name,
				value: category.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Item"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"sm"} marginX={"auto"}>
						{/* Item Category */}
						<FormControl>
							<InputLabel label="Item Category" />
							<ReactSelect
								name="categoryId"
								value={categoryOptions.find(
									(el) => el.value === values.categoryId,
								)}
								onChange={(newValue) => {
									setFieldValue(
										"categoryId",
										(newValue as SelectOption).value,
									)
								}}
								options={categoryOptions}
								isSearchable
							/>
						</FormControl>
						{/* Name */}
						<FormControl>
							<InputLabel label="Item Name" />
							<Input
								name="name"
								placeholder="Name"
								maxLength={50}
								required
								autoFocus
								value={values.name}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="name" />
						</FormControl>
						{/* Rate */}
						<FormControl>
							<InputLabel label="Rate" />
							<Input
								name="rate"
								placeholder="Rate"
								maxLength={50}
								type="number"
								value={values.rate}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="rate" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
