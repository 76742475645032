import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IPayment } from "src/domain/entities"
import { formatDate, getFullName, toINR } from "src/utils/helpers"

export const PaymentListView: FC<{
	list: IPayment[]
	onUpdate: (payment: IPayment) => void
	onDelete: (payment: IPayment) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Receipt No.</Th>
				<Th>Date</Th>
				<Th>Student Name</Th>
				<Th textAlign={"center"}>Payment Mode</Th>
				<Th isNumeric>Amount</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((payment, i) => (
				<Tr key={i}>
					<Td>{payment.receipt?.receiptNo}</Td>
					<Td>{formatDate(payment.paymentDate)}</Td>
					<Td>{getFullName(payment.receipt?.student)}</Td>
					<Td textAlign={"center"}>{payment.paymentMode || "-"}</Td>
					<Td isNumeric>{toINR(payment.paymentAmount)}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(payment)} />
						<DeleteIconButton onClick={() => onDelete(payment)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
