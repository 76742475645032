import { IHallBookingReceipt } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	categoryId?: string
	search?: string
	searchFields?: string[]
	categorySlugs?: string[]
	pagination?: {
		page: number
		limit: number
	}
	fetch?: {
		category: boolean
	}
}

export async function hallBookingReceiptListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/income/list/v1"
	const requester = new ApiRequester<RequestDataShape, IHallBookingReceipt[]>(endpoint)
	const hallBookingIncomes = await requester.sendRequest(payload, token)
	return hallBookingIncomes.map((income: any): IHallBookingReceipt => {
		return {
			id: income.id,
			createdAt: income.createdAt,
			category: income.category,
			date: income.date,
			hallBookingReceiptNo: income.incomeNo,
			updatedAt: income.updatedAt,
			fields: income.fields ?? [],
		}
	})
}
