import { IContact } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		firstName?: string
		middleName?: string
		lastName?: string
		address?: string
		mobile?: string
		email?: string
		note?: string
		categoryId?: string
	}
}

export async function contactUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/contact/update/v1"
	const requester = new ApiRequester<RequestDataShape, IContact>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
