export enum FieldType {
	STRING = "string",
	NUMBER = "number",
	BOOLEAN = "boolean",
	ACCOUNT = "account",
	LOCATION = "location",
	SHOP = "shop",
	CONTACT = "contact",
	DATE = "date",
}

export enum ExpenseCategorySlugs {
	SAMAJ_VOUCHER = "samaj-voucher",
	VAADI_VOUCHER = "vaadi-voucher",
	PARTY_PLOT_VOUCHER = "party-plot-voucher",
	HOSTEL_VOUCHER = "hostel-voucher",
}

export enum IncomeCategorySlugs {
	TRUST_FUND_RECEIPT = "trust-fund-receipt",
	SHOP_RENT_RECEIPT = "shop-rent-receipt",
	OTHER_PAYMENT_RECEIPT = "other-payment-receipt",
	OTHER_INCOME_RECEIPT = "other-income-receipt",
	LIFETIME_MEMBER_RECEIPT = "lifetime-member-receipt",
}
export enum HallBookingReceiptCategorySlugs {
	VAADI_BHADA_DEPOSIT_RECEIPT = "vaadi-bhada-deposit-receipt",
	PARTY_PLOT_BHADA_DEPOSIT_RECEIPT = "party-plot-bhada-deposit-receipt",
}
