import { IPayment } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	receiptId?: string
	fetch?: {
		receipt?: boolean
		receiptStudent?: boolean
	}
	pagination?: {
		page: number
		limit: number
	}
	search?: string
}

export async function paymentListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/payment/list/v1"
	const requester = new ApiRequester<RequestDataShape, IPayment[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
