import { ComponentProps, FC, useCallback } from "react"
import { IAccount } from "src/domain/entities"
import { accountAddService } from "src/domain/services/account/accountAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IAccountAddFormFields, AccountAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof AccountAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (account: IAccount) => void
}

export const AccountAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IAccountAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const account = await accountAddService(values, token)
				onSuccess(account)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <AccountAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
