import dayjs from "dayjs"
import { expenseAddApi } from "../../api/expense/expenseAddApi"

type PayloadShape = Parameters<typeof expenseAddApi>[0]

export async function expenseAddService(payload: PayloadShape, token: string) {
	payload.expenseNo = +payload.expenseNo
	payload.date = dayjs(payload.date).unix() * 1000
	payload.fields = payload.fields
		.map((field) => ({
			fieldId: field.fieldId,
			value: field.value,
		}))
		.filter((field) => field.value !== "")

	return await expenseAddApi(payload, token)
}
