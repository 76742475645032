import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IExpenseCategory } from "src/domain/entities"

export const ExpenseCategoryListView: FC<{
	list: IExpenseCategory[]
	onUpdate: (expenseCategory: IExpenseCategory) => void
	onDelete: (expenseCategory: IExpenseCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Slug</Th>
				<Th>Note</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((expenseCategory, i) => (
				<Tr key={i}>
					<Td>{expenseCategory.name}</Td>
					<Td>{expenseCategory.slug}</Td>
					<Td>{expenseCategory.note || "-"}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(expenseCategory)} />
						<DeleteIconButton onClick={() => onDelete(expenseCategory)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
