import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { DashboardWrapper } from "src/components/wrappers"
import { IHallBookingReceipt } from "src/domain/entities"
import { useHallBookingReceiptListService } from "src/hooks/hallBookingReceipt/useHallBookingReceiptListService"
import { useHallBookingReceiptCategoryListService } from "src/hooks/hallBookingReceiptCategory"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { HallBookingReceiptAddDrawerFormController } from "./HallBookingReceiptAddDrawerForm"
import { HallBookingReceiptDeleteDialogController } from "./HallBookingReceiptDeleteDialogController"
import { HallBookingReceiptListController } from "./HallBookingReceiptList"
import { HallBookingReceiptUpdateDrawerFormController } from "./HallBookingReceiptUpdateDrawerForm"

export const HallBookingReceiptsPage: FC = () => {
	const { hallBookingReceiptList, isLoading, fetchHallBookingReceiptList } =
		useHallBookingReceiptListService()
	const { hallBookingReceiptCategoryList, fetchHallBookingReceiptCategoryList } =
		useHallBookingReceiptCategoryListService()
	const [
		isHallBookingReceiptDeleteDialogOpen,
		setIsHallBookingReceiptDeleteDialogOpen,
	] = useState(false)
	const hallBookingReceiptAddDrawerDisclosure = useDisclosure()
	const hallBookingReceiptUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [selectedHallBookingReceiptCategory, setSelectedHallBookingReceiptCategory] =
		useState<SelectOption | null>({
			label: "All",
			value: null,
		})
	const incomeCategoryOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...hallBookingReceiptCategoryList.map((hallBookingReceiptCategoryList) => ({
				label: hallBookingReceiptCategoryList.name,
				value: hallBookingReceiptCategoryList.id,
			})),
		]
	}, [hallBookingReceiptCategoryList])

	const fetchHallBookingReceipts = useCallback(() => {
		fetchHallBookingReceiptList({
			fetch: { category: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
			categoryId: selectedHallBookingReceiptCategory?.value ?? undefined,
		})
	}, [
		fetchHallBookingReceiptList,
		pagination.currentPage,
		searchText,
		selectedHallBookingReceiptCategory,
	])

	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	useEffect(() => {
		fetchHallBookingReceipts()
		fetchHallBookingReceiptCategoryList()
	}, [fetchHallBookingReceipts, fetchHallBookingReceiptCategoryList])

	const updateHallBookingReceiptRef = useRef<IHallBookingReceipt>()
	const deleteHallBookingReceiptRef = useRef<IHallBookingReceipt>()

	return (
		<DashboardWrapper>
			<Box padding={2} pb={0}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Hall Booking Receipts
					</Text>
					<Button
						size="sm"
						onClick={hallBookingReceiptAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Hall Booking Receipt
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
					</Box>
					<Box minW={"230px"}>
						<InputLabel label="Category" />
						<ReactSelect
							onChange={(newValue) => {
								setSelectedHallBookingReceiptCategory(
									incomeCategoryOptions.find(
										(el) => el.value === newValue?.value,
									) ?? incomeCategoryOptions[0],
								)
							}}
							value={selectedHallBookingReceiptCategory}
							options={incomeCategoryOptions}
							isSearchable={false}
						/>
					</Box>
				</Flex>
				{gujaratiSuggestions.length ? (
					<Box mt={1}>
						{gujaratiSuggestions.map((el) => (
							<Tag
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				) : null}
			</Box>
			<HallBookingReceiptListController
				list={hallBookingReceiptList}
				isLoading={isLoading}
				onUpdate={(income: IHallBookingReceipt) => {
					updateHallBookingReceiptRef.current = income
					hallBookingReceiptUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(income: IHallBookingReceipt) => {
					deleteHallBookingReceiptRef.current = income
					setIsHallBookingReceiptDeleteDialogOpen(true)
				}}
				onAddClick={hallBookingReceiptAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={hallBookingReceiptList.length === 0}
			/>
			{hallBookingReceiptAddDrawerDisclosure.isOpen ? (
				<HallBookingReceiptAddDrawerFormController
					{...hallBookingReceiptAddDrawerDisclosure}
					onSuccess={() => fetchHallBookingReceipts()}
				/>
			) : null}
			{updateHallBookingReceiptRef.current &&
				hallBookingReceiptUpdateDrawerDisclosure.isOpen && (
					<HallBookingReceiptUpdateDrawerFormController
						{...hallBookingReceiptUpdateDrawerDisclosure}
						hallBookingReceipt={updateHallBookingReceiptRef.current}
						onSuccess={() => fetchHallBookingReceipts()}
					/>
				)}
			{deleteHallBookingReceiptRef.current && (
				<HallBookingReceiptDeleteDialogController
					isOpen={isHallBookingReceiptDeleteDialogOpen}
					setIsOpen={setIsHallBookingReceiptDeleteDialogOpen}
					hallBookingReceipt={deleteHallBookingReceiptRef.current}
					onSuccess={() => fetchHallBookingReceipts()}
				/>
			)}
		</DashboardWrapper>
	)
}
