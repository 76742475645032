import { createContext, FC, useContext, useState } from "react"

interface IAuthState {
	token: string | null
	setToken: Function
}

const AuthContext = createContext<IAuthState>({
	token: null,
	setToken: () => {},
})

export const AuthContextProvider: FC = ({ children, ...rest }) => {
	const [token, setStateToken] = useState<IAuthState["token"]>(null)

	const setToken = (value: string | null) => {
		setStateToken(value)

		if (value?.toString() === "null") return
		if (value) localStorage.setItem("token", value)
	}

	return (
		<AuthContext.Provider value={{ token, setToken }} {...rest}>
			{children}
		</AuthContext.Provider>
	)
}

export function useAuthContext() {
	return useContext(AuthContext)
}
