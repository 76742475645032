import { FC } from "react"
import { IIncome } from "src/domain/entities"
import { IncomeCategorySlugs } from "src/utils/enums"
import {
	LifetimeMemberFeesReceiptPrintView,
	OtherIncomeRecepitPrintView,
	OtherPaymentRecepitPrintView,
	ShopRentReceiptPrintView,
	TrustFundReceiptPrintView,
} from "../../ReceiptPrintsPage"

export const IncomePrintView: FC<{ income: IIncome }> = ({ income }) => {
	switch (income.category?.slug) {
		case IncomeCategorySlugs.TRUST_FUND_RECEIPT:
			// console.log("income", income.fields?.map((field) => field.value)[1])
			// break
			return <TrustFundReceiptPrintView income={income} />
		case IncomeCategorySlugs.LIFETIME_MEMBER_RECEIPT:
			return <LifetimeMemberFeesReceiptPrintView income={income} />
		case IncomeCategorySlugs.OTHER_INCOME_RECEIPT:
			return <OtherIncomeRecepitPrintView income={income} />
		case IncomeCategorySlugs.OTHER_PAYMENT_RECEIPT:
			return <OtherPaymentRecepitPrintView income={income} />
		case IncomeCategorySlugs.SHOP_RENT_RECEIPT:
			return <ShopRentReceiptPrintView income={income} />
	}

	return <>Unsupported income category</>
}
