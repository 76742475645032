import { IShop } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update?: {
		blockNo?: string
		rent?: number
		gstPercentage?: number
		note?: string
		currentRenteeId?: string
	}
}

export async function shopUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/shop/update/v1"
	const requester = new ApiRequester<RequestDataShape, IShop>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
