import { useCallback, useState } from "react"
import { IPayment } from "src/domain/entities"
import { paymentListService } from "src/domain/services/payment/paymentListService"
import { useAuth } from ".."

export function usePaymentListService(): {
	paymentList: IPayment[]
	isLoading: boolean
	fetchPaymentList: (filter?: Parameters<typeof paymentListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [paymentList, setPaymentList] = useState<IPayment[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchPaymentList = useCallback(
		async (filter: Parameters<typeof paymentListService>[0] = {}) => {
			setIsLoading(true)
			const data = await paymentListService(filter, token)
			setPaymentList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { paymentList, isLoading, fetchPaymentList }
}
