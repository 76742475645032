import { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAuthContext } from "../context/auth"

export const LogoutPage: FC = () => {
	const { setToken } = useAuthContext()
	setToken(null)
	localStorage.clear()

	return <Navigate to="/" />
}
