import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IContact, IShop } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IShopUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	shop: IShop
	contactList: IContact[]
	handleSubmit: FormikOnSubmit<IShopUpdateFormFields>
}

export const ShopUpdateDrawerFormView: FC<Props> = ({
	shop,
	handleSubmit,
	contactList,
	...rest
}) => (
	<Formik<IShopUpdateFormFields>
		initialValues={{ ...shop, currentRenteeId: shop.currentRentee?.id }}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const contactOptions = contactList.map((contact) => ({
				label: getFullName(contact),
				value: contact.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Shop"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* Block No. */}
							<FormControl flex={1}>
								<InputLabel label="Block No." />
								<Input
									name="blockNo"
									maxLength={50}
									autoFocus
									value={values.blockNo}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="blockNo"
								/>
							</FormControl>
							{/* Current Rentee */}
							<FormControl flex={2}>
								<InputLabel label="Current Rentee" />
								<ReactSelect
									name="currentRenteeId"
									onChange={(newValue) => {
										setFieldValue(
											"currentRenteeId",
											(newValue as SelectOption).value,
										)
									}}
									value={contactOptions.find(
										(el) => el.value === values.currentRenteeId,
									)}
									options={contactOptions}
									isSearchable
								/>
							</FormControl>
						</Flex>
						<Flex gridColumnGap={2}>
							{/* Rent */}
							<FormControl flex={1}>
								<InputLabel label="Rent" />
								<Input
									name="rent"
									maxLength={50}
									type="number"
									value={values.rent}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="rent" />
							</FormControl>
							{/* GST% */}
							<FormControl flex={2}>
								<InputLabel label="GST%" />
								<Input
									name="gstPercentage"
									maxLength={50}
									type="number"
									value={values.gstPercentage}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="gstPercentage"
								/>
							</FormControl>
						</Flex>
						{/* Note */}
						<FormControl>
							<InputLabel label="Note" />
							<Input
								name="note"
								placeholder="Note"
								maxLength={50}
								value={values.note}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
