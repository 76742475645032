import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IIncome, IIncomeCategory } from "src/domain/entities"
import { incomeUpdateService } from "src/domain/services/income/incomeUpdateService"
import { useAuth } from "src/hooks"
import { useHallBookingReceiptCategoryListService } from "src/hooks/hallBookingReceiptCategory"
import { FormikOnSubmit } from "src/utils/types"
import {
	HallBookingReceiptUpdateDrawerFormView,
	IHallBookingReceiptUpdateFormFields,
} from "."

type Props = Omit<
	ComponentProps<typeof HallBookingReceiptUpdateDrawerFormView>,
	| "handleSubmit"
	| "hallBookingReceiptCategoryList"
	| "selectedHallBookingReceiptCategory"
	| "setSelectedHallBookingReceiptCategory"
> & {
	onSuccess?: (income: IIncome) => void
}

export const HallBookingReceiptUpdateDrawerFormController: FC<Props> = ({
	hallBookingReceipt,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { hallBookingReceiptCategoryList, fetchHallBookingReceiptCategoryList } =
		useHallBookingReceiptCategoryListService()

	const [selectedHallBookingReceiptCategory, setSelectedHallBookingReceiptCategory] =
		useState<IIncomeCategory | undefined>(hallBookingReceipt.category)

	const handleSubmit = useCallback<FormikOnSubmit<IHallBookingReceiptUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.categoryId === hallBookingReceipt.category?.id) {
					values.categoryId = undefined
				}
				const updatedIncome = await incomeUpdateService(
					{ id: hallBookingReceipt.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedIncome)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ incomeNo: message })
			}
		},
		[onSuccess, rest, token, hallBookingReceipt.id, hallBookingReceipt.category],
	)

	useEffect(() => {
		fetchHallBookingReceiptCategoryList({ fetch: { fields: true } }).then((res) => {
			setSelectedHallBookingReceiptCategory(
				res.find((c) => c.id === hallBookingReceipt.category?.id),
			)
		})
	}, [fetchHallBookingReceiptCategoryList, hallBookingReceipt.category])

	return (
		<HallBookingReceiptUpdateDrawerFormView
			hallBookingReceipt={hallBookingReceipt}
			handleSubmit={handleSubmit}
			hallBookingReceiptCategoryList={hallBookingReceiptCategoryList}
			selectedHallBookingReceiptCategory={selectedHallBookingReceiptCategory}
			setSelectedHallBookingReceiptCategory={setSelectedHallBookingReceiptCategory}
			{...rest}
		/>
	)
}
