import { IExpense } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	expenseNo: number
	date: number
	categoryId: string
	fields: {
		fieldId: string
		value: any
	}[]
}

export async function expenseAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/expense/add/v1"
	const requester = new ApiRequester<RequestDataShape, IExpense>(endpoint)
	return await requester.sendRequest(payload, token)
}
