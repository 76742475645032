import { IconButton } from "@chakra-ui/react"
import React from "react"
import { BsPrinter } from "react-icons/bs"

export const PrintIconButton: React.FC<{
	onClick?: Function | (() => void)
	size?: string
}> = ({ onClick, size, ...rest }) => (
	<IconButton
		aria-label="print"
		variant="ghost"
		icon={<BsPrinter />}
		size={size ?? "sm"}
		mx="1"
		colorScheme={"green"}
		color={"green.600"}
		onClick={(e) => {
			e.stopPropagation()
			onClick && onClick()
		}}
		{...rest}
	/>
)
