import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { ILocation } from "src/domain/entities"
import { LocationListView } from "."

interface Props {
	list: ILocation[]
	isLoading: boolean
	onUpdate: (location: ILocation) => void
	onDelete: (location: ILocation) => void
	onAddClick: () => void
}

export const LocationListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No locations found." onAddClick={onAddClick} />
	}

	return <LocationListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
