import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { useCommitteeMemberListService, useTenureListService } from "src/hooks"

import { ICommitteeMember } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { CommitteeMemberAddDrawerFormController } from "./CommitteeMemberAddDrawerForm"
import { CommitteeMemberDeleteDialogController } from "./CommitteeMemberDeleteDialogController"
import { CommitteeMemberListController } from "./CommitteeMemberList"
import { CommitteeMemberUpdateDrawerFormController } from "./CommitteeMemberUpdateDrawerForm"

export const CommitteeMembersPage: FC = () => {
	const { committeeMemberList, isLoading, fetchCommitteeMemberList } =
		useCommitteeMemberListService()
	const { tenureList, fetchTenureList } = useTenureListService()
	const [isCommitteeMemberDeleteDialogOpen, setIsCommitteeMemberDeleteDialogOpen] =
		useState(false)
	const committeeMemberAddDrawerDisclosure = useDisclosure()
	const committeeMemberUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [selectedTenure, setSelectedTenure] = useState<SelectOption | null>({
		label: "All",
		value: null,
	})
	const tenureOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...tenureList.map((tenure) => ({
				label: tenure.term.en + "(" + tenure.term.gu + ")",
				value: tenure.id,
			})),
		]
	}, [tenureList])

	const fetchCommitteeMembers = useCallback(() => {
		fetchCommitteeMemberList({
			fetch: { tenure: true, profile: { surname: {} } },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			tenureId: selectedTenure?.value ?? undefined,
		})
	}, [fetchCommitteeMemberList, pagination.currentPage, selectedTenure])

	useEffect(() => {
		fetchCommitteeMembers()
		fetchTenureList()
	}, [fetchCommitteeMembers, fetchTenureList])

	const updateCommitteeMemberRef = useRef<ICommitteeMember>()
	const deleteCommitteeMemberRef = useRef<ICommitteeMember>()

	return (
		<DashboardWrapper>
			<Box padding={2} pb={0}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Committee Members
					</Text>
					<Button
						size="sm"
						onClick={committeeMemberAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Committee Member
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box minW={"230px"}>
						<InputLabel label="Tenure" />
						<ReactSelect
							onChange={(newValue) => {
								setSelectedTenure(
									tenureOptions.find(
										(el) => el.value === newValue?.value,
									) ?? tenureOptions[0],
								)
							}}
							value={selectedTenure}
							options={tenureOptions}
							isSearchable={false}
						/>
					</Box>
				</Flex>
			</Box>
			<CommitteeMemberListController
				list={committeeMemberList}
				isLoading={isLoading}
				onUpdate={(committeeMember: ICommitteeMember) => {
					updateCommitteeMemberRef.current = committeeMember
					committeeMemberUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(committeeMember: ICommitteeMember) => {
					deleteCommitteeMemberRef.current = committeeMember
					setIsCommitteeMemberDeleteDialogOpen(true)
				}}
				onAddClick={committeeMemberAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={committeeMemberList.length === 0}
			/>
			{committeeMemberAddDrawerDisclosure.isOpen ? (
				<CommitteeMemberAddDrawerFormController
					{...committeeMemberAddDrawerDisclosure}
					onSuccess={() => fetchCommitteeMembers()}
				/>
			) : null}
			{updateCommitteeMemberRef.current &&
				committeeMemberUpdateDrawerDisclosure.isOpen && (
					<CommitteeMemberUpdateDrawerFormController
						{...committeeMemberUpdateDrawerDisclosure}
						committeeMember={updateCommitteeMemberRef.current}
						onSuccess={() => fetchCommitteeMembers()}
					/>
				)}
			{deleteCommitteeMemberRef.current && (
				<CommitteeMemberDeleteDialogController
					isOpen={isCommitteeMemberDeleteDialogOpen}
					setIsOpen={setIsCommitteeMemberDeleteDialogOpen}
					committeeMember={deleteCommitteeMemberRef.current}
					onSuccess={() => fetchCommitteeMembers()}
				/>
			)}
		</DashboardWrapper>
	)
}
