import { IContactCategory } from "src/domain/entities"
import { contactCategoryListApi } from "../../api/contactCategory/contactCategoryListApi"

type PayloadShape = Parameters<typeof contactCategoryListApi>[0]

export async function contactCategoryListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const contactCategory = await contactCategoryListApi(payload, token)
	return contactCategory as NonNullable<IContactCategory[]>
}
