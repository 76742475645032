import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IContact } from "src/domain/entities"
import { contactUpdateService } from "src/domain/services/contact/contactUpdateService"
import { useAuth, useContactCategoryListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IContactUpdateFormFields, ContactUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ContactUpdateDrawerFormView>,
	"handleSubmit" | "categoryList"
> & {
	onSuccess?: (contact: IContact) => void
}

export const ContactUpdateDrawerFormController: FC<Props> = ({
	contact,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { contactCategoryList, fetchContactCategoryList } =
		useContactCategoryListService()

	const handleSubmit = useCallback<FormikOnSubmit<IContactUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedContact = await contactUpdateService(
					{ id: contact.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedContact)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ firstName: message })
			}
		},
		[onSuccess, rest, token, contact.id],
	)

	useEffect(() => {
		fetchContactCategoryList()
	}, [fetchContactCategoryList])

	return (
		<ContactUpdateDrawerFormView
			contact={contact}
			handleSubmit={handleSubmit}
			categoryList={contactCategoryList}
			{...rest}
		/>
	)
}
