import { IAccount } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function accountListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/account/list/v1"
	const requester = new ApiRequester<RequestDataShape, IAccount[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
