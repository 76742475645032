import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useLocationListService } from "src/hooks/location"

import { ILocation } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { LocationAddDrawerFormController } from "./LocationAddDrawerForm"
import { LocationDeleteDialogController } from "./LocationDeleteDialogController"
import { LocationListController } from "./LocationList"
import { LocationUpdateDrawerFormController } from "./LocationUpdateDrawerForm"

export const LocationsPage: FC = () => {
	const { locationList, isLoading, fetchLocationList } = useLocationListService()
	const [isLocationDeleteDialogOpen, setIsLocationDeleteDialogOpen] = useState(false)
	const locationAddDrawerDisclosure = useDisclosure()
	const locationUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchLocationList()
	}, [fetchLocationList])

	const updateLocationRef = useRef<ILocation>()
	const deleteLocationRef = useRef<ILocation>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Locations
					</Text>
					<Button
						size="sm"
						onClick={locationAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Location
					</Button>
				</Flex>
			</Box>
			<LocationListController
				list={locationList}
				isLoading={isLoading}
				onUpdate={(location: ILocation) => {
					updateLocationRef.current = location
					locationUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(location: ILocation) => {
					deleteLocationRef.current = location
					setIsLocationDeleteDialogOpen(true)
				}}
				onAddClick={locationAddDrawerDisclosure.onOpen}
			/>
			{locationAddDrawerDisclosure.isOpen ? (
				<LocationAddDrawerFormController
					{...locationAddDrawerDisclosure}
					onSuccess={() => fetchLocationList()}
				/>
			) : null}
			{updateLocationRef.current && locationUpdateDrawerDisclosure && (
				<LocationUpdateDrawerFormController
					{...locationUpdateDrawerDisclosure}
					location={updateLocationRef.current}
					onSuccess={() => fetchLocationList()}
				/>
			)}
			{deleteLocationRef.current && isLocationDeleteDialogOpen && (
				<LocationDeleteDialogController
					isOpen={isLocationDeleteDialogOpen}
					setIsOpen={setIsLocationDeleteDialogOpen}
					location={deleteLocationRef.current}
					onSuccess={() => fetchLocationList()}
				/>
			)}
		</DashboardWrapper>
	)
}
