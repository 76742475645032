import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	username: string
	password: string
}

interface ResponseDataShape {
	token: string
}

export async function adminLoginApi(payload: RequestDataShape) {
	const endpoint = "/admin/login/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload)
	return response
}
