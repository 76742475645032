import { ITenure } from "./tenure"

export type ICommitteeMember = {
	id: string
	designation: MultiLangText
	category: CommitteeMemberCategory
	profile?: any
	tenure?: ITenure
	createdAt: number
	updatedAt: number
}

export type MultiLangText = { en: string; gu: string }

export enum CommitteeMemberCategory {
	TRUSTEE = "trustee",
	WORKING_BODY = "working_body",
}
