import { CommitteeMemberCategory, ICommitteeMember, MultiLangText } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update?: {
		designation?: MultiLangText
		category?: CommitteeMemberCategory
		profileId?: string
		tenureId?: string
	}
}

export async function committeeMemberUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/committee/member/update/v1"
	const requester = new ApiRequester<RequestDataShape, ICommitteeMember>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
