import { IContact } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	firstName: string
	middleName?: string
	lastName?: string
	address: string
	categoryId: string
	mobile?: string
	email?: string
	note?: string
}

export async function contactAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/contact/add/v1"
	const requester = new ApiRequester<RequestDataShape, IContact>(endpoint)
	return await requester.sendRequest(payload, token)
}
