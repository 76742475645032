import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { ITenure } from "src/domain/entities"
import { TenureListView } from "."

interface Props {
	list: ITenure[]
	isLoading: boolean
	onUpdate: (tenure: ITenure) => void
	onDelete: (tenure: ITenure) => void
	onAddClick: () => void
}

export const TenureListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No tenures found." onAddClick={onAddClick} />
	}

	return <TenureListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
