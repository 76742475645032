import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IItemCategory } from "src/domain/entities"
import { ItemCategoryListView } from "./ItemCategoryListView"

interface Props {
	list: IItemCategory[]
	isLoading: boolean
	onUpdate: (itemCategory: IItemCategory) => void
	onDelete: (itemCategory: IItemCategory) => void
	onAddClick: () => void
}

export const ItemCategoryListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No Item Categories found."
				onAddClick={onAddClick}
			/>
		)
	}

	return <ItemCategoryListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
