import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IItemCategory } from "src/domain/entities"

export const ItemCategoryListView: FC<{
	list: IItemCategory[]
	onUpdate: (itemCategory: IItemCategory) => void
	onDelete: (itemCategory: IItemCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Slug</Th>
				<Th>Note</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((itemCategory, i) => (
				<Tr key={i}>
					<Td>{itemCategory.name}</Td>
					<Td>{itemCategory.slug}</Td>

					<Td>{itemCategory.note ? itemCategory.note : "-"}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(itemCategory)} />
						<DeleteIconButton onClick={() => onDelete(itemCategory)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
