import { IStudent } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update?: {
		studentNo?: number
		standardId?: string
		firstName?: string
		middleName?: string
		lastName?: string
	}
}

export async function studentUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/student/update/v1"
	const requester = new ApiRequester<RequestDataShape, IStudent>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
