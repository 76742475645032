import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { usePaymentListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { IPayment } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { PaymentAddDrawerFormController } from "./PaymentAddDrawerForm"
import { PaymentDeleteDialogController } from "./PaymentDeleteDialogController"
import { PaymentListController } from "./PaymentList"
import { PaymentUpdateDrawerFormController } from "./PaymentUpdateDrawerForm"

export const PaymentsPage: FC = () => {
	const { paymentList, isLoading, fetchPaymentList } = usePaymentListService()
	const [isPaymentDeleteDialogOpen, setIsPaymentDeleteDialogOpen] = useState(false)
	const paymentAddDrawerDisclosure = useDisclosure()
	const paymentUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchPayments = useCallback(() => {
		fetchPaymentList({
			fetch: { receipt: true, receiptStudent: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
		})
	}, [fetchPaymentList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchPayments()
	}, [fetchPayments])

	const updatePaymentRef = useRef<IPayment>()
	const deletePaymentRef = useRef<IPayment>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Payments
					</Text>
					<Button
						size="sm"
						onClick={paymentAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Payment
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				</Flex>
			</Box>
			<PaymentListController
				list={paymentList}
				isLoading={isLoading}
				onUpdate={(payment: IPayment) => {
					updatePaymentRef.current = payment
					paymentUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(payment: IPayment) => {
					deletePaymentRef.current = payment
					setIsPaymentDeleteDialogOpen(true)
				}}
				onAddClick={paymentAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={paymentList.length === 0}
			/>
			<PaymentAddDrawerFormController
				{...paymentAddDrawerDisclosure}
				onSuccess={() => fetchPayments()}
			/>
			{updatePaymentRef.current && (
				<PaymentUpdateDrawerFormController
					{...paymentUpdateDrawerDisclosure}
					payment={updatePaymentRef.current}
					onSuccess={() => fetchPayments()}
				/>
			)}
			{deletePaymentRef.current && (
				<PaymentDeleteDialogController
					isOpen={isPaymentDeleteDialogOpen}
					setIsOpen={setIsPaymentDeleteDialogOpen}
					payment={deletePaymentRef.current}
					onSuccess={() => fetchPayments()}
				/>
			)}
		</DashboardWrapper>
	)
}
