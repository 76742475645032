import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IPayment } from "src/domain/entities"
import { paymentAddService } from "src/domain/services/payment/paymentAddService"
import { useAuth, useReceiptListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IPaymentAddFormFields, PaymentAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof PaymentAddDrawerFormView>,
		"handleSubmit" | "receiptList"
	> {
	onSuccess: (payment: IPayment) => void
}

export const PaymentAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { receiptList, fetchReceiptList } = useReceiptListService()

	const handleSubmit = useCallback<FormikOnSubmit<IPaymentAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const payment = await paymentAddService(values, token)
				onSuccess(payment)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ paymentAmount: message })
			}
		},
		[onSuccess, rest, token],
	)

	useEffect(() => {
		fetchReceiptList({ fetch: { student: true } })
	}, [fetchReceiptList])

	return (
		<PaymentAddDrawerFormView
			receiptList={receiptList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
