import { IAccount } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	accountNo: string
	bankName: string
	ifscCode: string
	note?: string
}

export async function accountAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/account/add/v1"
	const requester = new ApiRequester<RequestDataShape, IAccount>(endpoint)
	return await requester.sendRequest(payload, token)
}
