import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IItem } from "src/domain/entities"
import { itemAddService } from "src/domain/services/item/itemAddService"
import { useAuth } from "src/hooks"
import { useItemCategoryListService } from "src/hooks/itemCategory"
import { FormikOnSubmit } from "src/utils/types"
import { IItemAddFormFields, ItemAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ItemAddDrawerFormView>,
		"handleSubmit" | "itemCategoryList"
	> {
	onSuccess: (item: IItem) => void
}

export const ItemAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { itemCategoryList, fetchItemCategoryList } = useItemCategoryListService()

	const handleSubmit = useCallback<FormikOnSubmit<IItemAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const item = await itemAddService(values, token)
				onSuccess(item)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	useEffect(() => {
		fetchItemCategoryList()
	}, [fetchItemCategoryList])

	return (
		<ItemAddDrawerFormView
			handleSubmit={handleSubmit}
			itemCategoryList={itemCategoryList}
			{...rest}
		/>
	)
}
