import dayjs from "dayjs"
import { receiptAddApi } from "../../api/receipt/receiptAddApi"

type PayloadShape = Parameters<typeof receiptAddApi>[0]

export async function receiptAddService(payload: PayloadShape, token: string) {
	payload.receiptNo = +payload.receiptNo
	payload.receiptItems.forEach((item) => {
		item.amount = +item.amount
	})

	payload.receiptDate = dayjs(payload.receiptDate).unix() * 1000

	return await receiptAddApi(payload, token)
}
