import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IContact, IContactCategory } from "src/domain/entities"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IContactUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	contact: IContact
	categoryList: IContactCategory[]
	handleSubmit: FormikOnSubmit<IContactUpdateFormFields>
}

export const ContactUpdateDrawerFormView: FC<Props> = ({
	contact,
	handleSubmit,
	categoryList,
	...rest
}) => {
	const [firstNameSuggestions, setFirstNameSuggestions] = useState<string[]>([])
	const [middleNameSuggestions, setMiddleNameSuggestions] = useState<string[]>([])
	const [lastNameSuggestions, setLastNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IContactUpdateFormFields>
			initialValues={{ ...contact, categoryId: contact.category?.id }}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
					type: "firstName" | "middleName" | "lastName",
				) => {
					setFieldValue(type, e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)

					if (type === "firstName") {
						setFirstNameSuggestions(suggestions)
					} else if (type === "middleName") {
						setMiddleNameSuggestions(suggestions)
					} else if (type === "lastName") {
						setLastNameSuggestions(suggestions)
					}
				}

				const categoryOptions = categoryList.map((category) => ({
					label: category.name,
					value: category.id,
				}))
				return (
					<DrawerForm
						size="sm"
						headerLabel="Update Contact"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
							{/* ContactCategory */}
							<FormControl>
								<InputLabel label="ContactCategory" />
								<ReactSelect
									name="categoryId"
									value={categoryOptions.find(
										(el) => el.value === values.categoryId,
									)}
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
									}}
									options={categoryOptions}
									isSearchable
								/>
							</FormControl>
							<Flex gridColumnGap={2}>
								{/* First Name */}
								<Box flex={1}>
									<FormControl>
										<InputLabel label="First Name" />
										<Input
											name="firstName"
											maxLength={50}
											required
											value={values.firstName}
											onChange={(e) =>
												handleNameChange(e, "firstName")
											}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="firstName"
										/>
									</FormControl>
									<Box>
										{firstNameSuggestions.map((el) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("firstName", el)
													setFirstNameSuggestions([])
												}}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Middle Name */}
								<Box flex={1}>
									<FormControl>
										<InputLabel label="Middle Name" />
										<Input
											name="middleName"
											maxLength={50}
											value={values.middleName}
											onChange={(e) =>
												handleNameChange(e, "middleName")
											}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="middleName"
										/>
									</FormControl>
									<Box>
										{middleNameSuggestions.map((el) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("middleName", el)
													setMiddleNameSuggestions([])
												}}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Last Name */}
								<Box flex={1}>
									<FormControl>
										<InputLabel label="Last Name" />
										<Input
											name="lastName"
											maxLength={50}
											value={values.lastName}
											onChange={(e) =>
												handleNameChange(e, "lastName")
											}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="lastName"
										/>
										<Box>
											{lastNameSuggestions.map((el) => (
												<Tag
													colorScheme={"green"}
													backgroundColor={"green.50"}
													variant="outline"
													_hover={{
														backgroundColor: "green.100",
													}}
													cursor="pointer"
													margin={0.5}
													onClick={() => {
														setFieldValue("lastName", el)
														setLastNameSuggestions([])
													}}
												>
													{el}
												</Tag>
											))}
										</Box>
									</FormControl>
								</Box>
							</Flex>
							<Flex gridColumnGap={2}>
								{/* Mobile */}
								<FormControl>
									<InputLabel label="Mobile" />
									<Input
										name="mobile"
										placeholder="+91-XXXXXXXXXX"
										maxLength={15}
										value={values.mobile}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="mobile"
									/>
								</FormControl>
								{/* Email */}
								<FormControl>
									<InputLabel label="Email" />
									<Input
										name="email"
										placeholder="Email"
										maxLength={50}
										value={values.email}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="email"
									/>
								</FormControl>
							</Flex>
							{/* Address */}
							<FormControl>
								<InputLabel label="Address" />
								<Input
									name="address"
									placeholder="Address"
									value={values.address}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="address"
								/>
							</FormControl>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									value={values.note}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
