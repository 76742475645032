import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useContactCategoryListService } from "src/hooks/contactCategory"

import { IContactCategory } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ContactCategoryAddDrawerFormController } from "./ContactCategoryAddDrawerForm"
import { ContactCategoryDeleteDialogController } from "./ContactCategoryDeleteDialogController"
import { ContactCategoryListController } from "./ContactCategoryList"
import { ContactCategoryUpdateDrawerFormController } from "./ContactCategoryUpdateDrawerForm"

export const ContactCategoriesPage: FC = () => {
	const { contactCategoryList, isLoading, fetchContactCategoryList } =
		useContactCategoryListService()
	const [isContactCategoryDeleteDialogOpen, setIsContactCategoryDeleteDialogOpen] =
		useState(false)
	const contactCategoryAddDrawerDisclosure = useDisclosure()
	const contactCategoryUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchContactCategoryList()
	}, [fetchContactCategoryList])

	const updateContactCategoryRef = useRef<IContactCategory>()
	const deleteContactCategoryRef = useRef<IContactCategory>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Contact Categories
					</Text>
					<Button
						size="sm"
						onClick={contactCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Contact Category
					</Button>
				</Flex>
			</Box>
			<ContactCategoryListController
				list={contactCategoryList}
				isLoading={isLoading}
				onUpdate={(contactCategory: IContactCategory) => {
					updateContactCategoryRef.current = contactCategory
					contactCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(contactCategory: IContactCategory) => {
					deleteContactCategoryRef.current = contactCategory
					setIsContactCategoryDeleteDialogOpen(true)
				}}
				onAddClick={contactCategoryAddDrawerDisclosure.onOpen}
			/>
			{contactCategoryAddDrawerDisclosure.isOpen ? (
				<ContactCategoryAddDrawerFormController
					{...contactCategoryAddDrawerDisclosure}
					onSuccess={() => fetchContactCategoryList()}
				/>
			) : null}
			{updateContactCategoryRef.current &&
				contactCategoryUpdateDrawerDisclosure && (
					<ContactCategoryUpdateDrawerFormController
						{...contactCategoryUpdateDrawerDisclosure}
						contactCategory={updateContactCategoryRef.current}
						onSuccess={() => fetchContactCategoryList()}
					/>
				)}
			{deleteContactCategoryRef.current && isContactCategoryDeleteDialogOpen && (
				<ContactCategoryDeleteDialogController
					isOpen={isContactCategoryDeleteDialogOpen}
					setIsOpen={setIsContactCategoryDeleteDialogOpen}
					contactCategory={deleteContactCategoryRef.current}
					onSuccess={() => fetchContactCategoryList()}
				/>
			)}
		</DashboardWrapper>
	)
}
