import { IReceipt } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	standardId?: string
	receiptNo?: number
	studentId?: string
	fetch?: {
		standard?: boolean
		student?: boolean
		receiptItems?: boolean
		payments?: boolean
	}
	pagination?: {
		page: number
		limit: number
	}
}

export async function receiptListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/receipt/list/v1"
	const requester = new ApiRequester<RequestDataShape, IReceipt[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
