import { IShop } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	blockNo: string
	rent: number
	gstPercentage: number
	note?: string
	currentRenteeId: string
}

export async function shopAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/shop/add/v1"
	const requester = new ApiRequester<RequestDataShape, IShop>(endpoint)
	return await requester.sendRequest(payload, token)
}
