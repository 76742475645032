import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { createRef, FC, RefObject, useCallback, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { PrintIconButton } from "src/components/ui/iconButtons/PrintIconButton"
import { IContact, IHallBookingReceipt } from "src/domain/entities"
import { HallBookingReceiptCategorySlugs } from "src/utils/enums"
import { getFullName } from "src/utils/helpers"
import { HallReceiptPreviewDialogController } from "./HallBookingReceiptPreviewDialogController"
import { HallBookingReceiptPrintView } from "./HallBookingReceiptPrintView"

export const HallReceiptListView: FC<{
	list: IHallBookingReceipt[]
	onUpdate: (hallReceipt: IHallBookingReceipt) => void
	onDelete: (hallReceipt: IHallBookingReceipt) => void
}> = ({ list, onUpdate, onDelete }) => {
	const printComponentRefs = useRef<RefObject<HTMLDivElement>[]>(
		list.map(() => createRef<HTMLDivElement>()),
	)

	const reactToPrintContent = useCallback((index: number) => {
		return printComponentRefs.current[index].current
	}, [])

	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const selectedPreviewHallReceipt = useRef<IHallBookingReceipt | null>(null)
	const selectedPreviewHallReceiptIndex = useRef<number | null>(null)

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				body {
					padding: 0.5cm;
				}
				table {
					border: solid #000 !important;
					border-width: 1px 0 0 1px !important;
				}
				table th, table td {
					border: solid #000 !important;
					border-width: 0 1px 1px 0 !important;
				}
			}
		`
	}

	return (
		<>
			<Table size={"sm"}>
				<Thead>
					<Tr>
						<Th width={"15%"}>Receipt No.</Th>
						<Th width={"15%"}>Lobby / Area</Th>
						<Th>Contact</Th>
						<Th>Contact No.</Th>
						<Th>Reason</Th>
						<Th>Deposit</Th>
						<Th isNumeric>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((hallReceipt, i) => (
						<Tr
							key={hallReceipt.id}
							_hover={{
								backgroundColor: "gray.100",
							}}
							cursor="pointer"
							onClick={() => {
								selectedPreviewHallReceipt.current = hallReceipt
								selectedPreviewHallReceiptIndex.current = i
								setIsPreviewOpen(true)
							}}
						>
							<Td>{hallReceipt.hallBookingReceiptNo}</Td>
							<Td>
								{
									hallReceipt.fields.find(
										(field) => field.field.name === "Lobby / Area",
									)?.value.name
								}
							</Td>
							<Td>{getHallReceiptContact(hallReceipt)}</Td>
							<Td>{getHallReceiptContactNumber(hallReceipt)}</Td>
							<Td>
								{
									hallReceipt.fields.find(
										(field) => field.field.name === "Reason",
									)?.value
								}
							</Td>
							<Td>
								{
									hallReceipt.fields.find(
										(field) => field.field.name === "Amount",
									)?.value
								}
							</Td>
							<Td isNumeric>
								<div ref={printComponentRefs.current[i]}>
									<style>{getPageStyles()}</style>
									<div className="printable">
										<div style={{ pageBreakBefore: "always" }}>
											<HallBookingReceiptPrintView
												hallBookingReceipt={hallReceipt}
											/>
										</div>
									</div>
								</div>
								<ReactToPrint
									content={() => reactToPrintContent(i)}
									copyStyles={true}
									trigger={() => <PrintIconButton />}
								/>
								<EditIconButton onClick={() => onUpdate(hallReceipt)} />
								<DeleteIconButton onClick={() => onDelete(hallReceipt)} />
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			{selectedPreviewHallReceipt.current &&
			selectedPreviewHallReceiptIndex.current != null &&
			isPreviewOpen ? (
				<HallReceiptPreviewDialogController
					isOpen={isPreviewOpen}
					setIsOpen={setIsPreviewOpen}
					hallBookingReceipt={selectedPreviewHallReceipt.current}
				/>
			) : null}
		</>
	)
}

const getHallReceiptContact = (hallReceipt: IHallBookingReceipt) => {
	let particulars = ""

	switch (hallReceipt.category?.slug) {
		case HallBookingReceiptCategorySlugs.VAADI_BHADA_DEPOSIT_RECEIPT: {
			const contact: IContact = hallReceipt.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case HallBookingReceiptCategorySlugs.PARTY_PLOT_BHADA_DEPOSIT_RECEIPT: {
			const contact: IContact = hallReceipt.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
	}
	return particulars
}

const getHallReceiptContactNumber = (hallReceipt: IHallBookingReceipt) => {
	let particulars = ""

	switch (hallReceipt.category?.slug) {
		case HallBookingReceiptCategorySlugs.VAADI_BHADA_DEPOSIT_RECEIPT: {
			const contact: IContact = hallReceipt.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = contact?.mobile ?? "-"
			break
		}
		case HallBookingReceiptCategorySlugs.PARTY_PLOT_BHADA_DEPOSIT_RECEIPT: {
			const contact: IContact = hallReceipt.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = contact?.mobile ?? "-"
			break
		}
	}
	return particulars
}
