import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IItem } from "src/domain/entities"
import { itemUpdateService } from "src/domain/services/item/itemUpdateService"
import { useAuth } from "src/hooks"
import { useItemCategoryListService } from "src/hooks/itemCategory"
import { FormikOnSubmit } from "src/utils/types"
import { IItemUpdateFormFields, ItemUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ItemUpdateDrawerFormView>,
	"handleSubmit" | "categoryList"
> & {
	onSuccess?: (item: IItem) => void
}

export const ItemUpdateDrawerFormController: FC<Props> = ({
	item,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { itemCategoryList, fetchItemCategoryList } = useItemCategoryListService()

	const handleSubmit = useCallback<FormikOnSubmit<IItemUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedItem = await itemUpdateService(
					{ id: item.id, categoryId: item.category?.id ?? "", update: values },
					token,
				)
				onSuccess && onSuccess(updatedItem)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, item.id, item.category?.id],
	)

	useEffect(() => {
		fetchItemCategoryList()
	}, [fetchItemCategoryList])

	return (
		<ItemUpdateDrawerFormView
			item={item}
			handleSubmit={handleSubmit}
			categoryList={itemCategoryList}
			{...rest}
		/>
	)
}
