import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IItem } from "src/domain/entities"

export const ItemListView: FC<{
	list: IItem[]
	onUpdate: (item: IItem) => void
	onDelete: (item: IItem) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Rate</Th>
				<Th>Category</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((item, i) => (
				<Tr key={i}>
					<Td>{item.name}</Td>
					<Td>{item.rate}</Td>
					<Td>{item.category?.name}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(item)} />
						<DeleteIconButton onClick={() => onDelete(item)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
