import { IReceipt } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	receiptNo: number
	receiptDate: number
	studentId: string
	receiptItems: {
		itemId: string
		amount: number
	}[]
}

export async function receiptAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/receipt/add/v1"
	const requester = new ApiRequester<RequestDataShape, IReceipt>(endpoint)
	return await requester.sendRequest(payload, token)
}
