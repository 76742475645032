import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IReceipt } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IPaymentAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IPaymentAddFormFields>
	selectedReceiptId?: string
	receiptList: IReceipt[]
}

export const PaymentAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	receiptList,
	selectedReceiptId,
	...rest
}) => {
	const receiptOptions = receiptList.map((receipt) => ({
		label: `${receipt.receiptNo} - ${getFullName(receipt.student)}`,
		value: receipt.id,
	}))
	return (
		<Formik<IPaymentAddFormFields>
			initialValues={{
				paymentAmount: 0,
				paymentDate: dayjs().format("YYYY-MM-DD") as any,
				paymentMode: "",
				receiptId: selectedReceiptId || "",
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => (
				<DrawerForm
					size="lg"
					headerLabel="Add Payment"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* Date */}
							<FormControl flex={1}>
								<InputLabel label="Date" />
								<Input
									type="date"
									name="paymentDate"
									maxLength={50}
									value={values.paymentDate}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentDate"
								/>
							</FormControl>
							{/* Receipt */}
							<FormControl flex={2}>
								<InputLabel label="Receipt" />
								<ReactSelect
									name="receiptId"
									onChange={(newValue) => {
										setFieldValue(
											"receiptId",
											(newValue as SelectOption).value,
										)
									}}
									value={receiptOptions.find(
										(el) => el.value === values.receiptId,
									)}
									options={receiptOptions}
									isSearchable
								/>
							</FormControl>
						</Flex>
						<Flex gridColumnGap={2}>
							{/* Amount */}
							<FormControl flex={1}>
								<InputLabel label="Amount" />
								<Input
									name="paymentAmount"
									maxLength={50}
									type="number"
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentAmount"
								/>
							</FormControl>
							{/* Mode */}
							<FormControl flex={2}>
								<InputLabel label="Payment Mode" />
								<Input
									name="paymentMode"
									maxLength={50}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentMode"
								/>
							</FormControl>
						</Flex>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
