import { Text } from "@chakra-ui/react"
import { FC } from "react"

export const SKALPSLogo: FC<{ fontSize?: string }> = ({ fontSize }) => {
	return (
		<>
			<Text
				as="span"
				width={"fit-content"}
				bgGradient="linear(to-br, #FF6200, #FFD900)"
				bgClip="text"
				fontSize={fontSize ?? { base: "5xl", lg: "6xl" }}
				fontWeight={"bold"}
				lineHeight={"shorter"}
			>
				SKALPS
			</Text>
		</>
	)
}
