import { useCallback, useState } from "react"
import { IHallBookingReceiptCategory } from "src/domain/entities"
import { hallBookingReceiptCategoryListService } from "src/domain/services/hallBookingReceiptCategory/hallBookingReceiptCategoryListService"
import { useAuth } from ".."

export function useHallBookingReceiptCategoryListService(): {
	hallBookingReceiptCategoryList: IHallBookingReceiptCategory[]
	isLoading: boolean
	fetchHallBookingReceiptCategoryList: (
		filter?: Parameters<typeof hallBookingReceiptCategoryListService>[0],
	) => Promise<IHallBookingReceiptCategory[]>
} {
	const { token } = useAuth()
	const [hallBookingReceiptCategoryList, setHallBookingReceiptCategoryList] = useState<
		IHallBookingReceiptCategory[]
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchHallBookingReceiptCategoryList = useCallback(
		async (
			filter: Parameters<typeof hallBookingReceiptCategoryListService>[0] = {},
		) => {
			setIsLoading(true)
			const data = await hallBookingReceiptCategoryListService(filter, token)
			setHallBookingReceiptCategoryList(data)
			setIsLoading(false)
			return data
		},
		[token],
	)

	return {
		hallBookingReceiptCategoryList,
		isLoading,
		fetchHallBookingReceiptCategoryList,
	}
}
