import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useIncomeCategoryListService } from "src/hooks/incomeCategory"

import { IIncomeCategory } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { IncomeCategoryAddDrawerFormController } from "./IncomeCategoryAddDrawerForm"
import { IncomeCategoryDeleteDialogController } from "./IncomeCategoryDeleteDialogController"
import { IncomeCategoryListController } from "./IncomeCategoryList"
import { IncomeCategoryUpdateDrawerFormController } from "./IncomeCategoryUpdateDrawerForm"

export const IncomeCategoriesPage: FC = () => {
	const { incomeCategoryList, isLoading, fetchIncomeCategoryList } =
		useIncomeCategoryListService()
	const [isIncomeCategoryDeleteDialogOpen, setIsIncomeCategoryDeleteDialogOpen] =
		useState(false)
	const incomeCategoryAddDrawerDisclosure = useDisclosure()
	const incomeCategoryUpdateDrawerDisclosure = useDisclosure()

	const fetchIncomeCategories = useCallback(() => {
		fetchIncomeCategoryList({
			fetch: { fields: true },
		})
	}, [fetchIncomeCategoryList])

	useEffect(() => {
		fetchIncomeCategories()
	}, [fetchIncomeCategories])

	const updateIncomeCategoryRef = useRef<IIncomeCategory>()
	const deleteIncomeCategoryRef = useRef<IIncomeCategory>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Income Categories
					</Text>
					<Button
						size="sm"
						onClick={incomeCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Income Category
					</Button>
				</Flex>
			</Box>
			<IncomeCategoryListController
				list={incomeCategoryList}
				isLoading={isLoading}
				onUpdate={(incomeCategory: IIncomeCategory) => {
					updateIncomeCategoryRef.current = incomeCategory
					incomeCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(incomeCategory: IIncomeCategory) => {
					deleteIncomeCategoryRef.current = incomeCategory
					setIsIncomeCategoryDeleteDialogOpen(true)
				}}
				onAddClick={incomeCategoryAddDrawerDisclosure.onOpen}
			/>
			{incomeCategoryAddDrawerDisclosure.isOpen ? (
				<IncomeCategoryAddDrawerFormController
					{...incomeCategoryAddDrawerDisclosure}
					onSuccess={() => fetchIncomeCategories()}
				/>
			) : null}
			{updateIncomeCategoryRef.current &&
				incomeCategoryUpdateDrawerDisclosure.isOpen && (
					<IncomeCategoryUpdateDrawerFormController
						{...incomeCategoryUpdateDrawerDisclosure}
						incomeCategory={updateIncomeCategoryRef.current}
						onSuccess={() => fetchIncomeCategories()}
					/>
				)}
			{deleteIncomeCategoryRef.current && isIncomeCategoryDeleteDialogOpen && (
				<IncomeCategoryDeleteDialogController
					isOpen={isIncomeCategoryDeleteDialogOpen}
					setIsOpen={setIsIncomeCategoryDeleteDialogOpen}
					incomeCategory={deleteIncomeCategoryRef.current}
					onSuccess={() => fetchIncomeCategories()}
				/>
			)}
		</DashboardWrapper>
	)
}
