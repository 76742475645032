import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useItemCategoryListService } from "src/hooks/itemCategory/useItemCategoryListService"

import { IItemCategory } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ItemCategoryAddDrawerFormController } from "./ItemCategoryAddDrawerForm"
import { ItemCategoryDeleteDialogController } from "./ItemCategoryDeleteDialogController"
import { ItemCategoryListController } from "./ItemCategoryList"
import { ItemCategoryUpdateDrawerFormController } from "./ItemCategoryUpdateDrawerForm"

export const ItemCategoriesPage: FC = () => {
	const { itemCategoryList, isLoading, fetchItemCategoryList } =
		useItemCategoryListService()
	const [isItemCategoryDeleteDialogOpen, setIsItemCategoryDeleteDialogOpen] =
		useState(false)
	const itemCategoryAddDrawerDisclosure = useDisclosure()
	const itemCategoryUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchItemCategoryList()
	}, [fetchItemCategoryList])

	const updateItemCategoryRef = useRef<IItemCategory>()
	const deleteItemCategoryRef = useRef<IItemCategory>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Item Categories
					</Text>
					<Button
						size="sm"
						onClick={itemCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Item Category
					</Button>
				</Flex>
			</Box>
			<ItemCategoryListController
				list={itemCategoryList}
				isLoading={isLoading}
				onUpdate={(itemCategory: IItemCategory) => {
					updateItemCategoryRef.current = itemCategory
					itemCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(itemCategory: IItemCategory) => {
					deleteItemCategoryRef.current = itemCategory
					setIsItemCategoryDeleteDialogOpen(true)
				}}
				onAddClick={itemCategoryAddDrawerDisclosure.onOpen}
			/>
			{itemCategoryAddDrawerDisclosure.isOpen ? (
				<ItemCategoryAddDrawerFormController
					{...itemCategoryAddDrawerDisclosure}
					onSuccess={() => fetchItemCategoryList()}
				/>
			) : null}
			{updateItemCategoryRef.current && itemCategoryUpdateDrawerDisclosure && (
				<ItemCategoryUpdateDrawerFormController
					{...itemCategoryUpdateDrawerDisclosure}
					itemCategory={updateItemCategoryRef.current}
					onSuccess={() => fetchItemCategoryList()}
				/>
			)}
			{deleteItemCategoryRef.current && isItemCategoryDeleteDialogOpen && (
				<ItemCategoryDeleteDialogController
					isOpen={isItemCategoryDeleteDialogOpen}
					setIsOpen={setIsItemCategoryDeleteDialogOpen}
					itemCategory={deleteItemCategoryRef.current}
					onSuccess={() => fetchItemCategoryList()}
				/>
			)}
		</DashboardWrapper>
	)
}
