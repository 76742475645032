import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IAccount } from "src/domain/entities"

export const AccountListView: FC<{
	list: IAccount[]
	onUpdate: (account: IAccount) => void
	onDelete: (account: IAccount) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Account No.</Th>
				<Th>IFSC Code</Th>
				<Th>Bank Name</Th>
				<Th>Note</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((account, i) => (
				<Tr key={i}>
					<Td>{account.name}</Td>
					<Td>{account.accountNo}</Td>
					<Td>{account.ifscCode}</Td>
					<Td>{account.bankName}</Td>
					<Td>{account.note}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(account)} />
						<DeleteIconButton onClick={() => onDelete(account)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
