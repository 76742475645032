import { IIncomeCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	slug?: string
	fields: {
		name: string
		type: string
		position: number
	}[]
	note?: string
}

export async function incomeCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/income/category/add/v1"
	const requester = new ApiRequester<RequestDataShape, IIncomeCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
