import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IHallBookingReceipt, IHallBookingReceiptCategory } from "src/domain/entities"
import { hallBookingReceiptAddService } from "src/domain/services/hallBookingReceipt/hallBookingReceiptAddService"
import { hallBookingReceiptNumberGetService } from "src/domain/services/hallBookingReceipt/hallBookingReceiptNumberGetService"
import { useAuth } from "src/hooks"
import { useHallBookingReceiptCategoryListService } from "src/hooks/hallBookingReceiptCategory"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { HallBookingReceiptAddDrawerFormView, IHallBookingReceiptAddFormFields } from "."

interface Props
	extends Omit<
		ComponentProps<typeof HallBookingReceiptAddDrawerFormView>,
		| "handleSubmit"
		| "hallBookingReceiptCategoryList"
		| "hallBookingReceiptNextNumber"
		| "getGujaratiSuggestions"
		| "selectedHallBookingReceiptCategory"
		| "setSelectedHallBookingReceiptCategory"
	> {
	onSuccess: (hallBookingReceipt: IHallBookingReceipt) => void
}

export const HallBookingReceiptAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { hallBookingReceiptCategoryList, fetchHallBookingReceiptCategoryList } =
		useHallBookingReceiptCategoryListService()
	const [hallBookingReceiptNextNumber, setHallBookingReceiptNextNumber] = useState(0)
	const [selectedHallBookingReceiptCategory, setSelectedHallBookingReceiptCategory] =
		useState<IHallBookingReceiptCategory>()

	const handleSubmit = useCallback<FormikOnSubmit<IHallBookingReceiptAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const hallBookingReceipt = await hallBookingReceiptAddService(
					values,
					token,
				)
				onSuccess(hallBookingReceipt)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ hallBookingReceiptNo: message })
			}
		},
		[onSuccess, rest, token],
	)

	const fetchHallBookingReceiptNextNumber = useCallback(async () => {
		if (!selectedHallBookingReceiptCategory) return
		const hallBookingReceiptNextNumber = await hallBookingReceiptNumberGetService(
			{
				categoryId: selectedHallBookingReceiptCategory.id,
			},
			token,
		)
		setHallBookingReceiptNextNumber(hallBookingReceiptNextNumber.hallBookingReceiptNo)
	}, [token, selectedHallBookingReceiptCategory])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchHallBookingReceiptCategoryList({ fetch: { fields: true } })
		fetchHallBookingReceiptNextNumber()
	}, [fetchHallBookingReceiptCategoryList, fetchHallBookingReceiptNextNumber])

	return (
		<HallBookingReceiptAddDrawerFormView
			hallBookingReceiptCategoryList={hallBookingReceiptCategoryList}
			handleSubmit={handleSubmit}
			hallBookingReceiptNextNumber={hallBookingReceiptNextNumber}
			getGujaratiSuggestions={getGujaratiSuggestions}
			selectedHallBookingReceiptCategory={selectedHallBookingReceiptCategory}
			setSelectedHallBookingReceiptCategory={setSelectedHallBookingReceiptCategory}
			{...rest}
		/>
	)
}
