import { ComponentProps, FC, useCallback } from "react"
import { ILocation } from "src/domain/entities"
import { locationUpdateService } from "src/domain/services/location/locationUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { ILocationUpdateFormFields, LocationUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof LocationUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (location: ILocation) => void
}

export const LocationUpdateDrawerFormController: FC<Props> = ({
	location,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ILocationUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedLocation = await locationUpdateService(
					{ id: location.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedLocation)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, location.id],
	)

	return (
		<LocationUpdateDrawerFormView
			location={location}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
