import { ILocation } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function locationListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/location/list/v1"
	const requester = new ApiRequester<RequestDataShape, ILocation[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
