import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IIncome, IIncomeCategory } from "src/domain/entities"
import { incomeAddService } from "src/domain/services/income/incomeAddService"
import { incomeNumberGetService } from "src/domain/services/income/incomeNumberGetService"
import { useAuth, useIncomeCategoryListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IncomeAddDrawerFormView, IIncomeAddFormFields } from "."

interface Props
	extends Omit<
		ComponentProps<typeof IncomeAddDrawerFormView>,
		| "handleSubmit"
		| "incomeCategoryList"
		| "incomeNextNumber"
		| "getGujaratiSuggestions"
		| "selectedIncomeCategory"
		| "setSelectedIncomeCategory"
	> {
	onSuccess: (income: IIncome) => void
}

export const IncomeAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { incomeCategoryList, fetchIncomeCategoryList } = useIncomeCategoryListService()
	const [incomeNextNumber, setIncomeNextNumber] = useState(0)
	const [selectedIncomeCategory, setSelectedIncomeCategory] =
		useState<IIncomeCategory>()

	const handleSubmit = useCallback<FormikOnSubmit<IIncomeAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const income = await incomeAddService(values, token)
				onSuccess(income)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ incomeNo: message })
			}
		},
		[onSuccess, rest, token],
	)

	const fetchIncomeNextNumber = useCallback(async () => {
		if (!selectedIncomeCategory) return
		const incomeNextNumber = await incomeNumberGetService(
			{
				categoryId: selectedIncomeCategory.id,
			},
			token,
		)
		setIncomeNextNumber(incomeNextNumber.incomeNo)
	}, [token, selectedIncomeCategory])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchIncomeCategoryList({ fetch: { fields: true } })
		fetchIncomeNextNumber()
	}, [fetchIncomeCategoryList, fetchIncomeNextNumber])

	return (
		<IncomeAddDrawerFormView
			incomeCategoryList={incomeCategoryList}
			handleSubmit={handleSubmit}
			incomeNextNumber={incomeNextNumber}
			getGujaratiSuggestions={getGujaratiSuggestions}
			selectedIncomeCategory={selectedIncomeCategory}
			setSelectedIncomeCategory={setSelectedIncomeCategory}
			{...rest}
		/>
	)
}
