import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { createRef, FC, RefObject, useCallback, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { PrintIconButton } from "src/components/ui/iconButtons/PrintIconButton"
import { IContact, IExpense } from "src/domain/entities"
import { ExpenseCategorySlugs } from "src/utils/enums"
import { getFullName } from "src/utils/helpers"
import { ExpensePreviewDialogController } from "./ExpensePreviewDialogController"
import { ExpensePrintView } from "./ExpensePrintView"

export const ExpenseListView: FC<{
	list: IExpense[]
	onUpdate: (expense: IExpense) => void
	onDelete: (expense: IExpense) => void
}> = ({ list, onUpdate, onDelete }) => {
	const printComponentRefs = useRef<RefObject<HTMLDivElement>[]>(
		list.map(() => createRef<HTMLDivElement>()),
	)

	const reactToPrintContent = useCallback((index: number) => {
		return printComponentRefs.current[index].current
	}, [])

	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const selectedPreviewExpense = useRef<IExpense | null>(null)
	const selectedPreviewExpenseIndex = useRef<number | null>(null)

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				body {
					padding: 0.5cm;
				}
				table {
					border: solid #000 !important;
					border-width: 1px 0 0 1px !important;
				}
				table th, table td {
					border: solid #000 !important;
					border-width: 0 1px 1px 0 !important;
				}
			}
		`
	}

	return (
		<>
			<Table size={"sm"}>
				<Thead>
					<Tr>
						<Th width={"15%"}>Expense No.</Th>
						<Th>Contact</Th>
						<Th>Expense Category</Th>
						<Th isNumeric>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((expense, i) => (
						<Tr
							key={expense.id}
							_hover={{
								backgroundColor: "gray.100",
							}}
							cursor="pointer"
							onClick={() => {
								selectedPreviewExpense.current = expense
								selectedPreviewExpenseIndex.current = i
								setIsPreviewOpen(true)
							}}
						>
							<Td>{expense.expenseNo}</Td>
							<Td>{getExpenseContact(expense)}</Td>
							<Td>{expense.category?.name}</Td>
							<Td isNumeric>
								<div ref={printComponentRefs.current[i]}>
									<style>{getPageStyles()}</style>
									<div className="printable">
										<div style={{ pageBreakBefore: "always" }}>
											<ExpensePrintView expense={expense} />
										</div>
									</div>
								</div>
								<ReactToPrint
									content={() => reactToPrintContent(i)}
									copyStyles={true}
									trigger={() => <PrintIconButton />}
								/>
								<EditIconButton onClick={() => onUpdate(expense)} />
								<DeleteIconButton onClick={() => onDelete(expense)} />
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			{selectedPreviewExpense.current &&
			selectedPreviewExpenseIndex.current != null &&
			isPreviewOpen ? (
				<ExpensePreviewDialogController
					isOpen={isPreviewOpen}
					setIsOpen={setIsPreviewOpen}
					expense={selectedPreviewExpense.current}
				/>
			) : null}
		</>
	)
}

const getExpenseContact = (expense: IExpense) => {
	let particulars = ""

	switch (expense.category?.slug) {
		case ExpenseCategorySlugs.VAADI_VOUCHER: {
			const contact: IContact = expense.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case ExpenseCategorySlugs.SAMAJ_VOUCHER: {
			const contact: IContact = expense.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case ExpenseCategorySlugs.PARTY_PLOT_VOUCHER: {
			const contact: IContact = expense.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case ExpenseCategorySlugs.HOSTEL_VOUCHER: {
			const contact: IContact = expense.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
	}
	return particulars
}
