import { IItem } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	categoryId: string
	update: {
		name?: string
		rate?: number
		categoryId?: string
	}
}

export async function itemUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/item/update/v1"
	const requester = new ApiRequester<RequestDataShape, IItem>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
