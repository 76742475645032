import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { ICommitteeMember } from "src/domain/entities"
import { CommitteeMemberListView } from "."

interface Props {
	list: ICommitteeMember[]
	isLoading: boolean
	onUpdate: (committeeMember: ICommitteeMember) => void
	onDelete: (committeeMember: ICommitteeMember) => void
	onAddClick: () => void
}

export const CommitteeMemberListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No committeeMembers found."
				onAddClick={onAddClick}
			/>
		)
	}

	return <CommitteeMemberListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
