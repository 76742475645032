import { FC } from "react"
import { IHallBookingReceipt } from "src/domain/entities"
import { HallBookingReceiptCategorySlugs } from "src/utils/enums"
import {
	PartyPlotBhadaDepositReceiptPrintView,
	VaadiBhadaDepositReceiptPrintView,
} from "../../../ReceiptPrintsPage/ReceiptPrintsPage"

export const HallBookingReceiptPrintView: FC<{
	hallBookingReceipt: IHallBookingReceipt
}> = ({ hallBookingReceipt }) => {
	switch (hallBookingReceipt.category?.slug) {
		case HallBookingReceiptCategorySlugs.VAADI_BHADA_DEPOSIT_RECEIPT:
			return (
				<VaadiBhadaDepositReceiptPrintView
					hallBookingReceipt={hallBookingReceipt}
				/>
			)
		case HallBookingReceiptCategorySlugs.PARTY_PLOT_BHADA_DEPOSIT_RECEIPT:
			return (
				<PartyPlotBhadaDepositReceiptPrintView
					hallBookingReceipt={hallBookingReceipt}
				/>
			)
	}

	return <>Unsupported hallReceipt category</>
}
