import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { useExpenseCategoryListService, useExpenseListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { IExpense } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ExpenseAddDrawerFormController } from "./ExpenseAddDrawerForm"
import { ExpenseDeleteDialogController } from "./ExpenseDeleteDialogController"
import { ExpenseListController } from "./ExpenseList"
import { ExpenseUpdateDrawerFormController } from "./ExpenseUpdateDrawerForm"

export const ExpensesPage: FC = () => {
	const { expenseList, isLoading, fetchExpenseList } = useExpenseListService()
	const { expenseCategoryList, fetchExpenseCategoryList } =
		useExpenseCategoryListService()
	const [isExpenseDeleteDialogOpen, setIsExpenseDeleteDialogOpen] = useState(false)
	const expenseAddDrawerDisclosure = useDisclosure()
	const expenseUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [selectedExpenseCategory, setSelectedExpenseCategory] =
		useState<SelectOption | null>({
			label: "All",
			value: null,
		})
	const expenseCategoryOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...expenseCategoryList.map((expenseCategory) => ({
				label: expenseCategory.name,
				value: expenseCategory.id,
			})),
		]
	}, [expenseCategoryList])

	const fetchExpenses = useCallback(() => {
		fetchExpenseList({
			fetch: { category: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
			categoryId: selectedExpenseCategory?.value ?? undefined,
		})
	}, [fetchExpenseList, pagination.currentPage, searchText, selectedExpenseCategory])

	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	useEffect(() => {
		fetchExpenses()
		fetchExpenseCategoryList()
	}, [fetchExpenses, fetchExpenseCategoryList])

	const updateExpenseRef = useRef<IExpense>()
	const deleteExpenseRef = useRef<IExpense>()

	return (
		<DashboardWrapper>
			<Box padding={2} pb={0}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Expenses
					</Text>
					<Button
						size="sm"
						onClick={expenseAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Expense
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
					</Box>
					<Box minW={"230px"}>
						<InputLabel label="ExpenseCategory" />
						<ReactSelect
							onChange={(newValue) => {
								setSelectedExpenseCategory(
									expenseCategoryOptions.find(
										(el) => el.value === newValue?.value,
									) ?? expenseCategoryOptions[0],
								)
							}}
							value={selectedExpenseCategory}
							options={expenseCategoryOptions}
							isSearchable={false}
						/>
					</Box>
				</Flex>
				{gujaratiSuggestions.length ? (
					<Box mt={1}>
						{gujaratiSuggestions.map((el) => (
							<Tag
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				) : null}
			</Box>
			<ExpenseListController
				list={expenseList}
				isLoading={isLoading}
				onUpdate={(expense: IExpense) => {
					updateExpenseRef.current = expense
					expenseUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(expense: IExpense) => {
					deleteExpenseRef.current = expense
					setIsExpenseDeleteDialogOpen(true)
				}}
				onAddClick={expenseAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={expenseList.length === 0}
			/>
			{expenseAddDrawerDisclosure.isOpen ? (
				<ExpenseAddDrawerFormController
					{...expenseAddDrawerDisclosure}
					onSuccess={() => fetchExpenses()}
				/>
			) : null}
			{updateExpenseRef.current && expenseUpdateDrawerDisclosure.isOpen && (
				<ExpenseUpdateDrawerFormController
					{...expenseUpdateDrawerDisclosure}
					expense={updateExpenseRef.current}
					onSuccess={() => fetchExpenses()}
				/>
			)}
			{deleteExpenseRef.current && (
				<ExpenseDeleteDialogController
					isOpen={isExpenseDeleteDialogOpen}
					setIsOpen={setIsExpenseDeleteDialogOpen}
					expense={deleteExpenseRef.current}
					onSuccess={() => fetchExpenses()}
				/>
			)}
		</DashboardWrapper>
	)
}
