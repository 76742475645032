import axios from "axios"
import _ from "lodash"
import moment from "moment"

export function toINR(amount: number, { dashIfZero = false, gujarati = false } = {}) {
	if (dashIfZero && amount === 0) return "-"
	const formatted = new Intl.NumberFormat("gu-IN", {
		style: "currency",
		currency: "INR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount)

	if (gujarati) {
		let formattedGujarati = formatted.split("").map(getGujaratiCharacter)
		return formattedGujarati
	}

	return formatted
}

export function toKGs(quantity: number, { dashIfZero = false } = {}) {
	if (dashIfZero && quantity === 0) return "-"
	const formatted = new Intl.NumberFormat("en-IN", {
		style: "decimal",
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	}).format(quantity)
	return formatted
}

export function fullMonthAndYear({ month, year }: { year: number; month: number }) {
	return monthName(month) + " " + year
}

export function monthName(month: number) {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]
	return months[month - 1]
}

export function disclosureDefaultValue() {
	return {
		isOpen: false,
		onOpen: () => {},
		onClose: () => {},
		onToggle: () => {},
		getButtonProps: () => {},
		getDisclosureProps: () => {},
		isControlled: false,
	}
}

export function getErrorMessage(err: unknown) {
	if (err instanceof Error) return err.message
	if (typeof err === "string") return err
	return "Unknown Error"
}

export function formatDate(date: Date | number | string, { gujarati = false } = {}) {
	const formattedDate = moment(date).format("DD-MM-YYYY")

	if (gujarati) {
		let formattedGujarati = formattedDate.split("").map(getGujaratiCharacter)
		return formattedGujarati
	}

	return formattedDate
}

export function getFullName(val?: {
	firstName: string
	middleName?: string
	lastName?: string
}) {
	let name = val?.firstName || ""

	if (val?.middleName) name += " " + val.middleName
	if (val?.lastName) name += " " + val.lastName

	return name
}

export async function fetchGujaratiSuggestions(text: string): Promise<string[]> {
	if (text.trim().length === 0) return []
	var response = await axios.get(
		`https://inputtools.google.com/request?text=${text}&itc=gu-t-i0-und&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8`,
	)
	if (_.isArray(response.data[1][0][1])) {
		return response.data[1][0][1]
	}
	return []
}

export function getGujaratiCharacter(char?: string) {
	switch (char) {
		case "1":
			return "૧"
		case "2":
			return "૨"
		case "3":
			return "૩"
		case "4":
			return "૪"
		case "5":
			return "૫"
		case "6":
			return "૬"
		case "7":
			return "૭"
		case "8":
			return "૮"
		case "9":
			return "૯"
		case "0":
			return "૦"
		default:
			return char
	}
}

export const getGujaratiString = (str?: string) => {
	if (!str) return ""
	return str.split("").map(getGujaratiCharacter).join("")
}

export function gujaratiToDecimal(n: string): number {
	return n.split("").reduce((sum, chr, index, array) => {
		var num = chr.charCodeAt(0) - 2790 //Subtract 2790 to get the decimal value for the current char
		num *= Math.pow(10, array.length - index - 1) //Multiply it with a power of ten, based on its position
		return sum + num //Sum it up
	}, 0)
}

export function decimalToGujarati(n: number): string {
	var str = ""
	var decimal = n
	while (decimal > 0) {
		var digit = decimal % 10
		str = String.fromCharCode(digit + 2790) + str
		decimal = Math.floor(decimal / 10)
	}

	return str
}

export function toINRWords(num?: number): string {
	if (!num) return ""
	const dictionary: { [key: number]: string } = {
		0: "શૂન્ય",
		1: "એક",
		2: "બે",
		3: "ત્રણ",
		4: "ચાર",
		5: "પાંચ",
		6: "છ",
		7: "સાત",
		8: "આઠ",
		9: "નવ",
		10: "દસ",
		11: "અગિયાર",
		12: "બાર",
		13: "તેર",
		14: "ચૌદ",
		15: "પંદર",
		16: "સોળ",
		17: "સત્તર",
		18: "અઢાર",
		19: "ઓગણીસ",
		20: "વીસ",
		21: "એકવીસ",
		22: "બાવીસ",
		23: "તેવીસ",
		24: "ચોવીસ",
		25: "પચ્ચીસ",
		26: "છવીસ",
		27: "સત્તાવીસ",
		28: "અઠ્ઠાવીસ",
		29: "ઓગણત્રીસ",
		30: "ત્રીસ",
		31: "એકત્રીસ",
		32: "બત્રીસ",
		33: "તેત્રીસ",
		34: "ચોત્રીસ",
		35: "પાંત્રીસ",
		36: "છત્રીસ",
		37: "સડત્રીસ",
		38: "અડત્રીસ",
		39: "ઓગણચાલીસ",
		40: "ચાલીસ",
		41: "એકતાલીસ",
		42: "બેતાલીસ",
		43: "ત્રેતાલીસ",
		44: "ચુંમાલીસ",
		45: "પિસ્તાલીસ",
		46: "છેતાલીસ",
		47: "સુડતાલીસ",
		48: "અડતાલીસ",
		49: "ઓગણપચાસ",
		50: "પચાસ",
		51: "એકાવન",
		52: "બાવન",
		53: "ત્રેપન",
		54: "ત્રેપન",
		55: "પંચાવન",
		56: "છપ્પન",
		57: "સત્તાવન",
		58: "અઠ્ઠાવન",
		59: "ઓગણસાઠ",
		60: "સાઠ",
		61: "એકસઠ",
		62: "બાસઠ",
		63: "ત્રેસઠ",
		64: "ચોસઠ",
		65: "પાંસઠ",
		66: "છાસઠ",
		67: "સડસઠ",
		68: "અડસઠ",
		69: "અગણોસિત્તેર",
		70: "સિત્તેર",
		71: "એકોતેર",
		72: "બોતેર",
		73: "તોતેર",
		74: "ચુમોતેર",
		75: "પંચોતેર",
		76: "છોતેર",
		77: "સિત્યોતેર",
		78: "ઇઠ્યોતેર",
		79: "ઓગણાએંસી",
		80: "એંસી",
		81: "એક્યાસી",
		82: "બ્યાસી",
		83: "ત્યાસી",
		84: "ચોર્યાસી",
		85: "પંચાસી",
		86: "છ્યાસી",
		87: "સિત્યાસી",
		88: "ઈઠ્યાસી",
		89: "નેવ્યાસી",
		90: "નેવું",
		91: "એકાણું",
		92: "બાણું",
		93: "ત્રાણું",
		94: "ચોરાણું",
		95: "પંચાણું",
		96: "છન્નું",
		97: "સત્તાણું",
		98: "અઠ્ઠાણું",
		99: "નવ્વાણું",
		100: "સો",
		200: "બસો",
		300: "ત્રણસો ",
		400: "ચારસો",
		500: "પાંચસો",
		600: "છસો",
		700: "સાતસો",
		800: "આઠસો",
		900: "નવસો",
		1000: "હજાર",
		100000: "લાખ ",
		10000000: "કરોડ",
		1000000000: "અબજ",
		1000000000000: "ટ્રિલિયન",
		1000000000000000: "ચતુર્ભુજ",
		1000000000000000000: "ક્વિન્ટીનયન",
	}

	let d: string = ""

	if (!isNaN(+dictionary[num])) {
		if (+num.toString().substring(0, 1) === 0) {
			d = ""
		} else if (num.toString().length === 1 || num.toString().length === 2) {
			d = dictionary[num]
		} else {
			if (+num.toString().substring(0, 1) === 1) {
				d = dictionary[+(num.toString().substring(0, 1) + " " + dictionary[num])]
			} else {
				d = dictionary[num]
			}
			return d
		}
	} else {
		if (num.toString().length === 1) {
			if (num === 0) {
				d = ""
			} else {
				d = dictionary[num]
			}
			return d
		} else if (num.toString().length === 2) {
			if (Math.floor(num / 10) === 0) {
				d = ""
				const d1 = num % 10
				return d + " " + toINRWords(d1)
			} else {
				d = dictionary[num]
				return d
			}
		} else if (num.toString().length === 3) {
			if (Math.floor(num / 100) === 0) {
				d = ""
			} else {
				if (Math.floor(num / 100) === 1) {
					const d1 = Math.floor(num / 100) + "00"
					d = dictionary[Math.floor(num / 100)] + "  " + dictionary[+d1]
				} else {
					d = dictionary[+(Math.floor(num / 100) + "00")]
				}
			}
			return d + " " + toINRWords(num % 100)
		} else if (num.toString().length === 4) {
			if (Math.floor(num / 1000) === 0) {
				d = ""
			} else {
				d = dictionary[Math.floor(num / 1000)] + " " + dictionary[1000]
			}
			return d + " " + toINRWords(num % 1000)
		} else if (num.toString().length === 5) {
			let d1
			if (Math.floor(num / 1000) === 0) {
				d = ""
				d1 = num % 10000
			} else {
				d = dictionary[Math.floor(num / 1000)] + " " + dictionary[1000]
				d1 = +num.toString().slice(2)
			}
			return d + " " + toINRWords(d1)
		} else if (num.toString().length === 6) {
			if (Math.floor(num / 100000) === 0) {
				d = ""
			} else {
				d = dictionary[Math.floor(num / 100000)] + " " + dictionary[100000]
			}
			return d + " " + toINRWords(num % 100000)
		} else if (num.toString().length === 7) {
			let d1
			if (Math.floor(num / 1000000) === 0) {
				d = ""
				d1 = num % 1000000
			} else {
				d = dictionary[Math.floor(num / 100000)] + " " + dictionary[100000]
				d1 = +num.toString().slice(2)
			}
			return d + " " + toINRWords(d1)
		} else if (num.toString().length === 8) {
			let d1
			if (Math.floor(num / 10000000) === 0) {
				d = ""
				d1 = num % 10000000
			} else {
				d = dictionary[Math.floor(num / 10000000)] + " " + dictionary[10000000]
				d1 = +num.toString().slice(1)
			}
			return d + " " + toINRWords(d1)
		} else if (num.toString().length === 9) {
			let d1
			if (Math.floor(num / 100000000) === 0) {
				d = ""
				d1 = num % 100000000
			} else {
				d = dictionary[Math.floor(num / 10000000)] + " " + dictionary[10000000]
				d1 = +num.toString().slice(2)
			}
			return d + " " + toINRWords(d1)
		}
	}
	return d
}
