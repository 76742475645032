import { useCallback, useState } from "react"
import { IShop } from "src/domain/entities"
import { shopListService } from "src/domain/services/shop/shopListService"
import { useAuth } from ".."

export function useShopListService(): {
	shopList: IShop[]
	isLoading: boolean
	fetchShopList: (filter?: Parameters<typeof shopListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [shopList, setShopList] = useState<IShop[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchShopList = useCallback(
		async (filter: Parameters<typeof shopListService>[0] = {}) => {
			setIsLoading(true)
			const data = await shopListService(filter, token)
			setShopList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { shopList, isLoading, fetchShopList }
}
