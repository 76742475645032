import { IIncome } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		incomeNo?: number
		date?: number
		categoryId?: string
		fields?: {
			fieldId: string
			value: any
		}[]
	}
}

export async function incomeUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/income/update/v1"
	const requester = new ApiRequester<RequestDataShape, IIncome>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
