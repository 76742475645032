import { AddIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	FormControl,
	IconButton,
	Input,
	Stack,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, useState } from "react"
import { BsTrash2Fill } from "react-icons/bs"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { FieldType } from "src/utils/enums"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IExpenseCategoryAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IExpenseCategoryAddFormFields>
	fields: { name: string; type: string, position: number }[]
	dispatchFields: Dispatch<{
		type: string
		index: number
		payload: { name: string; type: FieldType, position: number }
	}>
}

export const ExpenseCategoryAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	fields,
	dispatchFields,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IExpenseCategoryAddFormFields>
			initialValues={{ name: "", note: "", slug: "", fields: fields }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="md"
						headerLabel="Add Expense Category"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack marginX={"auto"}>
							<Flex gridColumnGap={2}>
								{/* Name */}
								<FormControl>
									<InputLabel label="Name" />
									<Input
										name="name"
										placeholder="Name"
										maxLength={50}
										required
										autoFocus
										value={values.name}
										onChange={handleNameChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name"
									/>
								</FormControl>
								{/* Slug */}
								<FormControl>
									<InputLabel label="Slug" />
									<Input
										name="slug"
										placeholder="Slug"
										maxLength={50}
										required
										value={values.slug}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="slug"
									/>
								</FormControl>
							</Flex>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
							<Box>
								<Text fontWeight="bold" fontSize={16}>
									Fields
								</Text>

								<Table
									backgroundColor={"gray.100"}
									borderRadius={8}
									marginTop={2}
								>
									<Thead>
										<Tr>
											<Th
												pr={0}
												pl={2}
												width={"5%"}
												style={{
													textTransform: "none",
													whiteSpace: "nowrap",
												}}
											>
												Sr No.
											</Th>
											<Th width={"40%"}>Name</Th>
											<Th width={"40%"} pl={0} pr={0}>
												Type
											</Th>
											<Th pr={2} pl={0} isNumeric>
												Action
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{fields.map((field, index) => (
											<Tr key={index}>
												<Td pr={0}>{index + 1}</Td>
												<Td pl={0} pr={2}>
													<Input
														backgroundColor={"white"}
														borderColor={"gray.300"}
														value={field.name}
														name={`fields[${index}].name`}
														onChange={(e) => {
															setFieldValue(
																`fields[${index}].name`,
																e.target.value,
															)

															dispatchFields({
																type: "update",
																index,
																payload: {
																	name:
																		e.target.value,
																	type:
																		field.type as FieldType,
																	position: field.position,
																},
															})
														}}
													/>
												</Td>
												<Td pl={0} pr={0}>
													<ReactSelect
														options={Object.entries(
															FieldType,
														).map(([_, value]) => ({
															label: value,
															value,
														}))}
														value={{
															label: field.type,
															value: field.type,
														}}
														name={`fields[${index}].type`}
														onChange={(e) => {
															setFieldValue(
																`fields[${index}].type`,
																(e as SelectOption).value,
															)

															dispatchFields({
																type: "update",
																index,
																payload: {
																	type: (
																		e as SelectOption
																	).value as FieldType,
																	name:
																		field.name,
																		position: field.position,
																},
															})
														}}
													/>
												</Td>
												<Td pr={2}>
													<IconButton
														aria-label="delete"
														variant="ghost"
														icon={<BsTrash2Fill />}
														size={"sm"}
														mx="1"
														colorScheme={"red"}
														_hover={{
															backgroundColor: "red.100",
														}}
														onClick={() => {
															dispatchFields({
																type: "remove",
																index,
																payload: {
																	name:
																		field.name,
																	type:
																		field.type as FieldType,
																		position: field.position,
																},
															})
														}}
													/>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
								<Button
									leftIcon={<AddIcon fontSize={10} />}
									backgroundColor={"gray.200"}
									_hover={{ backgroundColor: "gray.300" }}
									size={"sm"}
									marginTop={2}
									onClick={() => {
										dispatchFields({
											type: "add",
											index: fields.length,
											payload: {
												name: "",
												type: FieldType.STRING,
												position: fields.length+1,
											},
										})
									}}
								>
									Add
								</Button>
							</Box>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
