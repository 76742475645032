import { IField } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update?: {
		name?: string
		type?: string
		position?: number
		categoryId?: string
		categoryType?: "expense" | "income"
	}
}

export async function fieldUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/field/update/v1"
	const requester = new ApiRequester<RequestDataShape, IField>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
