import { IItem } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	categoryId?: string
	categorySlug?: string
	fetch?: {
		category: boolean
	}
}

export async function itemListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/item/list/v1"
	const requester = new ApiRequester<RequestDataShape, IItem[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
