import { ComponentProps, FC, useCallback, useEffect, useReducer } from "react"
import { IReceipt } from "src/domain/entities"
import { receiptUpdateService } from "src/domain/services/receipt/receiptUpdateService"
import { useAuth, useItemListService, useStudentListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IReceiptUpdateFormFields, ReceiptUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ReceiptUpdateDrawerFormView>,
	"handleSubmit" | "studentList" | "itemList" | "receiptItems" | "dispatchReceiptItems"
> & {
	onSuccess?: (receipt: IReceipt) => void
}

export const ReceiptUpdateDrawerFormController: FC<Props> = ({
	receipt,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { studentList, fetchStudentList } = useStudentListService()
	const { itemList, fetchItemList } = useItemListService()

	const reducer = (
		state: { itemId: string; amount: number }[],
		action: {
			type: string
			index: number
			payload: { itemId: string; amount: number }
		},
	) => {
		switch (action.type) {
			case "add":
				return [...state, action.payload]
			case "update":
				return state.map((item, i) => {
					if (i === action.index) return action.payload
					return item
				})
			case "remove":
				if (state.length === 1) return state
				return state.filter((_, i) => i !== action.index)
			default:
				return state
		}
	}

	const initialReceiptItems =
		receipt.receiptItems?.map((item) => ({
			itemId: item.item.id,
			amount: item.amount,
		})) ?? []

	const [receiptItems, dispatchReceiptItems] = useReducer(reducer, initialReceiptItems)

	const handleSubmit = useCallback<FormikOnSubmit<IReceiptUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedReceipt = await receiptUpdateService(
					{ id: receipt.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedReceipt)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ receiptNo: message })
			}
		},
		[onSuccess, rest, token, receipt.id],
	)

	useEffect(() => {
		fetchStudentList()
		fetchItemList()
	}, [fetchStudentList, fetchItemList])

	return (
		<ReceiptUpdateDrawerFormView
			receipt={receipt}
			handleSubmit={handleSubmit}
			studentList={studentList}
			itemList={itemList}
			receiptItems={receiptItems}
			dispatchReceiptItems={dispatchReceiptItems}
			{...rest}
		/>
	)
}
