import { useCallback, useState } from "react"
import { ILocation } from "src/domain/entities"
import { locationListService } from "src/domain/services/location/locationListService"
import { useAuth } from ".."

export function useLocationListService(): {
	locationList: ILocation[]
	isLoading: boolean
	fetchLocationList: (
		filter?: Parameters<typeof locationListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [locationList, setLocationList] = useState<ILocation[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchLocationList = useCallback(
		async (filter: Parameters<typeof locationListService>[0] = {}) => {
			setIsLoading(true)
			const data = await locationListService(filter, token)
			setLocationList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { locationList, isLoading, fetchLocationList }
}
