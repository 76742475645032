import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IStudent } from "src/domain/entities"
import { studentUpdateService } from "src/domain/services/student/studentUpdateService"
import { useAuth, useStandardListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IStudentUpdateFormFields, StudentUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof StudentUpdateDrawerFormView>,
	"handleSubmit" | "standardList"
> & {
	onSuccess?: (student: IStudent) => void
}

export const StudentUpdateDrawerFormController: FC<Props> = ({
	student,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { standardList, fetchStandardList } = useStandardListService()

	const handleSubmit = useCallback<FormikOnSubmit<IStudentUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedStudent = await studentUpdateService(
					{ id: student.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedStudent)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ firstName: message })
			}
		},
		[onSuccess, rest, token, student.id],
	)

	useEffect(() => {
		fetchStandardList()
	}, [fetchStandardList])

	return (
		<StudentUpdateDrawerFormView
			student={student}
			handleSubmit={handleSubmit}
			standardList={standardList}
			{...rest}
		/>
	)
}
