import { ITenure } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function tenureListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/tenure/list/v1"
	const requester = new ApiRequester<RequestDataShape, ITenure[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
