import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IShop } from "src/domain/entities"
import { shopUpdateService } from "src/domain/services/shop/shopUpdateService"
import { useAuth, useContactListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IShopUpdateFormFields, ShopUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ShopUpdateDrawerFormView>,
	"handleSubmit" | "contactList"
> & {
	onSuccess?: (shop: IShop) => void
}

export const ShopUpdateDrawerFormController: FC<Props> = ({
	shop,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { contactList, fetchContactList } = useContactListService()

	const handleSubmit = useCallback<FormikOnSubmit<IShopUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedShop = await shopUpdateService(
					{ id: shop.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedShop)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token, shop.id],
	)

	useEffect(() => {
		fetchContactList({ fetch: { category: true } })
	}, [fetchContactList])

	return (
		<ShopUpdateDrawerFormView
			shop={shop}
			handleSubmit={handleSubmit}
			contactList={contactList}
			{...rest}
		/>
	)
}
