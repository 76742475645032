import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import { AiFillHome } from "react-icons/ai"
import {
	BsBank,
	BsBox,
	BsBoxArrowLeft,
	BsBriefcase,
	BsCalendarWeek,
	BsCash,
	BsCashCoin,
	BsCurrencyDollar,
	BsPeople,
	BsPeopleFill,
	BsPersonFill,
	BsPinMap,
	BsReceipt,
	BsShopWindow,
	BsWalletFill,
} from "react-icons/bs"
import { Header } from "../shared/Header"
import { NavItem, SideBar } from "../shared/SideBar"

const items: NavItem[] = [
	{ name: "Dashboard", link: "/", icon: AiFillHome },
	{
		name: "Education",
		link: "/standards",
		icon: BsBriefcase,
		subItems: [
			{ name: "Standards", link: "/standards", icon: BsBriefcase },
			{ name: "Students", link: "/students", icon: BsPeople },
			{ name: "Fee Receipts", link: "/receipts", icon: BsReceipt },
			{ name: "Fee Payments", link: "/payments", icon: BsCashCoin },
		],
	},
	{ name: "Items", link: "/items", icon: BsBox },
	...(process.env.NODE_ENV === "development"
		? [{ name: "Item Categories", link: "/itemCategories", icon: BsPeopleFill }]
		: []),
	{ name: "Locations", link: "/locations", icon: BsPinMap },
	{ name: "Accounts", link: "/accounts", icon: BsBank },
	{ name: "Contact Categories", link: "/contactCategories", icon: BsPeopleFill },
	{ name: "Contacts", link: "/contacts", icon: BsPersonFill },
	{ name: "Shops", link: "/shops", icon: BsShopWindow },
	{
		name: "Hall Booking",
		link: "/hallBooking",
		icon: BsBriefcase,
		subItems: [
			{ name: "Receipts", link: "/hallBookingReceipts", icon: BsReceipt },
			{ name: "Bills", link: "/hallBookingBills", icon: BsCurrencyDollar },
			{ name: "Vouchers", link: "/hallBookingVouchers", icon: BsCashCoin },
			{ name: "Calender", link: "/hallBookingCalender", icon: BsCalendarWeek },
		],
	},
	...(process.env.NODE_ENV === "development"
		? [{ name: "Expense Categories", link: "/expenseCategories", icon: BsBox }]
		: []),
	{ name: "Expenses", link: "/expenses", icon: BsWalletFill },
	...(process.env.NODE_ENV === "development"
		? [{ name: "Income Categories", link: "/incomeCategories", icon: BsBox }]
		: []),

	{ name: "Incomes", link: "/incomes", icon: BsCash },
	{ name: "Tenures", link: "/tenures", icon: BsBriefcase },
	{ name: "Committee Members", link: "/committeeMembers", icon: BsPeople },
	{ name: "Logout", link: "/logout", icon: BsBoxArrowLeft },
]

export const DashboardWrapper: FC = ({ children }) => {
	return (
		<Flex height="100vh" overflowY="hidden">
			<Header />
			<SideBar items={items} />
			<Box width="100%" overflowY="auto" px={6} py={2}>
				{children}
			</Box>
		</Flex>
	)
}
