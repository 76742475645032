import { ICommitteeMember } from "src/domain/entities"
import { committeeMemberListApi } from "../../api/committeeMember/committeeMemberListApi"

type PayloadShape = Parameters<typeof committeeMemberListApi>[0]

export async function committeeMemberListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const committeeMember = await committeeMemberListApi(payload, token)
	return committeeMember as NonNullable<ICommitteeMember[]>
}
