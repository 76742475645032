import { ComponentProps, FC, useCallback } from "react"
import { IStandard } from "src/domain/entities"
import { standardUpdateService } from "src/domain/services/standard/standardUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IStandardUpdateFormFields, StandardUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof StandardUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (standard: IStandard) => void
}

export const StandardUpdateDrawerFormController: FC<Props> = ({
	standard,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IStandardUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedStandard = await standardUpdateService(
					{ id: standard.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedStandard)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, standard.id],
	)

	return (
		<StandardUpdateDrawerFormView
			standard={standard}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
