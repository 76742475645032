import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IStandard, IStudent } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IStudentUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	student: IStudent
	standardList: IStandard[]
	handleSubmit: FormikOnSubmit<IStudentUpdateFormFields>
}

export const StudentUpdateDrawerFormView: FC<Props> = ({
	student,
	handleSubmit,
	standardList,
	...rest
}) => (
	<Formik<IStudentUpdateFormFields>
		initialValues={{ ...student, standardId: student.standard?.id }}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const standardOptions = standardList.map((standard) => ({
				label: standard.name,
				value: standard.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Student"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* Student No. */}
							<FormControl>
								<InputLabel label="Student No." />
								<Input
									name="studentNo"
									maxLength={50}
									value={values.studentNo}
									required
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="studentNo"
								/>
							</FormControl>
							{/* Standard */}
							<FormControl>
								<InputLabel label="Standard" />
								<ReactSelect
									name="standardId"
									value={standardOptions.find(
										(el) => el.value === values.standardId,
									)}
									onChange={(newValue) => {
										setFieldValue(
											"standardId",
											(newValue as SelectOption).value,
										)
									}}
									options={standardOptions}
									isSearchable
								/>
							</FormControl>
						</Flex>
						<Flex gridColumnGap={2}>
							{/* First Name */}
							<FormControl flex={1}>
								<InputLabel label="First Name" />
								<Input
									name="firstName"
									maxLength={50}
									value={values.firstName}
									required
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="firstName"
								/>
							</FormControl>
							{/* Middle Name */}
							<FormControl flex={1}>
								<InputLabel label="Middle Name" />
								<Input
									name="middleName"
									maxLength={50}
									value={values.middleName}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="middleName"
								/>
							</FormControl>
							{/* Last Name */}
							<FormControl flex={1}>
								<InputLabel label="Last Name" />
								<Input
									name="lastName"
									maxLength={50}
									value={values.lastName}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="lastName"
								/>
							</FormControl>
						</Flex>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
