import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IPayment, IReceipt } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IPaymentUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	payment: IPayment
	receiptList: IReceipt[]
	handleSubmit: FormikOnSubmit<IPaymentUpdateFormFields>
}

export const PaymentUpdateDrawerFormView: FC<Props> = ({
	payment,
	handleSubmit,
	receiptList,
	...rest
}) => (
	<Formik<IPaymentUpdateFormFields>
		initialValues={{ ...payment, receiptId: payment.receipt?.id }}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const receiptOptions = receiptList.map((receipt) => ({
				label: `${receipt.receiptNo} - ${getFullName(receipt.student)}`,
				value: receipt.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Payment"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* Date */}
							<FormControl flex={1}>
								<InputLabel label="Date" />
								<Input
									type="date"
									name="paymentDate"
									maxLength={50}
									value={dayjs(values.paymentDate).format("YYYY-MM-DD")}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentDate"
								/>
							</FormControl>
							{/* Receipt */}
							<FormControl flex={2}>
								<InputLabel label="Receipt" />
								<ReactSelect
									name="receiptId"
									onChange={(newValue) => {
										setFieldValue(
											"receiptId",
											(newValue as SelectOption).value,
										)
									}}
									value={receiptOptions.find(
										(el) => el.value === values.receiptId,
									)}
									options={receiptOptions}
									isSearchable
								/>
							</FormControl>
						</Flex>
						<Flex gridColumnGap={2}>
							{/* Amount */}
							<FormControl flex={1}>
								<InputLabel label="Amount" />
								<Input
									name="paymentAmount"
									maxLength={50}
									type="number"
									value={values.paymentAmount}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentAmount"
								/>
							</FormControl>
							{/* Mode */}
							<FormControl flex={2}>
								<InputLabel label="Payment Mode" />
								<Input
									name="paymentMode"
									maxLength={50}
									value={values.paymentMode}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="paymentMode"
								/>
							</FormControl>
						</Flex>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
