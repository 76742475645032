import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { IStudent } from "src/domain/entities"
import { studentDeleteService } from "src/domain/services/student/studentDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage, getFullName } from "src/utils/helpers"

interface Props {
	student: IStudent
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const StudentDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	student,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleStudentDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await studentDeleteService({ id: student.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [student, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Student: ${getFullName(student)}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleStudentDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
