import { IStudent } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	standardId: string
	studentNo: number
	firstName: string
	middleName?: string
	lastName?: string
}

export async function studentAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/student/add/v1"
	const requester = new ApiRequester<RequestDataShape, IStudent>(endpoint)
	return await requester.sendRequest(payload, token)
}
