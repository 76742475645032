import { ComponentProps, FC, useCallback } from "react"
import { ILocation } from "src/domain/entities"
import { locationAddService } from "src/domain/services/location/locationAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { ILocationAddFormFields, LocationAddDrawerFormView } from "."

interface Props
	extends Omit<ComponentProps<typeof LocationAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (location: ILocation) => void
}

export const LocationAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ILocationAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const location = await locationAddService(values, token)
				onSuccess(location)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <LocationAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
