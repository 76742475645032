import { IContactCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	note?: string
}

export async function contactCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/contact/category/add/v1"
	const requester = new ApiRequester<RequestDataShape, IContactCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
