import { IItemCategory } from "src/domain/entities"
import { itemCategoryListApi } from "../../api/itemCategory/itemCategoryListApi"

type PayloadShape = Parameters<typeof itemCategoryListApi>[0]

export async function itemCategoryListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const itemCategory = await itemCategoryListApi(payload, token)
	return itemCategory as NonNullable<IItemCategory[]>
}
