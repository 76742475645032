import { useCallback, useState } from "react"
import { ITenure } from "src/domain/entities"
import { tenureListService } from "src/domain/services/tenure/tenureListService"
import { useAuth } from ".."

export function useTenureListService(): {
	tenureList: ITenure[]
	isLoading: boolean
	fetchTenureList: (filter?: Parameters<typeof tenureListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [tenureList, setTenureList] = useState<ITenure[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchTenureList = useCallback(
		async (filter: Parameters<typeof tenureListService>[0] = {}) => {
			setIsLoading(true)
			const data = await tenureListService(filter, token)
			setTenureList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { tenureList, isLoading, fetchTenureList }
}
