import { incomeCategoryListApi } from "src/domain/api/incomeCategory/incomeCategoryListApi"
import { IHallBookingReceiptCategory } from "src/domain/entities"
import { HallBookingReceiptCategorySlugs } from "src/utils/enums"

type PayloadShape = Parameters<typeof incomeCategoryListApi>[0]

export async function hallBookingReceiptCategoryListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const hallReceipt = await incomeCategoryListApi(
		{
			...payload,
			slugs: [
				HallBookingReceiptCategorySlugs.PARTY_PLOT_BHADA_DEPOSIT_RECEIPT,
				HallBookingReceiptCategorySlugs.VAADI_BHADA_DEPOSIT_RECEIPT,
			],
		},
		token,
	)
	return hallReceipt as NonNullable<IHallBookingReceiptCategory[]>
}
