import dayjs from "dayjs"
import { receiptUpdateApi } from "../../api/receipt/receiptUpdateApi"

type PayloadShape = Parameters<typeof receiptUpdateApi>[0]

export async function receiptUpdateService(payload: PayloadShape, token: string) {
	if (payload.update.receiptNo) payload.update.receiptNo = +payload.update.receiptNo

	if (payload.update.receiptItems) {
		payload.update.receiptItems.forEach((item) => {
			item.amount = +item.amount
		})
	}
	if (payload.update.receiptDate) {
		payload.update.receiptDate = dayjs(payload.update.receiptDate).unix() * 1000
	}

	return await receiptUpdateApi(payload, token)
}
