import { IItem } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	rate: number
}

export async function itemAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/item/add/v1"
	const requester = new ApiRequester<RequestDataShape, IItem>(endpoint)
	return await requester.sendRequest(payload, token)
}
