import { IItemCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	note?: string
	slug: string
}

export async function itemCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/item/category/add/v1"
	const requester = new ApiRequester<RequestDataShape, IItemCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
