import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { IStudent } from "src/domain/entities"
import { StudentListView } from "."

interface Props {
	list: IStudent[]
	isLoading: boolean
	onUpdate: (student: IStudent) => void
	onDelete: (student: IStudent) => void
	onAddClick: () => void
}

export const StudentListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No students found." onAddClick={onAddClick} />
	}

	return <StudentListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
