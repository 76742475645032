import { IIncomeCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: string
		slug?: string
		fields?: {
			name: string
			type: string
			position: number
		}[]
		note?: string
	}
}

export async function incomeCategoryUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/income/category/update/v1"
	const requester = new ApiRequester<RequestDataShape, IIncomeCategory>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
