import { IItemCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: string
		note?: string
		slug?: string
	}
}

export async function itemCategoryUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/item/category/update/v1"
	const requester = new ApiRequester<RequestDataShape, IItemCategory>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
