import { useCallback, useState } from "react"
import { ICommitteeMember } from "src/domain/entities"
import { committeeMemberListService } from "src/domain/services/committeeMember/committeeMemberListService"
import { useAuth } from ".."

export function useCommitteeMemberListService(): {
	committeeMemberList: ICommitteeMember[]
	isLoading: boolean
	fetchCommitteeMemberList: (
		filter?: Parameters<typeof committeeMemberListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [committeeMemberList, setCommitteeMemberList] = useState<ICommitteeMember[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchCommitteeMemberList = useCallback(
		async (filter: Parameters<typeof committeeMemberListService>[0] = {}) => {
			setIsLoading(true)
			const data = await committeeMemberListService(filter, token)
			setCommitteeMemberList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { committeeMemberList, isLoading, fetchCommitteeMemberList }
}
