import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { ITenure } from "src/domain/entities"

export const TenureListView: FC<{
	list: ITenure[]
	onUpdate: (tenure: ITenure) => void
	onDelete: (tenure: ITenure) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name En</Th>
				<Th>Name Gu</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((tenure, i) => (
				<Tr key={i}>
					<Td>{tenure.term.en}</Td>
					<Td>{tenure.term.gu}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(tenure)} />
						<DeleteIconButton onClick={() => onDelete(tenure)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
