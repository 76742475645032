import { useCallback, useState } from "react"
import { IExpense } from "src/domain/entities"
import { expenseListService } from "src/domain/services/expense/expenseListService"
import { useAuth } from ".."

export function useExpenseListService(): {
	expenseList: IExpense[]
	isLoading: boolean
	fetchExpenseList: (filter?: Parameters<typeof expenseListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [expenseList, setExpenseList] = useState<IExpense[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchExpenseList = useCallback(
		async (filter: Parameters<typeof expenseListService>[0] = {}) => {
			setIsLoading(true)
			const data = await expenseListService(filter, token)
			setExpenseList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { expenseList, isLoading, fetchExpenseList }
}
