import { useCallback, useState } from "react"
import { IAccount } from "src/domain/entities"
import { accountListService } from "src/domain/services/account/accountListService"
import { useAuth } from ".."

export function useAccountListService(): {
	accountList: IAccount[]
	isLoading: boolean
	fetchAccountList: (filter?: Parameters<typeof accountListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [accountList, setAccountList] = useState<IAccount[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchAccountList = useCallback(
		async (filter: Parameters<typeof accountListService>[0] = {}) => {
			setIsLoading(true)
			const data = await accountListService(filter, token)
			setAccountList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { accountList, isLoading, fetchAccountList }
}
