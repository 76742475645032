import { expenseNumberGetApi } from "../../api/expense/expenseNumberGetApi"

type PayloadShape = Parameters<typeof expenseNumberGetApi>[0]

export async function expenseNumberGetService(
	payload: PayloadShape,
	token?: null | string,
) {
	return await expenseNumberGetApi(payload, token)
}
