import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { HomePage, LoginPage, LogoutPage, NotFoundPage } from "src/components/pages"
import { AuthContextProvider } from "./components/context/auth"
import { AccountsPage } from "./components/pages/AccountsPage"
import { CommitteeMembersPage } from "./components/pages/CommitteeMembersPage"
import { ContactCategoriesPage } from "./components/pages/ContactCategoriesPage"
import { ContactsPage } from "./components/pages/ContactsPage"
import { ExpenseCategoriesPage } from "./components/pages/ExpenseCategoriesPage"
import { ExpensesPage } from "./components/pages/ExpensesPage"
import { HallBookingReceiptsPage } from "./components/pages/HallBookingsPage/HallBookingReceiptPage"
import { IncomeCategoriesPage } from "./components/pages/IncomeCategoriesPage"
import { IncomesPage } from "./components/pages/IncomesPage"
import { ItemCategoriesPage } from "./components/pages/ItemCategoriesPage"
import { ItemsPage } from "./components/pages/ItemsPage"
import { LocationsPage } from "./components/pages/LocationsPage"
import { PaymentsPage } from "./components/pages/PaymentsPage"
import { ReceiptPrintsPage } from "./components/pages/ReceiptPrintsPage"
import { ReceiptsPage } from "./components/pages/ReceiptsPage"
import { ShopsPage } from "./components/pages/ShopsPage"
import { StandardsPage } from "./components/pages/StandardsPage"
import { StudentsPage } from "./components/pages/StudentsPage"
import { TenuresPage } from "./components/pages/TenuresPage"
import { PrivateRoute } from "./components/shared/PrivateRoute"

export const App: React.FC = () => (
	<ChakraProvider>
		<AuthContextProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<LoginPage />} />
					<Route path="/logout" element={<LogoutPage />} />
					<Route path="/receiptPrints" element={<ReceiptPrintsPage />} />
					<Route path="/" element={<PrivateRoute element={<HomePage />} />} />
					<Route
						path="/standards"
						element={<PrivateRoute element={<StandardsPage />} />}
					/>

					<Route
						path="/students"
						element={<PrivateRoute element={<StudentsPage />} />}
					/>

					<Route
						path="/items"
						element={<PrivateRoute element={<ItemsPage />} />}
					/>

					<Route
						path="/receipts"
						element={<PrivateRoute element={<ReceiptsPage />} />}
					/>

					<Route
						path="/payments"
						element={<PrivateRoute element={<PaymentsPage />} />}
					/>

					<Route
						path="/locations"
						element={<PrivateRoute element={<LocationsPage />} />}
					/>

					<Route
						path="/accounts"
						element={<PrivateRoute element={<AccountsPage />} />}
					/>

					<Route
						path="/contactCategories"
						element={<PrivateRoute element={<ContactCategoriesPage />} />}
					/>
					<Route
						path="/itemCategories"
						element={<PrivateRoute element={<ItemCategoriesPage />} />}
					/>

					<Route
						path="/contacts"
						element={<PrivateRoute element={<ContactsPage />} />}
					/>

					<Route
						path="/shops"
						element={<PrivateRoute element={<ShopsPage />} />}
					/>

					<Route
						path="/expenseCategories"
						element={<PrivateRoute element={<ExpenseCategoriesPage />} />}
					/>
					<Route
						path="/hallBookingReceipts"
						element={<PrivateRoute element={<HallBookingReceiptsPage />} />}
					/>

					<Route
						path="/expenses"
						element={<PrivateRoute element={<ExpensesPage />} />}
					/>

					<Route
						path="/incomeCategories"
						element={<PrivateRoute element={<IncomeCategoriesPage />} />}
					/>

					<Route
						path="/incomes"
						element={<PrivateRoute element={<IncomesPage />} />}
					/>

					<Route
						path="/tenures"
						element={<PrivateRoute element={<TenuresPage />} />}
					/>

					<Route
						path="/committeeMembers"
						element={<PrivateRoute element={<CommitteeMembersPage />} />}
					/>

					<Route element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</AuthContextProvider>
	</ChakraProvider>
)
