import dayjs from "dayjs"
import { incomeUpdateApi } from "../../api/income/incomeUpdateApi"

type PayloadShape = Parameters<typeof incomeUpdateApi>[0]

export async function incomeUpdateService(payload: PayloadShape, token: string) {
	if (payload.update.incomeNo) payload.update.incomeNo = +payload.update.incomeNo

	if (payload.update.date) {
		payload.update.date = dayjs(payload.update.date).unix() * 1000
	}

	return await incomeUpdateApi(payload, token)
}
