import { IExpenseCategory } from "src/domain/entities"
import { expenseCategoryListApi } from "../../api/expenseCategory/expenseCategoryListApi"

type PayloadShape = Parameters<typeof expenseCategoryListApi>[0]

export async function expenseCategoryListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const expenseCategory = await expenseCategoryListApi(payload, token)
	return expenseCategory as NonNullable<IExpenseCategory[]>
}
