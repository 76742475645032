import dayjs from "dayjs"
import { paymentAddApi } from "../../api/payment/paymentAddApi"

type PayloadShape = Parameters<typeof paymentAddApi>[0]

export async function paymentAddService(payload: PayloadShape, token: string) {
	payload.paymentAmount = +payload.paymentAmount
	payload.paymentDate = dayjs(payload.paymentDate).unix() * 1000
	return await paymentAddApi(payload, token)
}
