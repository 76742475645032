import { useCallback, useState } from "react"
import { IStandard } from "src/domain/entities"
import { standardListService } from "src/domain/services/standard/standardListService"
import { useAuth } from ".."

export function useStandardListService(): {
	standardList: IStandard[]
	isLoading: boolean
	fetchStandardList: (
		filter?: Parameters<typeof standardListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [standardList, setStandardList] = useState<IStandard[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchStandardList = useCallback(
		async (filter: Parameters<typeof standardListService>[0] = {}) => {
			setIsLoading(true)
			const data = await standardListService(filter, token)
			setStandardList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { standardList, isLoading, fetchStandardList }
}
