import { CommitteeMemberCategory, ICommitteeMember } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	category?: CommitteeMemberCategory
	profileId?: string
	tenureId?: string
	fetch?: {
		tenure?: boolean
		profile?: {
			nativeVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			currentVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			educations?: {
				educationCategory?: boolean
			}
			occupations?: {
				occupationCategory?: boolean
			}
			surname?: {
				subCaste?: boolean
			}
			reviewedBy?: {
				details?: boolean
			}
			createdBy?: {
				details?: boolean
			}
			updatedBy?: {
				details?: boolean
			}
			acquiredBy?: {
				details?: boolean
			}
		}
	}
	pagination?: { page: number; limit: number }
}

export async function committeeMemberListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/committee/member/list/v1"
	const requester = new ApiRequester<RequestDataShape, ICommitteeMember[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
