import { ComponentProps, FC, useCallback, useState } from "react"
import { IExpenseCategory } from "src/domain/entities"
import { expenseCategoryUpdateService } from "src/domain/services/expenseCategory/expenseCategoryUpdateService"
import { fieldAddService } from "src/domain/services/field/fieldAddService"
import { fieldDeleteService } from "src/domain/services/field/fieldDeleteService"
import { fieldUpdateService } from "src/domain/services/field/fieldUpdateService"
import { useAuth } from "src/hooks"
import { FieldType } from "src/utils/enums"
import { FormikOnSubmit } from "src/utils/types"
import { ExpenseCategoryUpdateDrawerFormView, IExpenseCategoryUpdateFormFields } from "."

type Props = Omit<
	ComponentProps<typeof ExpenseCategoryUpdateDrawerFormView>,
	| "handleSubmit"
	| "fields"
	| "setFields"
	| "handleFieldAdd"
	| "handleFieldUpdate"
	| "handleFieldDelete"
> & {
	onSuccess?: (expenseCategory: IExpenseCategory) => void
}

export const ExpenseCategoryUpdateDrawerFormController: FC<Props> = ({
	expenseCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IExpenseCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedExpenseCategory = await expenseCategoryUpdateService(
					{ id: expenseCategory.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedExpenseCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, expenseCategory.id],
	)

	const [fields, setFields] = useState(
		expenseCategory.fields?.sort((a, b) => a.position - b.position) ?? [],
	)

	const handleFieldAdd = useCallback(async () => {
		const field = await fieldAddService(
			{
				type: FieldType.STRING,
				name: "",
				position: fields.length + 1,
				categoryId: expenseCategory.id,
				categoryType: "expense",
			},
			token,
		)

		setFields([...fields, field])
	}, [fields, expenseCategory.id, token])

	const handleFieldUpdate = useCallback(
		async (id: string, update: { name?: string; type?: FieldType }) => {
			await fieldUpdateService({ id, update }, token)
			setFields(
				fields.map((field) =>
					field.id === id ? { ...field, ...update } : field,
				),
			)
		},
		[fields, token],
	)

	const handleFieldDelete = useCallback(
		async (index) => {
			await fieldDeleteService({ id: fields[index].id }, token)

			setFields(fields.filter((_, i) => i !== index))
		},
		[fields, token],
	)

	return (
		<ExpenseCategoryUpdateDrawerFormView
			expenseCategory={expenseCategory}
			fields={fields}
			setFields={setFields}
			handleSubmit={handleSubmit}
			handleFieldAdd={handleFieldAdd}
			handleFieldUpdate={handleFieldUpdate}
			handleFieldDelete={handleFieldDelete}
			{...rest}
		/>
	)
}
