import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { useShopListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { IShop } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ShopAddDrawerFormController } from "./ShopAddDrawerForm"
import { ShopDeleteDialogController } from "./ShopDeleteDialogController"
import { ShopListController } from "./ShopList"
import { ShopUpdateDrawerFormController } from "./ShopUpdateDrawerForm"

export const ShopsPage: FC = () => {
	const { shopList, isLoading, fetchShopList } = useShopListService()
	const [isShopDeleteDialogOpen, setIsShopDeleteDialogOpen] = useState(false)
	const shopAddDrawerDisclosure = useDisclosure()
	const shopUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchShops = useCallback(() => {
		fetchShopList({
			fetch: { currentRentee: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
		})
	}, [fetchShopList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchShops()
	}, [fetchShops])

	const updateShopRef = useRef<IShop>()
	const deleteShopRef = useRef<IShop>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Shops
					</Text>
					<Button
						size="sm"
						onClick={shopAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Shop
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				</Flex>
			</Box>
			<ShopListController
				list={shopList}
				isLoading={isLoading}
				onUpdate={(shop: IShop) => {
					updateShopRef.current = shop
					shopUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(shop: IShop) => {
					deleteShopRef.current = shop
					setIsShopDeleteDialogOpen(true)
				}}
				onAddClick={shopAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={shopList.length === 0}
			/>
			{shopAddDrawerDisclosure.isOpen ? (
				<ShopAddDrawerFormController
					{...shopAddDrawerDisclosure}
					onSuccess={() => fetchShops()}
				/>
			) : null}
			{updateShopRef.current && shopUpdateDrawerDisclosure.isOpen && (
				<ShopUpdateDrawerFormController
					{...shopUpdateDrawerDisclosure}
					shop={updateShopRef.current}
					onSuccess={() => fetchShops()}
				/>
			)}
			{deleteShopRef.current && isShopDeleteDialogOpen && (
				<ShopDeleteDialogController
					isOpen={isShopDeleteDialogOpen}
					setIsOpen={setIsShopDeleteDialogOpen}
					shop={deleteShopRef.current}
					onSuccess={() => fetchShops()}
				/>
			)}
		</DashboardWrapper>
	)
}
