import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IAccount } from "src/domain/entities"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IAccountUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	account: IAccount
	handleSubmit: FormikOnSubmit<IAccountUpdateFormFields>
}

export const AccountUpdateDrawerFormView: FC<Props> = ({
	account,
	handleSubmit,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IAccountUpdateFormFields>
			initialValues={{
				name: account.name,
				accountNo: account.accountNo,
				bankName: account.bankName,
				ifscCode: account.ifscCode,
				note: account.note,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Update Account"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Name */}
							<FormControl>
								<InputLabel label="Account Name" />
								<Input
									name="name"
									placeholder="Name"
									maxLength={50}
									required
									autoFocus
									value={values.name}
									onChange={handleNameChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="name" />
							</FormControl>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>

							<Flex gridGap={2}>
								{/* Account No. */}
								<FormControl>
									<InputLabel label="Account No." />
									<Input
										name="accountNo"
										placeholder="1234567890"
										maxLength={50}
										required
										value={values.accountNo}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="accountNo"
									/>
								</FormControl>
								{/* IFSC Code */}
								<FormControl>
									<InputLabel label="IFSC Code" />
									<Input
										name="ifscCode"
										placeholder="AAAA0000001"
										maxLength={11}
										required
										value={values.ifscCode}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="ifscCode"
									/>
								</FormControl>
							</Flex>

							{/* Bank Name */}
							<FormControl>
								<InputLabel label="Bank Name" />
								<Input
									name="bankName"
									placeholder="SBI"
									required
									value={values.bankName}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="bankName"
								/>
							</FormControl>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									value={values.note}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
