import { IPayment } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	paymentMode: string
	paymentAmount: number
	paymentDate: number
	receiptId: string
}

export async function paymentAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/payment/add/v1"
	const requester = new ApiRequester<RequestDataShape, IPayment>(endpoint)
	return await requester.sendRequest(payload, token)
}
