import { ITenure, MultiLangText } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	term: MultiLangText
}

export async function tenureAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/tenure/add/v1"
	const requester = new ApiRequester<RequestDataShape, ITenure>(endpoint)
	return await requester.sendRequest(payload, token)
}
