import { ComponentProps, FC, useCallback } from "react"
import { ITenure } from "src/domain/entities"
import { tenureUpdateService } from "src/domain/services/tenure/tenureUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { ITenureUpdateFormFields, TenureUpdateDrawerFormView } from "."

type Props = Omit<ComponentProps<typeof TenureUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (tenure: ITenure) => void
}

export const TenureUpdateDrawerFormController: FC<Props> = ({
	tenure,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ITenureUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedTenure = await tenureUpdateService(
					{ id: tenure.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedTenure)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ term: message })
			}
		},
		[onSuccess, rest, token, tenure.id],
	)

	return (
		<TenureUpdateDrawerFormView
			tenure={tenure}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
