import { ITenure, MultiLangText } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		term?: MultiLangText
	}
}

export async function tenureUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/tenure/update/v1"
	const requester = new ApiRequester<RequestDataShape, ITenure>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
