import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

export async function locationDeleteApi(payload: RequestDataShape, token: string) {
	const endpoint = "/location/delete/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	await requester.sendRequest(payload, token)
}
