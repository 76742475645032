import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ICommitteeMember } from "src/domain/entities"
import { committeeMemberAddService } from "src/domain/services/committeeMember/committeeMemberAddService"
import { useAuth, useProfileListService, useTenureListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { CommitteeMemberAddDrawerFormView, ICommitteeMemberAddFormFields } from "."

interface Props
	extends Omit<
		ComponentProps<typeof CommitteeMemberAddDrawerFormView>,
		| "handleSubmit"
		| "tenureList"
		| "profileList"
		| "setProfileSearchText"
		| "getGujaratiSuggestions"
	> {
	onSuccess: (committeeMember: ICommitteeMember) => void
}

export const CommitteeMemberAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { tenureList, fetchTenureList } = useTenureListService()
	const { profileList, fetchProfileList } = useProfileListService()

	const handleSubmit = useCallback<FormikOnSubmit<ICommitteeMemberAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const committeeMember = await committeeMemberAddService(values, token)
				onSuccess(committeeMember)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ profileId: message })
			}
		},
		[onSuccess, rest, token],
	)

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchTenureList()
	}, [fetchTenureList])

	const [profileSearchText, setProfileSearchText] = useState("")

	useEffect(() => {
		fetchProfileList({
			search: profileSearchText,
			fetch: { surname: {} },
			pagination: { limit: 15, page: 1 },
		})
	}, [fetchProfileList, profileSearchText])

	return (
		<CommitteeMemberAddDrawerFormView
			tenureList={tenureList}
			profileList={profileList}
			handleSubmit={handleSubmit}
			getGujaratiSuggestions={getGujaratiSuggestions}
			setProfileSearchText={setProfileSearchText}
			{...rest}
		/>
	)
}
