import { FC } from "react"
import { IExpense } from "src/domain/entities"
import { ExpenseCategorySlugs } from "src/utils/enums"
import {
	HostelVoucherPrintView,
	PartyPlotVoucherPrintView,
	SamajVoucherPrintView,
	VaadiVoucherPrintView,
} from "../../ReceiptPrintsPage"

export const ExpensePrintView: FC<{ expense: IExpense }> = ({ expense }) => {
	switch (expense.category?.slug) {
		case ExpenseCategorySlugs.HOSTEL_VOUCHER:
			return <HostelVoucherPrintView expense={expense} />
		case ExpenseCategorySlugs.PARTY_PLOT_VOUCHER:
			return <PartyPlotVoucherPrintView expense={expense} />
		case ExpenseCategorySlugs.VAADI_VOUCHER:
			return <VaadiVoucherPrintView expense={expense} />
		case ExpenseCategorySlugs.SAMAJ_VOUCHER:
			return <SamajVoucherPrintView expense={expense} />
	}

	return <>Unsupported expense category</>
}
