import { Box, Flex, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { IReceipt } from "src/domain/entities"
import { formatDate, getFullName, getGujaratiString, toINR } from "src/utils/helpers"

interface Props {
	receipt?: IReceipt
}

const demoReceipt: IReceipt = {
	createdAt: 1654940295312,
	totalAmount: 1050,
	receiptNo: 12,
	receiptDate: 1655836200000,
	id: "3b0fc84c-14a6-43ba-b47e-b354462a7d67",
	paidAmount: 600,
	updatedAt: 1654949237327,
	student: {
		createdAt: 1654869651145,
		firstName: "Ramji",
		lastName: "GoThaniyaa",
		middleName: "Shyamji",
		studentNo: 12,
		id: "82a91831-4eb4-4888-8292-d62eebc386dd",
		updatedAt: 1654870903551,
		standard: {
			createdAt: 1654664648880,
			name: "SevenTh",
			semester: "2",
			id: "4c6c4d77-d547-4c7c-ba36-5f2ea78b76f1",
			updatedAt: 1654922759212,
		},
	},
	receiptItems: [
		{
			createdAt: 1654944151182,
			amount: 1000,
			id: "c0eb8ace-13e5-4cdd-a064-ab0d5be00d3c",
			updatedAt: 1654944151182,
			item: {
				createdAt: 1654924592208,
				rate: 5000,
				name: "Hostel Fee",
				id: "24fcb294-71c4-437c-9e05-b7f2bf95b853",
				updatedAt: 1654943497742,
			},
		},
		{
			createdAt: 1654944151182,
			amount: 50,
			id: "80a03db4-221b-4b36-b35c-63a80964fe62",
			updatedAt: 1654944151182,
			item: {
				createdAt: 1654943493619,
				rate: 1000,
				name: "Bus fee",
				id: "e06d4659-a2ed-4895-9d02-a4476f3d9bf5",
				updatedAt: 1654943493619,
			},
		},
	],
}

export const ReceiptPrintView: FC<Props> = ({ receipt }) => {
	if (!receipt) receipt = demoReceipt

	if (!receipt) return null

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box>
				<Box height={"40mm"}>
					{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Image src="/lps_logo.jpeg" height={16} />
					<Box textAlign={"center"} flex={1}>
						<Box fontSize={20} lineHeight="1.2">
							શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ - સંચાલિત
						</Box>
						<Box fontSize={20} color={"red.700"} fontWeight="500">
							માતૃશ્રી રતનબેન માધવજી લેઉવા પટેલ કન્યા છાત્રાલય
						</Box>
						<Box fontSize={14} color="gray.700" lineHeight={1}>
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર - ૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
				</Box>
				<Box
					pt={2}
					fontWeight={"bold"}
					fontSize={"lg"}
					textAlign="center"
					textDecoration={"underline"}
				>
					ફી પહોંચ
				</Box>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								પહોંચ નં. :{" "}
							</Text>
							<Text as="span">
								{getGujaratiString(receipt.receiptNo.toString())}
							</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								વિદ્યાર્થી નં. :{" "}
							</Text>
							<Text as="span">
								{getGujaratiString(
									receipt.student?.studentNo.toString() ?? "",
								)}
							</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								વિદ્યાર્થીનું નામ :{" "}
							</Text>
							<Text as="span">{getFullName(receipt.student)}</Text>
						</Box>
					</Box>
					<Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								પહોંચ તારીખ :{" "}
							</Text>
							<Text as="span">
								{formatDate(receipt.receiptDate, { gujarati: true })}
							</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								ધોરણ :{" "}
							</Text>
							<Text as="span">{receipt.student?.standard?.name}</Text>
						</Box>
						{receipt.student?.standard?.semester ? (
							<Box>
								<Text as="span" fontWeight={"bold"}>
									સેમેસ્ટર :{" "}
								</Text>
								<Text as="span">
									{receipt.student?.standard?.semester}
								</Text>
							</Box>
						) : null}
					</Box>
				</Flex>
			</Box>
			{/* Receipt Items */}
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "2px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="10%"
								textAlign={"center"}
							>
								ક્રમ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								textAlign="center"
							>
								ફીની વિગતો
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="20%"
								textAlign="center"
							>
								રકમ
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{receipt.receiptItems?.map((item, index) => (
							<Tr key={item.id}>
								<Td
									style={{ border: "1px solid #555" }}
									padding={0}
									textAlign="center"
								>
									{getGujaratiString((index + 1).toString())}
								</Td>
								<Td
									style={{ border: "1px solid #555" }}
									padding={0}
									pl={2}
								>
									{item.item.name}
								</Td>
								<Td
									style={{ border: "1px solid #555" }}
									padding={0}
									isNumeric
									pr={2}
								>
									{toINR(item.amount, { gujarati: true })}
								</Td>
							</Tr>
						))}
					</Tbody>
					<Tfoot>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ રકમ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
								fontWeight={"bold"}
							>
								{toINR(receipt.totalAmount, { gujarati: true })}
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ ચૂકવેલ ફી
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								{toINR(receipt.paidAmount, { gujarati: true })}
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								બાકી ફી
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
								fontWeight={"bold"}
							>
								{toINR(receipt.totalAmount - receipt.paidAmount, {
									gujarati: true,
								})}
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
			{/* Payments */}
			{receipt.payments?.length ? (
				<Box mt={4}>
					<Text fontWeight={"bold"} mb={1}>
						ચુકવણીની વિગત
					</Text>
					<Table
						style={{
							padding: "0px",
							border: "2px solid black",
						}}
					>
						<Thead>
							<Tr>
								<Th
									padding={1}
									style={{
										border: "1px solid #555",
									}}
									color="black"
									backgroundColor={"gray.100"}
									fontSize={"sm"}
									width="8%"
									textAlign={"center"}
								>
									#
								</Th>
								<Th
									padding={1}
									style={{
										textTransform: "none",
										border: "1px solid #555",
									}}
									color="black"
									backgroundColor={"gray.100"}
									fontSize={"sm"}
									width="20%"
									textAlign="center"
								>
									તારીખ
								</Th>
								<Th
									padding={1}
									style={{
										textTransform: "none",
										border: "1px solid #555",
									}}
									color="black"
									backgroundColor={"gray.100"}
									fontSize={"sm"}
									textAlign="center"
								>
									ચુકવણીનો માધ્યમ
								</Th>
								<Th
									padding={1}
									style={{
										textTransform: "none",
										border: "1px solid #555",
									}}
									color="black"
									backgroundColor={"gray.100"}
									fontSize={"sm"}
									width="20%"
									textAlign="center"
								>
									રકમ
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{receipt.payments?.map((item, index) => (
								<Tr key={item.id}>
									<Td
										style={{ border: "1px solid #555" }}
										padding={0}
										textAlign="center"
									>
										{getGujaratiString((index + 1).toString())}
									</Td>
									<Td
										style={{ border: "1px solid #555" }}
										padding={0}
										textAlign="center"
									>
										{formatDate(item.paymentDate, { gujarati: true })}
									</Td>
									<Td
										style={{ border: "1px solid #555" }}
										padding={0}
										textAlign={"center"}
									>
										{item.paymentMode || "-"}
									</Td>
									<Td
										style={{ border: "1px solid #555" }}
										padding={0}
										isNumeric
										pr={2}
									>
										{toINR(item.paymentAmount, { gujarati: true })}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			) : null}
			{/* Signature */}
			<Flex alignItems="center" mt={6}>
				<Box flex={1}></Box>
				<Box flex={1}></Box>
				<Box flex={1}>
					<Text fontSize="sm" fontWeight="bold">
						નાણાં લેનારની સહી
					</Text>
				</Box>
			</Flex>
		</Box>
	)
}
