import { incomeNumberGetApi } from "../../api/income/incomeNumberGetApi"

type PayloadShape = Parameters<typeof incomeNumberGetApi>[0]

export async function incomeNumberGetService(
	payload: PayloadShape,
	token?: null | string,
) {
	return await incomeNumberGetApi(payload, token)
}
