import { useCallback, useState } from "react"
import { IItem } from "src/domain/entities"
import { itemListService } from "src/domain/services/item/itemListService"
import { useAuth } from ".."

export function useItemListService(): {
	itemList: IItem[]
	isLoading: boolean
	fetchItemList: (filter?: Parameters<typeof itemListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [itemList, setItemList] = useState<IItem[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchItemList = useCallback(
		async (filter: Parameters<typeof itemListService>[0] = {}) => {
			setIsLoading(true)
			const data = await itemListService(filter, token)
			setItemList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { itemList, isLoading, fetchItemList }
}
