import { Box, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IItemCategory } from "src/domain/entities"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IItemAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IItemAddFormFields>
	itemCategoryList: IItemCategory[]
}

export const ItemAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	itemCategoryList,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IItemAddFormFields>
			initialValues={{ name: "", rate: 0 }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}
				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Item"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Item Category */}
							<FormControl>
								<InputLabel label="Item Category" />
								<ReactSelect
									name="categoryId"
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
									}}
									options={itemCategoryList.map((itemCategory) => ({
										label: itemCategory.name,
										value: itemCategory.id,
									}))}
									isSearchable
								/>
							</FormControl>
							{/* Name */}
							<FormControl>
								<InputLabel label="Item Name" />
								<Input
									name="name"
									placeholder="Name"
									maxLength={50}
									value={values.name}
									required
									autoFocus
									onChange={handleNameChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="name" />
							</FormControl>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Rate */}
							<FormControl>
								<InputLabel label="Rate" />
								<Input
									name="rate"
									type="number"
									placeholder="Rate"
									maxLength={50}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="rate" />
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
