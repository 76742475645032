import { IField } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: string
	type: string
	position: number
	categoryId: string
	categoryType: "expense" | "income"
}

export async function fieldAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/field/add/v1"
	const requester = new ApiRequester<RequestDataShape, IField>(endpoint)
	return await requester.sendRequest(payload, token)
}
