import dayjs from "dayjs"
import { incomeAddApi } from "../../api/income/incomeAddApi"

type PayloadShape = Parameters<typeof incomeAddApi>[0]

export async function incomeAddService(payload: PayloadShape, token: string) {
	payload.incomeNo = +payload.incomeNo
	payload.date = dayjs(payload.date).unix() * 1000
	payload.fields = payload.fields
		.map((field) => ({
			fieldId: field.fieldId,
			value: field.value,
		}))
		.filter((field) => field.value !== "")

	return await incomeAddApi(payload, token)
}
