import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { IShop } from "src/domain/entities"
import { shopDeleteService } from "src/domain/services/shop/shopDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"

interface Props {
	shop: IShop
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const ShopDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	shop,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleShopDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await shopDeleteService({ id: shop.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [shop, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Shop: ${shop.blockNo}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleShopDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
