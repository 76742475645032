import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useTenureListService } from "src/hooks/tenure"

import { ITenure } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { TenureAddDrawerFormController } from "./TenureAddDrawerForm"
import { TenureDeleteDialogController } from "./TenureDeleteDialogController"
import { TenureListController } from "./TenureList"
import { TenureUpdateDrawerFormController } from "./TenureUpdateDrawerForm"

export const TenuresPage: FC = () => {
	const { tenureList, isLoading, fetchTenureList } = useTenureListService()
	const [isTenureDeleteDialogOpen, setIsTenureDeleteDialogOpen] = useState(false)
	const tenureAddDrawerDisclosure = useDisclosure()
	const tenureUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchTenureList()
	}, [fetchTenureList])

	const updateTenureRef = useRef<ITenure>()
	const deleteTenureRef = useRef<ITenure>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Tenures
					</Text>
					<Button
						size="sm"
						onClick={tenureAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Tenure
					</Button>
				</Flex>
			</Box>
			<TenureListController
				list={tenureList}
				isLoading={isLoading}
				onUpdate={(tenure: ITenure) => {
					updateTenureRef.current = tenure
					tenureUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(tenure: ITenure) => {
					deleteTenureRef.current = tenure
					setIsTenureDeleteDialogOpen(true)
				}}
				onAddClick={tenureAddDrawerDisclosure.onOpen}
			/>
			{tenureAddDrawerDisclosure.isOpen ? (
				<TenureAddDrawerFormController
					{...tenureAddDrawerDisclosure}
					onSuccess={() => fetchTenureList()}
				/>
			) : null}
			{updateTenureRef.current && tenureUpdateDrawerDisclosure && (
				<TenureUpdateDrawerFormController
					{...tenureUpdateDrawerDisclosure}
					tenure={updateTenureRef.current}
					onSuccess={() => fetchTenureList()}
				/>
			)}
			{deleteTenureRef.current && isTenureDeleteDialogOpen && (
				<TenureDeleteDialogController
					isOpen={isTenureDeleteDialogOpen}
					setIsOpen={setIsTenureDeleteDialogOpen}
					tenure={deleteTenureRef.current}
					onSuccess={() => fetchTenureList()}
				/>
			)}
		</DashboardWrapper>
	)
}
