import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IExpense, IExpenseCategory } from "src/domain/entities"
import { expenseAddService } from "src/domain/services/expense/expenseAddService"
import { expenseNumberGetService } from "src/domain/services/expense/expenseNumberGetService"
import { useAuth, useExpenseCategoryListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ExpenseAddDrawerFormView, IExpenseAddFormFields } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ExpenseAddDrawerFormView>,
		| "handleSubmit"
		| "expenseCategoryList"
		| "expenseNextNumber"
		| "getGujaratiSuggestions"
		| "selectedExpenseCategory"
		| "setSelectedExpenseCategory"
	> {
	onSuccess: (expense: IExpense) => void
}

export const ExpenseAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { expenseCategoryList, fetchExpenseCategoryList } =
		useExpenseCategoryListService()
	const [expenseNextNumber, setExpenseNextNumber] = useState(0)
	const [selectedExpenseCategory, setSelectedExpenseCategory] =
		useState<IExpenseCategory>()

	const handleSubmit = useCallback<FormikOnSubmit<IExpenseAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const expense = await expenseAddService(values, token)
				onSuccess(expense)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ expenseNo: message })
			}
		},
		[onSuccess, rest, token],
	)

	const fetchExpenseNextNumber = useCallback(async () => {
		if (!selectedExpenseCategory) return
		const expenseNextNumber = await expenseNumberGetService(
			{
				categoryId: selectedExpenseCategory.id,
			},
			token,
		)
		setExpenseNextNumber(expenseNextNumber.expenseNo)
	}, [token, selectedExpenseCategory])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchExpenseCategoryList({ fetch: { fields: true } })
		fetchExpenseNextNumber()
	}, [fetchExpenseCategoryList, fetchExpenseNextNumber])

	return (
		<ExpenseAddDrawerFormView
			expenseCategoryList={expenseCategoryList}
			handleSubmit={handleSubmit}
			expenseNextNumber={expenseNextNumber}
			getGujaratiSuggestions={getGujaratiSuggestions}
			selectedExpenseCategory={selectedExpenseCategory}
			setSelectedExpenseCategory={setSelectedExpenseCategory}
			{...rest}
		/>
	)
}
