import { FormControl, Input } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ErrorMessage } from "formik"
import { useEffect } from "react"
import ReactSelect from "react-select"
import { IField } from "src/domain/entities"
import {
	useAccountListService,
	useContactListService,
	useLocationListService,
	useShopListService,
} from "src/hooks"
import { FieldType } from "src/utils/enums"
import { getFullName } from "src/utils/helpers"
import { ErrorMessageField } from "../ui"
import { InputLabel } from "../ui/InputLabel"
import { SelectOption } from "./ReactSelect"

export const FormDynamicField: React.FC<{
	field: IField
	controlName: string
	value?: {
		fieldId: string
		value: any
	}
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	setFieldValue: (name: string, value: any) => void
}> = ({ field, controlName, value, handleChange, setFieldValue }) => {
	let input: JSX.Element = (
		<Input
			name={controlName}
			value={value?.value ?? ""}
			onChange={(e) => {
				handleChange(e)
				setFieldValue(controlName, {
					fieldId: field.id,
					value: e.target.value,
				})
			}}
		/>
	)

	const { contactList, fetchContactList } = useContactListService()
	const { locationList, fetchLocationList } = useLocationListService()
	const { accountList, fetchAccountList } = useAccountListService()
	const { shopList, fetchShopList } = useShopListService()

	useEffect(() => {
		switch (field.type) {
			case FieldType.CONTACT:
				fetchContactList()
				break
			case FieldType.LOCATION:
				fetchLocationList()
				break
			case FieldType.ACCOUNT:
				fetchAccountList()
				break
			case FieldType.SHOP:
				fetchShopList()
				break
		}
	}, [
		fetchContactList,
		fetchLocationList,
		fetchAccountList,
		fetchShopList,
		field.type,
	])

	switch (field.type) {
		case FieldType.NUMBER:
			input = (
				<Input
					name={controlName}
					type="number"
					value={value?.value ?? ""}
					onChange={(e) => {
						handleChange(e)
						setFieldValue(controlName, {
							fieldId: field.id,
							value: +e.target.value,
						})
					}}
				/>
			)
			break
		case FieldType.DATE:
			input = (
				<Input
					name={controlName}
					type="date"
					value={dayjs(value?.value).format("YYYY-MM-DD")}
					onChange={(e) => {
						handleChange(e)
						setFieldValue(controlName, {
							fieldId: field.id,
							value: e.target.value,
						})
					}}
				/>
			)
			break
		case FieldType.CONTACT:
			const contactOptions = contactList.map((contact) => ({
				label: getFullName(contact),
				value: contact.id,
			}))

			if (typeof value?.value === "object") {
				setFieldValue(controlName, {
					fieldId: field.id,
					value: value?.value?.id ?? "",
				})
			}

			input = (
				<ReactSelect
					name={controlName}
					value={contactOptions.find((option) => option.value === value?.value)}
					onChange={(newValue) => {
						setFieldValue(controlName, {
							fieldId: field.id,
							value: (newValue as SelectOption).value,
						})
					}}
					options={contactOptions}
					isSearchable
				/>
			)
			break
		case FieldType.LOCATION:
			const locationOptions = locationList.map((location) => ({
				label: location.name,
				value: location.id,
			}))

			if (typeof value?.value === "object") {
				setFieldValue(controlName, {
					fieldId: field.id,
					value: value?.value?.id ?? "",
				})
			}

			input = (
				<ReactSelect
					name={controlName}
					value={locationOptions.find(
						(option) => option.value === value?.value,
					)}
					onChange={(newValue) => {
						setFieldValue(controlName, {
							fieldId: field.id,
							value: (newValue as SelectOption).value,
						})
					}}
					options={locationOptions}
					isSearchable
				/>
			)
			break
		case FieldType.ACCOUNT:
			const accountOptions = accountList.map((account) => ({
				label: account.name,
				value: account.id,
			}))

			if (typeof value?.value === "object") {
				setFieldValue(controlName, {
					fieldId: field.id,
					value: value?.value?.id ?? "",
				})
			}

			input = (
				<ReactSelect
					name={controlName}
					value={accountOptions.find((option) => option.value === value?.value)}
					onChange={(newValue) => {
						setFieldValue(controlName, {
							fieldId: field.id,
							value: (newValue as SelectOption).value,
						})
					}}
					options={accountOptions}
					isSearchable
				/>
			)
			break
		case FieldType.SHOP:
			const shopOptions = shopList.map((shop) => ({
				label: shop.blockNo + " " + getFullName(shop.currentRentee),
				value: shop.id,
			}))

			if (typeof value?.value === "object") {
				setFieldValue(controlName, {
					fieldId: field.id,
					value: value?.value?.id ?? "",
				})
			}

			input = (
				<ReactSelect
					name={controlName}
					value={shopOptions.find((option) => option.value === value?.value)}
					onChange={(newValue) => {
						setFieldValue(controlName, {
							fieldId: field.id,
							value: (newValue as SelectOption).value,
						})
					}}
					options={shopOptions}
					isSearchable
				/>
			)
			break
	}

	return (
		<FormControl>
			<InputLabel label={field.name} />
			{input}
			<ErrorMessage component={ErrorMessageField} name={`fields[${field.id}]`} />
		</FormControl>
	)
}
