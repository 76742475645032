import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IShop } from "src/domain/entities"
import { shopAddService } from "src/domain/services/shop/shopAddService"
import { useAuth, useContactListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IShopAddFormFields, ShopAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ShopAddDrawerFormView>,
		"handleSubmit" | "contactList"
	> {
	onSuccess: (shop: IShop) => void
}

export const ShopAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { contactList, fetchContactList } = useContactListService()

	const handleSubmit = useCallback<FormikOnSubmit<IShopAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const shop = await shopAddService(values, token)
				onSuccess(shop)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token],
	)

	useEffect(() => {
		fetchContactList({ fetch: { category: true } })
	}, [fetchContactList])

	return (
		<ShopAddDrawerFormView
			contactList={contactList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
