import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useRef, useState } from "react"
import { BsCash, BsPrinterFill } from "react-icons/bs"
import ReactToPrint from "react-to-print"
import { IReceipt } from "src/domain/entities"
import { receiptListService } from "src/domain/services/receipt/receiptListService"
import { useAuth } from "src/hooks"
import { PaymentAddDrawerFormController } from "../../PaymentsPage/PaymentAddDrawerForm"
import { ReceiptPrintView } from "./ReceiptPrintView"

interface Props {
	receipt: IReceipt
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
}

export const ReceiptPreviewDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	receipt,
}) => {
	const [selectedReceipt, setSelectedReceipt] = useState<IReceipt>(receipt)
	const onClose = () => setIsOpen(false)
	const { token } = useAuth()

	const printComponentRef = useRef<HTMLDivElement>(null)

	const paymentAddDrawerDisclosure = useDisclosure()

	const onAddPaymentClick = () => {
		paymentAddDrawerDisclosure.onOpen()
	}
	const onAddPaymentSuccess = useCallback(async () => {
		if (!token) return
		const [updatedReceipt] = await receiptListService(
			{
				id: selectedReceipt.id,
				fetch: {
					payments: true,
					student: true,
					receiptItems: true,
					standard: true,
				},
			},
			token,
		)
		setSelectedReceipt(updatedReceipt)
		paymentAddDrawerDisclosure.onClose()
	}, [paymentAddDrawerDisclosure, selectedReceipt, token])

	return (
		<>
			<Modal onClose={onClose} isOpen={isOpen} isCentered size={"3xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Receipt Preview</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<ReceiptPrintView receipt={selectedReceipt} />
					</ModalBody>
					<ModalFooter>
						<Flex gridColumnGap={2}>
							<Button onClick={onClose}>Close</Button>
							<Button
								onClick={onAddPaymentClick}
								backgroundColor="teal.100"
								variant={"outline"}
								leftIcon={<BsCash />}
							>
								Add Payment
							</Button>
							<div ref={printComponentRef} className="printable">
								<ReceiptPrintView receipt={selectedReceipt} />
							</div>
							<ReactToPrint
								content={() => printComponentRef.current}
								copyStyles={true}
								trigger={() => (
									<Button
										colorScheme={"green"}
										leftIcon={<BsPrinterFill />}
									>
										Print
									</Button>
								)}
							/>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{paymentAddDrawerDisclosure.isOpen && (
				<PaymentAddDrawerFormController
					isOpen={paymentAddDrawerDisclosure.isOpen}
					onClose={paymentAddDrawerDisclosure.onClose}
					onSuccess={onAddPaymentSuccess}
					selectedReceiptId={selectedReceipt.id}
				/>
			)}
		</>
	)
}
