import { FC, useCallback, useState } from "react"
import DeleteItemDialog from "src/components/shared/DeleteItemDialog"
import { IExpense } from "src/domain/entities"
import { expenseDeleteService } from "src/domain/services/expense/expenseDeleteService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"

interface Props {
	expense: IExpense
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const ExpenseDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	expense,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleExpenseDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await expenseDeleteService({ id: expense.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [expense, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Expense: ${expense.expenseNo}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleExpenseDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
