import { useCallback, useState } from "react"
import { IStudent } from "src/domain/entities"
import { studentListService } from "src/domain/services/student/studentListService"
import { useAuth } from ".."

export function useStudentListService(): {
	studentList: IStudent[]
	isLoading: boolean
	fetchStudentList: (filter?: Parameters<typeof studentListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [studentList, setStudentList] = useState<IStudent[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchStudentList = useCallback(
		async (filter: Parameters<typeof studentListService>[0] = {}) => {
			setIsLoading(true)
			const data = await studentListService(filter, token)
			setStudentList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { studentList, isLoading, fetchStudentList }
}
