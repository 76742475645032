import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useStandardListService } from "src/hooks/standard"

import { IStandard } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { StandardAddDrawerFormController } from "./StandardAddDrawerForm"
import { StandardDeleteDialogController } from "./StandardDeleteDialogController"
import { StandardListController } from "./StandardList"
import { StandardUpdateDrawerFormController } from "./StandardUpdateDrawerForm"

export const StandardsPage: FC = () => {
	const { standardList, isLoading, fetchStandardList } = useStandardListService()
	const [isStandardDeleteDialogOpen, setIsStandardDeleteDialogOpen] = useState(false)
	const standardAddDrawerDisclosure = useDisclosure()
	const standardUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchStandardList()
	}, [fetchStandardList])

	const updateStandardRef = useRef<IStandard>()
	const deleteStandardRef = useRef<IStandard>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Standards
					</Text>
					<Button
						size="sm"
						onClick={standardAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Standard
					</Button>
				</Flex>
			</Box>
			<StandardListController
				list={standardList}
				isLoading={isLoading}
				onUpdate={(standard: IStandard) => {
					updateStandardRef.current = standard
					standardUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(standard: IStandard) => {
					deleteStandardRef.current = standard
					setIsStandardDeleteDialogOpen(true)
				}}
				onAddClick={standardAddDrawerDisclosure.onOpen}
			/>
			<StandardAddDrawerFormController
				{...standardAddDrawerDisclosure}
				onSuccess={() => fetchStandardList()}
			/>
			{updateStandardRef.current && (
				<StandardUpdateDrawerFormController
					{...standardUpdateDrawerDisclosure}
					standard={updateStandardRef.current}
					onSuccess={() => fetchStandardList()}
				/>
			)}
			{deleteStandardRef.current && (
				<StandardDeleteDialogController
					isOpen={isStandardDeleteDialogOpen}
					setIsOpen={setIsStandardDeleteDialogOpen}
					standard={deleteStandardRef.current}
					onSuccess={() => fetchStandardList()}
				/>
			)}
		</DashboardWrapper>
	)
}
