import { usePagination } from "@ajna/pagination"
import { SearchIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { SelectOption } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { useStandardListService, useStudentListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { IStudent } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { StudentAddDrawerFormController } from "./StudentAddDrawerForm"
import { StudentDeleteDialogController } from "./StudentDeleteDialogController"
import { StudentListController } from "./StudentList"
import { StudentUpdateDrawerFormController } from "./StudentUpdateDrawerForm"

export const StudentsPage: FC = () => {
	const { studentList, isLoading, fetchStudentList } = useStudentListService()
	const { standardList, fetchStandardList } = useStandardListService()
	const [isStudentDeleteDialogOpen, setIsStudentDeleteDialogOpen] = useState(false)
	const studentAddDrawerDisclosure = useDisclosure()
	const studentUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const [searchText, setSearchText] = useState("")
	const [selectedStandard, setSelectedStandard] = useState<SelectOption | null>({
		label: "All",
		value: null,
	})
	const standardOptions = useMemo(() => {
		return [
			{
				label: "All",
				value: null,
			},
			...standardList.map((standard) => ({
				label: standard.name,
				value: standard.id,
			})),
		]
	}, [standardList])

	const fetchStudents = useCallback(() => {
		fetchStudentList({
			fetch: { standard: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			search: searchText,
			standardId: selectedStandard?.value ?? undefined,
		})
	}, [fetchStudentList, pagination.currentPage, searchText, selectedStandard])

	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	useEffect(() => {
		fetchStudents()
		fetchStandardList()
	}, [fetchStudents, fetchStandardList])

	const updateStudentRef = useRef<IStudent>()
	const deleteStudentRef = useRef<IStudent>()

	return (
		<DashboardWrapper>
			<Box padding={2} pb={0}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Students
					</Text>
					<Button
						size="sm"
						onClick={studentAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Student
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<Box>
						<InputGroup width={"fit-content"}>
							<Input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<SearchIcon />
							</InputRightAddon>
						</InputGroup>
					</Box>
					<Box minW={"230px"}>
						<InputLabel label="Standard" />
						<ReactSelect
							onChange={(newValue) => {
								setSelectedStandard(
									standardOptions.find(
										(el) => el.value === newValue?.value,
									) ?? standardOptions[0],
								)
							}}
							value={selectedStandard}
							options={standardOptions}
							isSearchable={false}
						/>
					</Box>
				</Flex>
				{gujaratiSuggestions.length ? (
					<Box mt={1}>
						{gujaratiSuggestions.map((el) => (
							<Tag
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
				) : null}
			</Box>
			<StudentListController
				list={studentList}
				isLoading={isLoading}
				onUpdate={(student: IStudent) => {
					updateStudentRef.current = student
					studentUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(student: IStudent) => {
					deleteStudentRef.current = student
					setIsStudentDeleteDialogOpen(true)
				}}
				onAddClick={studentAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={studentList.length === 0}
			/>
			{studentAddDrawerDisclosure.isOpen ? (
				<StudentAddDrawerFormController
					{...studentAddDrawerDisclosure}
					onSuccess={() => fetchStudents()}
				/>
			) : null}
			{updateStudentRef.current && studentUpdateDrawerDisclosure.isOpen && (
				<StudentUpdateDrawerFormController
					{...studentUpdateDrawerDisclosure}
					student={updateStudentRef.current}
					onSuccess={() => fetchStudents()}
				/>
			)}
			{deleteStudentRef.current && (
				<StudentDeleteDialogController
					isOpen={isStudentDeleteDialogOpen}
					setIsOpen={setIsStudentDeleteDialogOpen}
					student={deleteStudentRef.current}
					onSuccess={() => fetchStudents()}
				/>
			)}
		</DashboardWrapper>
	)
}
