import { useCallback, useState } from "react"
import { IReceipt } from "src/domain/entities"
import { receiptListService } from "src/domain/services/receipt/receiptListService"
import { useAuth } from ".."

export function useReceiptListService(): {
	receiptList: IReceipt[]
	isLoading: boolean
	fetchReceiptList: (filter?: Parameters<typeof receiptListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [receiptList, setReceiptList] = useState<IReceipt[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchReceiptList = useCallback(
		async (filter: Parameters<typeof receiptListService>[0] = {}) => {
			setIsLoading(true)
			const data = await receiptListService(filter, token)
			setReceiptList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { receiptList, isLoading, fetchReceiptList }
}
