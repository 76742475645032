import { ComponentProps, FC, useCallback, useEffect, useReducer, useState } from "react"
import { IReceipt } from "src/domain/entities"
import { receiptAddService } from "src/domain/services/receipt/receiptAddService"
import { receiptNumberGetService } from "src/domain/services/receipt/receiptNumberGetService"
import { useAuth, useItemListService, useStudentListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IReceiptAddFormFields, ReceiptAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ReceiptAddDrawerFormView>,
		| "handleSubmit"
		| "studentList"
		| "itemList"
		| "receiptItems"
		| "dispatchReceiptItems"
		| "receiptNextNumber"
	> {
	onSuccess: (receipt: IReceipt) => void
}

export const ReceiptAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { studentList, fetchStudentList } = useStudentListService()
	const { itemList, fetchItemList } = useItemListService()
	const [receiptNextNumber, setReceiptNextNumber] = useState(0)

	const reducer = (
		state: { itemId: string; amount: number }[],
		action: {
			type: string
			index: number
			payload: { itemId: string; amount: number }
		},
	) => {
		switch (action.type) {
			case "add":
				return [...state, action.payload]
			case "update":
				return state.map((item, i) => {
					if (i === action.index) return action.payload
					return item
				})
			case "remove":
				if (state.length === 1) return state
				return state.filter((_, i) => i !== action.index)
			default:
				return state
		}
	}
	const [receiptItems, dispatchReceiptItems] = useReducer(reducer, [
		{
			itemId: "",
			amount: 0,
		},
	])

	const fetchReceiptNextNumber = useCallback(async () => {
		const receiptNextNumber = await receiptNumberGetService(token)
		setReceiptNextNumber(receiptNextNumber.receiptNo)
	}, [token])

	const handleSubmit = useCallback<FormikOnSubmit<IReceiptAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const receipt = await receiptAddService(
					{ ...values, receiptItems },
					token,
				)
				onSuccess(receipt)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ receiptNo: message })
			}
		},
		[onSuccess, rest, token, receiptItems],
	)

	useEffect(() => {
		fetchStudentList()
		fetchItemList({ categorySlug: "shikshan" })
		fetchReceiptNextNumber()
	}, [fetchStudentList, fetchItemList, fetchReceiptNextNumber])

	return (
		<ReceiptAddDrawerFormView
			studentList={studentList}
			itemList={itemList}
			handleSubmit={handleSubmit}
			receiptItems={receiptItems}
			dispatchReceiptItems={dispatchReceiptItems}
			receiptNextNumber={receiptNextNumber}
			{...rest}
		/>
	)
}
