import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IExpense, IExpenseCategory } from "src/domain/entities"
import { expenseUpdateService } from "src/domain/services/expense/expenseUpdateService"
import { useAuth, useExpenseCategoryListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IExpenseUpdateFormFields, ExpenseUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ExpenseUpdateDrawerFormView>,
	| "handleSubmit"
	| "expenseCategoryList"
	| "selectedExpenseCategory"
	| "setSelectedExpenseCategory"
> & {
	onSuccess?: (expense: IExpense) => void
}

export const ExpenseUpdateDrawerFormController: FC<Props> = ({
	expense,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { expenseCategoryList, fetchExpenseCategoryList } =
		useExpenseCategoryListService()

	const [selectedExpenseCategory, setSelectedExpenseCategory] = useState<
		IExpenseCategory | undefined
	>(expense.category)

	const handleSubmit = useCallback<FormikOnSubmit<IExpenseUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.categoryId === expense.category?.id) {
					values.categoryId = undefined
				}
				const updatedExpense = await expenseUpdateService(
					{ id: expense.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedExpense)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ expenseNo: message })
			}
		},
		[onSuccess, rest, token, expense.id, expense.category],
	)

	useEffect(() => {
		fetchExpenseCategoryList({ fetch: { fields: true } }).then((res) => {
			setSelectedExpenseCategory(res.find((c) => c.id === expense.category?.id))
		})
	}, [fetchExpenseCategoryList, expense.category])

	return (
		<ExpenseUpdateDrawerFormView
			expense={expense}
			handleSubmit={handleSubmit}
			expenseCategoryList={expenseCategoryList}
			selectedExpenseCategory={selectedExpenseCategory}
			setSelectedExpenseCategory={setSelectedExpenseCategory}
			{...rest}
		/>
	)
}
