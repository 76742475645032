import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { createRef, FC, RefObject, useCallback, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { PrintIconButton } from "src/components/ui/iconButtons/PrintIconButton"
import { IContact, IIncome } from "src/domain/entities"
import { IncomeCategorySlugs } from "src/utils/enums"
import { getFullName, toINR } from "src/utils/helpers"
import { IncomePreviewDialogController } from "./IncomePreviewDialogController"
import { IncomePrintView } from "./IncomePrintView"

export const IncomeListView: FC<{
	list: IIncome[]
	onUpdate: (income: IIncome) => void
	onDelete: (income: IIncome) => void
}> = ({ list, onUpdate, onDelete }) => {
	const printComponentRefs = useRef<RefObject<HTMLDivElement>[]>(
		list.map(() => createRef<HTMLDivElement>()),
	)

	const reactToPrintContent = useCallback((index: number) => {
		return printComponentRefs.current[index].current
	}, [])

	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const selectedPreviewIncome = useRef<IIncome | null>(null)
	const selectedPreviewIncomeIndex = useRef<number | null>(null)

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				body {
					padding: 0.5cm;
				}
				table {
					border: solid #000 !important;
					border-width: 1px 0 0 1px !important;
				}
				table th, table td {
					border: solid #000 !important;
					border-width: 0 1px 1px 0 !important;
				}
			}
		`
	}

	return (
		<>
			<Table size={"sm"}>
				<Thead>
					<Tr>
						<Th width={"15%"}>Income No.</Th>
						<Th>Contact</Th>
						<Th>Income Category</Th>
						<Th>Amount</Th>
						<Th isNumeric>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((income, i) => (
						<Tr
							key={income.id}
							_hover={{
								backgroundColor: "gray.100",
							}}
							cursor="pointer"
							onClick={() => {
								selectedPreviewIncome.current = income
								selectedPreviewIncomeIndex.current = i
								setIsPreviewOpen(true)
							}}
						>
							<Td>{income.incomeNo}</Td>
							<Td>{getIncomeContact(income)}</Td>
							<Td>{income.category?.name}</Td>
							<Td>{getIncomeAmount(income)}</Td>
							<Td isNumeric>
								<div ref={printComponentRefs.current[i]}>
									<style>{getPageStyles()}</style>
									<div className="printable">
										<div style={{ pageBreakBefore: "always" }}>
											<IncomePrintView income={income} />
										</div>
									</div>
								</div>
								<ReactToPrint
									content={() => reactToPrintContent(i)}
									copyStyles={true}
									trigger={() => <PrintIconButton />}
								/>
								<EditIconButton onClick={() => onUpdate(income)} />
								<DeleteIconButton onClick={() => onDelete(income)} />
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			{selectedPreviewIncome.current &&
			selectedPreviewIncomeIndex.current != null &&
			isPreviewOpen ? (
				<IncomePreviewDialogController
					isOpen={isPreviewOpen}
					setIsOpen={setIsPreviewOpen}
					income={selectedPreviewIncome.current}
				/>
			) : null}
		</>
	)
}

export const getIncomeAmount = (income: IIncome) => {
	let particulars = ""

	switch (income.category?.slug) {
		case IncomeCategorySlugs.TRUST_FUND_RECEIPT: {
			const trustFundAmount: number = income.fields?.find(
				(field) => field.field.name === "Trust fund amount",
			)?.value

			const buildingFundAmount: number = income.fields?.find(
				(field) => field.field.name === "Building fund amount",
			)?.value

			const amount = trustFundAmount + buildingFundAmount

			particulars = toINR(amount).toString()
			break
		}
		default:
			const amount: number = income.fields?.find(
				(field) => field.field.name === "Amount",
			)?.value

			particulars = toINR(amount).toString()
			break
	}
	return particulars
}

export const getIncomeContact = (income: IIncome) => {
	let particulars = ""

	switch (income.category?.slug) {
		case IncomeCategorySlugs.TRUST_FUND_RECEIPT: {
			const contact: IContact = income.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case IncomeCategorySlugs.SHOP_RENT_RECEIPT: {
			const contact: IContact = income.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case IncomeCategorySlugs.OTHER_PAYMENT_RECEIPT: {
			const contact: IContact = income.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case IncomeCategorySlugs.OTHER_INCOME_RECEIPT: {
			const contact: IContact = income.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
		case IncomeCategorySlugs.LIFETIME_MEMBER_RECEIPT: {
			const contact: IContact = income.fields?.find(
				(field) => field.field.name === "Contact",
			)?.value

			particulars = `${getFullName(contact)}`
			break
		}
	}
	return particulars
}
