import { ApiRequester } from "../ApiRequester"

interface ResponseDataShape {
	studentNo: number
}

export async function studentNumberGetApi(token: string) {
	const endpoint = "/student/number/get"
	const requester = new ApiRequester<{}, ResponseDataShape>(endpoint)
	return await requester.sendRequest({}, token)
}
