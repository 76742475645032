import { useCallback, useState } from "react"
import { IExpenseCategory } from "src/domain/entities"
import { expenseCategoryListService } from "src/domain/services/expenseCategory/expenseCategoryListService"
import { useAuth } from ".."

export function useExpenseCategoryListService(): {
	expenseCategoryList: IExpenseCategory[]
	isLoading: boolean
	fetchExpenseCategoryList: (
		filter?: Parameters<typeof expenseCategoryListService>[0],
	) => Promise<IExpenseCategory[]>
} {
	const { token } = useAuth()
	const [expenseCategoryList, setExpenseCategoryList] = useState<IExpenseCategory[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchExpenseCategoryList = useCallback(
		async (filter: Parameters<typeof expenseCategoryListService>[0] = {}) => {
			setIsLoading(true)
			const data = await expenseCategoryListService(filter, token)
			setExpenseCategoryList(data)
			setIsLoading(false)
			return data
		},
		[token],
	)

	return { expenseCategoryList, isLoading, fetchExpenseCategoryList }
}
