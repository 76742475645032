import { IPayment } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update?: {
		paymentMode?: string
		paymentDate?: number
		paymentAmount?: number
		receiptId?: string
	}
}

export async function paymentUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/payment/update/v1"
	const requester = new ApiRequester<RequestDataShape, IPayment>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
