import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { createRef, FC, RefObject, useCallback, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { PrintIconButton } from "src/components/ui/iconButtons/PrintIconButton"
import { IReceipt } from "src/domain/entities"
import { formatDate, getFullName, toINR } from "src/utils/helpers"
import { ReceiptPreviewDialogController } from "./ReceiptPreviewDialogController"
import { ReceiptPrintView } from "./ReceiptPrintView"

export const ReceiptListView: FC<{
	list: IReceipt[]
	onUpdate: (receipt: IReceipt) => void
	onDelete: (receipt: IReceipt) => void
}> = ({ list, onUpdate, onDelete }) => {
	const printComponentRefs = useRef<RefObject<HTMLDivElement>[]>(
		list.map(() => createRef<HTMLDivElement>()),
	)

	const reactToPrintContent = useCallback((index: number) => {
		return printComponentRefs.current[index].current
	}, [])

	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const selectedPreviewReceipt = useRef<IReceipt | null>(null)
	const selectedPreviewReceiptIndex = useRef<number | null>(null)

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				body {
					padding: 0.5cm;
				}
				table {
					border: solid #000 !important;
					border-width: 1px 0 0 1px !important;
				}
				table th, table td {
					border: solid #000 !important;
					border-width: 0 1px 1px 0 !important;
				}
			}
		`
	}
	return (
		<>
			<Table size={"sm"}>
				<Thead>
					<Tr>
						<Th>Receipt No.</Th>
						<Th>Date</Th>
						<Th>Name</Th>
						<Th isNumeric>Total</Th>
						<Th isNumeric>Paid</Th>
						<Th isNumeric>Remaining</Th>
						<Th isNumeric>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((receipt, i) => {
						const remainingAmount = receipt.totalAmount - receipt.paidAmount
						return (
							<Tr
								key={receipt.id}
								_hover={{
									backgroundColor: "gray.100",
								}}
								cursor="pointer"
								onClick={() => {
									selectedPreviewReceipt.current = receipt
									selectedPreviewReceiptIndex.current = i
									setIsPreviewOpen(true)
								}}
							>
								<Td>{receipt.receiptNo}</Td>
								<Td>{formatDate(receipt.receiptDate)}</Td>
								<Td>{getFullName(receipt.student)}</Td>
								<Td isNumeric>{toINR(receipt.totalAmount)}</Td>
								<Td isNumeric>
									{receipt.paidAmount ? toINR(receipt.paidAmount) : "-"}
								</Td>
								<Td isNumeric>
									{remainingAmount ? toINR(remainingAmount) : "-"}
								</Td>
								<Td isNumeric>
									<div ref={printComponentRefs.current[i]}>
										<style>{getPageStyles()}</style>
										<div className="printable">
											<div style={{ pageBreakBefore: "always" }}>
												<ReceiptPrintView receipt={receipt} />
											</div>
										</div>
									</div>
									<ReactToPrint
										content={() => reactToPrintContent(i)}
										copyStyles={true}
										trigger={() => <PrintIconButton />}
									/>
									<EditIconButton onClick={() => onUpdate(receipt)} />
									<DeleteIconButton onClick={() => onDelete(receipt)} />
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
			{selectedPreviewReceipt.current &&
			selectedPreviewReceiptIndex.current != null &&
			isPreviewOpen ? (
				<ReceiptPreviewDialogController
					isOpen={isPreviewOpen}
					setIsOpen={setIsPreviewOpen}
					receipt={selectedPreviewReceipt.current}
				/>
			) : null}
		</>
	)
}
