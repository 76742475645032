import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { CommitteeMemberCategory, ICommitteeMember } from "src/domain/entities"

export const CommitteeMemberListView: FC<{
	list: ICommitteeMember[]
	onUpdate: (committeeMember: ICommitteeMember) => void
	onDelete: (committeeMember: ICommitteeMember) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th width={"15%"}>Category</Th>
				<Th>Designation</Th>
				<Th>Name</Th>
				<Th>Tenure</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((committeeMember, i) => (
				<Tr key={i}>
					<Td>
						{committeeMember.category === CommitteeMemberCategory.TRUSTEE
							? "Trustee"
							: "Working body"}
					</Td>
					<Td>
						{committeeMember.designation.en} ({committeeMember.designation.gu}
						)
					</Td>
					<Td>
						{committeeMember.profile?.firstName?.en}{" "}
						{committeeMember.profile?.surname?.name?.en}
					</Td>
					<Td>
						{committeeMember.tenure?.term?.en} (
						{committeeMember.tenure?.term?.gu})
					</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(committeeMember)} />
						<DeleteIconButton onClick={() => onDelete(committeeMember)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
