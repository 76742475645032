import {
	Box,
	Flex,
	FormControl,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Tag,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { CommitteeMemberCategory, ITenure } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { ICommitteeMemberAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<ICommitteeMemberAddFormFields>
	tenureList: ITenure[]
	profileList: any[]
	setProfileSearchText: (value: string) => void
	getGujaratiSuggestions: (value: string) => Promise<string[]>
}

export const CommitteeMemberAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	tenureList,
	profileList,
	getGujaratiSuggestions,
	setProfileSearchText,
	...rest
}) => {
	const [designationSuggestions, setDesignationSuggestions] = useState<string[]>([])

	return (
		<Formik<ICommitteeMemberAddFormFields>
			initialValues={{
				profileId: "",
				tenureId: "",
				category: CommitteeMemberCategory.WORKING_BODY,
				designation: {
					en: "",
					gu: "",
				},
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleDesignationChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("designation.en", e.target.value)
					const suggestions = await getGujaratiSuggestions(e.target.value)
					setDesignationSuggestions(suggestions)
				}

				const categoryList = Object.values(CommitteeMemberCategory).map(
					(category) => {
						switch (category) {
							case CommitteeMemberCategory.WORKING_BODY:
								return {
									label: "Working Body",
									value: category,
								}
							case CommitteeMemberCategory.TRUSTEE:
								return {
									label: "Trustee",
									value: category,
								}
							default:
								return {
									label: category,
									value: category,
								}
						}
					},
				)
				return (
					<DrawerForm
						size="lg"
						headerLabel="Add Committee Member"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
							<Flex gridGap={2}>
								{/* Tenure */}
								<FormControl flex={2}>
									<InputLabel label="Tenure" />
									<ReactSelect
										name="tenureId"
										onChange={(newValue) => {
											setFieldValue(
												"tenureId",
												(newValue as SelectOption).value,
											)
										}}
										options={tenureList.map((tenure) => ({
											label:
												tenure.term.en +
												"(" +
												tenure.term.gu +
												")",
											value: tenure.id,
										}))}
										isSearchable
									/>
								</FormControl>
								{/* Profile */}
								<FormControl flex={3}>
									<InputLabel label="Profile" />
									<ReactSelect
										name="profileId"
										onChange={(newValue) => {
											setFieldValue(
												"profileId",
												(newValue as SelectOption).value,
											)
										}}
										onInputChange={(value) => {
											setProfileSearchText(value)
										}}
										options={profileList.map((profile) => ({
											label:
												profile.firstName.en +
												" " +
												profile.surname?.name?.en,
											value: profile.id,
										}))}
										isSearchable
									/>
								</FormControl>
							</Flex>

							<Flex gridColumnGap={2}>
								{/* Designation En */}
								<Box flex={1}>
									<FormControl>
										<InputLabel label="Designation" />
										<Input
											name="designation.en"
											maxLength={50}
											required
											value={values.designation.en}
											onChange={handleDesignationChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="designation.en"
										/>
									</FormControl>
								</Box>
								{/* Designation Gu */}
								<Box flex={1}>
									<FormControl>
										<InputLabel label="Designation Gu" />
										<Input
											name="designation.gu"
											maxLength={50}
											required
											value={values.designation.gu}
											onChange={handleChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="designation.gu"
										/>
									</FormControl>
								</Box>
							</Flex>
							<Box>
								{designationSuggestions.map((el) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("designation.gu", el)
											setDesignationSuggestions([])
										}}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Category radio */}
							<FormControl>
								<InputLabel label="Category" />
								<RadioGroup
									name="category"
									value={values.category}
									onChange={(val) => setFieldValue("category", val)}
								>
									<Stack direction="row">
										{categoryList.map((category) => (
											<Radio
												key={category.value}
												value={category.value}
											>
												{category.label}
											</Radio>
										))}
									</Stack>
								</RadioGroup>
								<ErrorMessage
									component={ErrorMessageField}
									name="category"
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
