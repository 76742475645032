import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IIncome, IIncomeCategory } from "src/domain/entities"
import { incomeUpdateService } from "src/domain/services/income/incomeUpdateService"
import { useAuth, useIncomeCategoryListService } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IIncomeUpdateFormFields, IncomeUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof IncomeUpdateDrawerFormView>,
	| "handleSubmit"
	| "incomeCategoryList"
	| "selectedIncomeCategory"
	| "setSelectedIncomeCategory"
> & {
	onSuccess?: (income: IIncome) => void
}

export const IncomeUpdateDrawerFormController: FC<Props> = ({
	income,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const { incomeCategoryList, fetchIncomeCategoryList } = useIncomeCategoryListService()

	const [selectedIncomeCategory, setSelectedIncomeCategory] = useState<
		IIncomeCategory | undefined
	>(income.category)

	const handleSubmit = useCallback<FormikOnSubmit<IIncomeUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.categoryId === income.category?.id) {
					values.categoryId = undefined
				}
				const updatedIncome = await incomeUpdateService(
					{ id: income.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedIncome)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ incomeNo: message })
			}
		},
		[onSuccess, rest, token, income.id, income.category],
	)

	useEffect(() => {
		fetchIncomeCategoryList({ fetch: { fields: true } }).then((res) => {
			setSelectedIncomeCategory(res.find((c) => c.id === income.category?.id))
		})
	}, [fetchIncomeCategoryList, income.category])

	return (
		<IncomeUpdateDrawerFormView
			income={income}
			handleSubmit={handleSubmit}
			incomeCategoryList={incomeCategoryList}
			selectedIncomeCategory={selectedIncomeCategory}
			setSelectedIncomeCategory={setSelectedIncomeCategory}
			{...rest}
		/>
	)
}
