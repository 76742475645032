import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"
import { useAccountListService } from "src/hooks/account"

import { IAccount } from "../../../domain/entities"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { AccountAddDrawerFormController } from "./AccountAddDrawerForm"
import { AccountDeleteDialogController } from "./AccountDeleteDialogController"
import { AccountListController } from "./AccountList"
import { AccountUpdateDrawerFormController } from "./AccountUpdateDrawerForm"

export const AccountsPage: FC = () => {
	const { accountList, isLoading, fetchAccountList } = useAccountListService()
	const [isAccountDeleteDialogOpen, setIsAccountDeleteDialogOpen] = useState(false)
	const accountAddDrawerDisclosure = useDisclosure()
	const accountUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchAccountList()
	}, [fetchAccountList])

	const updateAccountRef = useRef<IAccount>()
	const deleteAccountRef = useRef<IAccount>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Accounts
					</Text>
					<Button
						size="sm"
						onClick={accountAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Account
					</Button>
				</Flex>
			</Box>
			<AccountListController
				list={accountList}
				isLoading={isLoading}
				onUpdate={(account: IAccount) => {
					updateAccountRef.current = account
					accountUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(account: IAccount) => {
					deleteAccountRef.current = account
					setIsAccountDeleteDialogOpen(true)
				}}
				onAddClick={accountAddDrawerDisclosure.onOpen}
			/>
			{accountAddDrawerDisclosure.isOpen ? (
				<AccountAddDrawerFormController
					{...accountAddDrawerDisclosure}
					onSuccess={() => fetchAccountList()}
				/>
			) : null}
			{updateAccountRef.current && accountUpdateDrawerDisclosure.isOpen && (
				<AccountUpdateDrawerFormController
					{...accountUpdateDrawerDisclosure}
					account={updateAccountRef.current}
					onSuccess={() => fetchAccountList()}
				/>
			)}
			{deleteAccountRef.current && isAccountDeleteDialogOpen && (
				<AccountDeleteDialogController
					isOpen={isAccountDeleteDialogOpen}
					setIsOpen={setIsAccountDeleteDialogOpen}
					account={deleteAccountRef.current}
					onSuccess={() => fetchAccountList()}
				/>
			)}
		</DashboardWrapper>
	)
}
