import { useCallback, useState } from "react"
import { IContact } from "src/domain/entities"
import { contactListService } from "src/domain/services/contact/contactListService"
import { useAuth } from ".."

export function useContactListService(): {
	contactList: IContact[]
	isLoading: boolean
	fetchContactList: (filter?: Parameters<typeof contactListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [contactList, setContactList] = useState<IContact[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchContactList = useCallback(
		async (filter: Parameters<typeof contactListService>[0] = {}) => {
			setIsLoading(true)
			const data = await contactListService(filter, token)
			setContactList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { contactList, isLoading, fetchContactList }
}
