import { IIncomeCategory } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	slugs?: string[]
	fetch?: {
		fields: boolean
	}
}

export async function incomeCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/income/category/list/v1"
	const requester = new ApiRequester<RequestDataShape, IIncomeCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
