import { AddIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Flex,
	FormControl,
	IconButton,
	Input,
	Stack,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { BsCheck2, BsX } from "react-icons/bs"
import ReactSelect from "react-select"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { InputLabel } from "src/components/ui/InputLabel"
import { IIncomeCategory, IField } from "src/domain/entities"
import { FieldType } from "src/utils/enums"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IIncomeCategoryUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	incomeCategory: IIncomeCategory
	fields: IField[]
	setFields: (fields: IField[]) => void
	handleSubmit: FormikOnSubmit<IIncomeCategoryUpdateFormFields>
	handleFieldAdd: () => void
	handleFieldUpdate: (
		id: string,
		update: {
			name?: string
			type?: FieldType
		},
	) => void
	handleFieldDelete: (index: number) => void
}

export const IncomeCategoryUpdateDrawerFormView: FC<Props> = ({
	incomeCategory,
	fields,
	setFields,
	handleSubmit,
	handleFieldAdd,
	handleFieldUpdate,
	handleFieldDelete,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	const [editingFieldIndex, setEditingFieldIndex] = useState<number>()

	return (
		<Formik<IIncomeCategoryUpdateFormFields>
			initialValues={{
				name: incomeCategory.name,
				slug: incomeCategory.slug,
				note: incomeCategory.note,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="md"
						headerLabel="Update Income Category"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"lg"} marginX={"auto"}>
							<Flex gridColumnGap={2}>
								{/* Name */}
								<FormControl>
									<InputLabel label="Name" />
									<Input
										name="name"
										placeholder="Name"
										maxLength={50}
										required
										autoFocus
										value={values.name}
										onChange={handleNameChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name"
									/>
								</FormControl>
								{/* Slug */}
								<FormControl>
									<InputLabel label="Slug" />
									<Input
										name="slug"
										placeholder="Slug"
										maxLength={50}
										required
										value={values.slug}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="slug"
									/>
								</FormControl>
							</Flex>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Note */}
							<FormControl>
								<InputLabel label="Note" />
								<Input
									name="note"
									placeholder="Note"
									maxLength={50}
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="note" />
							</FormControl>
							<Box>
								<Text fontWeight="bold" fontSize={16}>
									Fields
								</Text>

								<Table
									backgroundColor={"gray.100"}
									borderRadius={8}
									marginTop={2}
								>
									<Thead>
										<Tr>
											<Th
												pr={0}
												pl={2}
												width={"5%"}
												style={{
													textTransform: "none",
													whiteSpace: "nowrap",
												}}
											>
												Sr No.
											</Th>
											<Th width={"40%"}>Name</Th>
											<Th width={"40%"} pl={0} pr={0}>
												Type
											</Th>
											<Th pr={2} pl={0} isNumeric>
												Action
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{fields.map((field, index) => (
											<Tr key={index}>
												<Td pr={0}>{index + 1}</Td>
												<Td pl={0} pr={2}>
													{editingFieldIndex === index ? (
														<Input
															backgroundColor={"white"}
															borderColor={"gray.300"}
															value={field.name}
															name={`fields[${index}].name`}
															onChange={(e) => {
																setFields(
																	fields.map((f, i) =>
																		i === index
																			? {
																					...f,
																					name: e
																						.target
																						.value,
																			  }
																			: f,
																	),
																)
															}}
														/>
													) : (
														field.name || "-"
													)}
												</Td>
												<Td pl={0} pr={0}>
													{editingFieldIndex === index ? (
														<ReactSelect
															options={Object.entries(
																FieldType,
															).map(([_, value]) => ({
																label: value,
																value,
															}))}
															value={{
																label: field.type,
																value: field.type,
															}}
															name={`fields[${index}].type`}
															onChange={(e) => {
																setFields(
																	fields.map((f, i) =>
																		i === index
																			? {
																					...f,
																					type: e?.value as FieldType,
																			  }
																			: f,
																	),
																)
															}}
														/>
													) : (
														field.type || "-"
													)}
												</Td>
												<Td pr={2}>
													<Flex justify={"flex-end"}>
														{editingFieldIndex === index ? (
															<>
																<IconButton
																	aria-label="Save"
																	size={"sm"}
																	mx="1"
																	icon={<BsCheck2 />}
																	variant="ghost"
																	colorScheme={"green"}
																	onClick={() => {
																		handleFieldUpdate(
																			field.id,
																			{
																				name: field.name,
																				type: field.type,
																			},
																		)
																		setEditingFieldIndex(
																			undefined,
																		)
																	}}
																/>
																<IconButton
																	aria-label="Cancel"
																	size={"sm"}
																	mx="1"
																	icon={<BsX />}
																	variant="ghost"
																	colorScheme={"red"}
																	onClick={() => {
																		setEditingFieldIndex(
																			undefined,
																		)
																	}}
																/>
															</>
														) : (
															<EditIconButton
																onClick={() => {
																	setEditingFieldIndex(
																		index,
																	)
																}}
															/>
														)}
														<DeleteIconButton
															onClick={() => {
																handleFieldDelete(index)
															}}
														/>
													</Flex>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
								<Button
									leftIcon={<AddIcon fontSize={10} />}
									backgroundColor={"gray.200"}
									_hover={{ backgroundColor: "gray.300" }}
									size={"sm"}
									marginTop={2}
									onClick={() => {
										handleFieldAdd()
									}}
								>
									Add
								</Button>
							</Box>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
