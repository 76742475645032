import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { IStudent } from "src/domain/entities"
import { studentAddService } from "src/domain/services/student/studentAddService"
import { studentNumberGetService } from "src/domain/services/student/studentNumberGetService"
import { useAuth, useStandardListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IStudentAddFormFields, StudentAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof StudentAddDrawerFormView>,
		"handleSubmit" | "standardList" | "studentNextNumber" | "getGujaratiSuggestions"
	> {
	onSuccess: (student: IStudent) => void
}

export const StudentAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { standardList, fetchStandardList } = useStandardListService()
	const [studentNextNumber, setStudentNextNumber] = useState(0)

	const handleSubmit = useCallback<FormikOnSubmit<IStudentAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const student = await studentAddService(values, token)
				onSuccess(student)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ firstName: message })
			}
		},
		[onSuccess, rest, token],
	)

	const fetchStudentNextNumber = useCallback(async () => {
		const studentNextNumber = await studentNumberGetService(token)
		setStudentNextNumber(studentNextNumber.studentNo)
	}, [token])

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchStandardList()
		fetchStudentNextNumber()
	}, [fetchStandardList, fetchStudentNextNumber])

	return (
		<StudentAddDrawerFormView
			standardList={standardList}
			handleSubmit={handleSubmit}
			studentNextNumber={studentNextNumber}
			getGujaratiSuggestions={getGujaratiSuggestions}
			{...rest}
		/>
	)
}
