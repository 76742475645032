import { IIncomeCategory } from "src/domain/entities"
import { incomeCategoryListApi } from "../../api/incomeCategory/incomeCategoryListApi"

type PayloadShape = Parameters<typeof incomeCategoryListApi>[0]

export async function incomeCategoryListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const incomeCategory = await incomeCategoryListApi(payload, token)
	return incomeCategory as NonNullable<IIncomeCategory[]>
}
