import { ComponentProps, FC, useCallback, useEffect } from "react"
import { IContact } from "src/domain/entities"
import { contactAddService } from "src/domain/services/contact/contactAddService"
import { useAuth, useContactCategoryListService } from "src/hooks"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ContactAddDrawerFormView, IContactAddFormFields } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ContactAddDrawerFormView>,
		"handleSubmit" | "contactCategoryList" | "getGujaratiSuggestions"
	> {
	onSuccess: (contact: IContact) => void
}

export const ContactAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const { contactCategoryList, fetchContactCategoryList } =
		useContactCategoryListService()

	const handleSubmit = useCallback<FormikOnSubmit<IContactAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const contact = await contactAddService(values, token)
				onSuccess(contact)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ firstName: message })
			}
		},
		[onSuccess, rest, token],
	)

	const getGujaratiSuggestions = useCallback(async (searchText) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		return suggestions
	}, [])

	useEffect(() => {
		fetchContactCategoryList()
	}, [fetchContactCategoryList])

	return (
		<ContactAddDrawerFormView
			contactCategoryList={contactCategoryList}
			handleSubmit={handleSubmit}
			getGujaratiSuggestions={getGujaratiSuggestions}
			{...rest}
		/>
	)
}
