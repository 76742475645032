import dayjs from "dayjs"
import { IHallBookingReceiptAddFormFields } from "src/components/pages/HallBookingsPage/HallBookingReceiptPage/HallBookingReceiptAddDrawerForm"
import { incomeAddApi } from "src/domain/api/income/incomeAddApi"
import { IHallBookingReceipt } from "src/domain/entities"

type PayloadShape = Parameters<typeof incomeAddApi>[0]

export async function hallBookingReceiptAddService(
	payload: IHallBookingReceiptAddFormFields,
	token: string,
): Promise<IHallBookingReceipt> {
	const payloadIncome: PayloadShape = {
		incomeNo: +payload.hallBookingReceiptNo,
		date: dayjs(payload.date).unix() * 1000,
		fields: payload.fields
			.map((field) => ({
				fieldId: field.fieldId,
				value: field.value,
			}))
			.filter((field) => field.value !== ""),
		categoryId: payload.categoryId,
	}

	const { createdAt, date, id, incomeNo, updatedAt, category, fields } =
		await incomeAddApi(payloadIncome, token)

	return {
		id,
		createdAt,
		date,
		fields: fields ?? [],
		hallBookingReceiptNo: incomeNo,
		updatedAt,
		category,
	}
}
