import { IStandard } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function standardListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/standard/list/v1"
	const requester = new ApiRequester<RequestDataShape, IStandard[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
