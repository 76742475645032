import { IContactCategory } from "src/domain/entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function contactCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/contact/category/list/v1"
	const requester = new ApiRequester<RequestDataShape, IContactCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
