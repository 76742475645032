import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { IStandard } from "src/domain/entities"

export const StandardListView: FC<{
	list: IStandard[]
	onUpdate: (standard: IStandard) => void
	onDelete: (standard: IStandard) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Semester</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((standard, i) => (
				<Tr key={i}>
					<Td>{standard.name}</Td>
					<Td>{standard.semester}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(standard)} />
						<DeleteIconButton onClick={() => onDelete(standard)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
