import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IContact } from "src/domain/entities"
import { getFullName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IShopAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IShopAddFormFields>
	selectedContactId?: string
	contactList: IContact[]
}

export const ShopAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	contactList,
	selectedContactId,
	...rest
}) => {
	const contactOptions = contactList.map((contact) => ({
		label: getFullName(contact),
		value: contact.id,
	}))
	return (
		<Formik<IShopAddFormFields>
			initialValues={{
				blockNo: "",
				currentRenteeId: "",
				gstPercentage: 0,
				rent: 0,
				note: "",
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => (
				<DrawerForm
					size="lg"
					headerLabel="Add Shop"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* Block No. */}
							<FormControl flex={1}>
								<InputLabel label="Block No." />
								<Input
									name="blockNo"
									maxLength={50}
									autoFocus
									value={values.blockNo}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="blockNo"
								/>
							</FormControl>
							{/* Current Rentee */}
							<FormControl flex={2}>
								<InputLabel label="Current Rentee" />
								<ReactSelect
									name="currentRenteeId"
									onChange={(newValue) => {
										setFieldValue(
											"currentRenteeId",
											(newValue as SelectOption).value,
										)
									}}
									value={contactOptions.find(
										(el) => el.value === values.currentRenteeId,
									)}
									options={contactOptions}
									isSearchable
								/>
							</FormControl>
						</Flex>
						<Flex gridColumnGap={2}>
							{/* Rent */}
							<FormControl flex={1}>
								<InputLabel label="Rent" />
								<Input
									name="rent"
									maxLength={50}
									type="number"
									onChange={handleChange}
								/>
								<ErrorMessage component={ErrorMessageField} name="rent" />
							</FormControl>
							{/* GST% */}
							<FormControl flex={2}>
								<InputLabel label="GST%" />
								<Input
									name="gstPercentage"
									maxLength={50}
									type="number"
									value={values.gstPercentage}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="gstPercentage"
								/>
							</FormControl>
						</Flex>
						{/* Note */}
						<FormControl>
							<InputLabel label="Note" />
							<Input
								name="note"
								placeholder="Note"
								maxLength={50}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
