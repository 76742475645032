import {
	Box,
	Flex,
	Image,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import moment from "moment"
import { IExpense, IHallBookingReceipt, IIncome } from "src/domain/entities"
import { formatDate, getFullName, getGujaratiString, toINRWords } from "src/utils/helpers"

export const ReceiptPrintsPage: React.FC = () => {
	return (
		<Box>
			<Box>
				<ComponentHeading>1. Hostel Fee Receipt</ComponentHeading>
				<GujaratiText>
					<HostelFeeReceiptPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>2. Vaadi Bhada deposit Receipt</ComponentHeading>
				<GujaratiText>
					<VaadiBhadaDepositReceiptPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>3. Lifetime member fees Receipt</ComponentHeading>
				<GujaratiText>
					<LifetimeMemberFeesReceiptPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>4. Other income Receipt</ComponentHeading>
				<GujaratiText>
					<OtherIncomeRecepitPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>5. Other Payment Receipt</ComponentHeading>
				<GujaratiText>
					<OtherPaymentRecepitPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>6. Shop rent Receipt</ComponentHeading>
				<GujaratiText>
					<ShopRentReceiptPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>7. Trust Fund Receipt</ComponentHeading>
				<GujaratiText>
					<TrustFundReceiptPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>8. Hostel Voucher</ComponentHeading>
				<GujaratiText>
					<HostelVoucherPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>9. Party Plot Voucher</ComponentHeading>
				<GujaratiText>
					<PartyPlotVoucherPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>10. Vaadi Voucher</ComponentHeading>
				<GujaratiText>
					<VaadiVoucherPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>11. Samaj Voucher</ComponentHeading>
				<GujaratiText>
					<SamajVoucherPrintView />
				</GujaratiText>
			</Box>
			<Box>
				<ComponentHeading>12. Vaddi Rent Bill</ComponentHeading>
				<GujaratiText>
					<VaadiRentBillPrintView />
				</GujaratiText>
			</Box>
		</Box>
	)
}

export const HostelFeeReceiptPrintView: React.FC = () => {
	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				<Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Image src="/lps_logo.jpeg" height={16} />
					<Box textAlign={"center"} flex={1}>
						<Box fontSize={20} lineHeight="1.2">
							શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ - સંચાલિત
						</Box>
						<Box fontSize={20} color={"red.700"} fontWeight="500">
							માતૃશ્રી રતનબેન માધવજી લેઉવા પટેલ કન્યા છાત્રાલય
						</Box>
						<Box fontSize={14} color="gray.700" lineHeight={1}>
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર - ૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex>
				<Box
					pt={2}
					fontWeight={"bold"}
					fontSize={"lg"}
					textAlign="center"
					textDecoration={"underline"}
				>
					ફી પહોંચ
				</Box>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								પહોંચ નં. :{" "}
							</Text>
							<Text as="span">3</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								વિદ્યાર્થી નં. :{" "}
							</Text>
							<Text as="span">10</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								વિદ્યાર્થીનું નામ :{" "}
							</Text>
							<Text as="span">Testing Student</Text>
						</Box>
					</Box>
					<Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								પહોંચ તારીખ :{" "}
							</Text>
							<Text as="span">12-12-2020</Text>
						</Box>
						<Box>
							<Text as="span" fontWeight={"bold"}>
								ધોરણ :{" "}
							</Text>
							<Text as="span">10</Text>
						</Box>

						<Box>
							<Text as="span" fontWeight={"bold"}>
								સેમેસ્ટર :{" "}
							</Text>
							<Text as="span">4</Text>
						</Box>
					</Box>
				</Flex>
			</Box>
			{/* Receipt Items */}
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "2px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="10%"
								textAlign={"center"}
							>
								ક્રમ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								textAlign="center"
							>
								ફીની વિગતો
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="20%"
								textAlign="center"
							>
								રકમ
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								1
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={0} pl={2}>
								Bus Bhada
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								14000
							</Td>
						</Tr>
					</Tbody>
					<Tfoot>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ રકમ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
								fontWeight={"bold"}
							>
								25000
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ ચૂકવેલ ફી
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								12000
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								colSpan={2}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								બાકી ફી
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
								fontWeight={"bold"}
							>
								3000
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>

			{/* Signature */}
			<Flex alignItems="center" mt={10}>
				<Box flex={1}></Box>
				<Box flex={1}></Box>
				<Box flex={1}>
					<Text fontSize="sm" fontWeight="bold">
						નાણાં લેનારની સહી
					</Text>
				</Box>
			</Flex>
		</Box>
	)
}

export const VaadiBhadaDepositReceiptPrintView: React.FC<{
	hallBookingReceipt?: IHallBookingReceipt
}> = ({ hallBookingReceipt }) => {
	if (!hallBookingReceipt) return null

	var fields = {
		hallBookingReceiptNo: hallBookingReceipt.hallBookingReceiptNo,
		date: moment(hallBookingReceipt.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			hallBookingReceipt.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		contactAddress: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Contact address",
		)?.value,
		startDate: formatDate(
			hallBookingReceipt.fields?.find((f) => f.field.name === "Start date")?.value,
			{ gujarati: true },
		),
		endDate: formatDate(
			hallBookingReceipt.fields?.find((f) => f.field.name === "End date")?.value,
			{ gujarati: true },
		),
		location: hallBookingReceipt.fields?.find((f) => f.field.name === "Lobby / Area")
			?.value,
		description: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Description",
		)?.value,
		paymentMode: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Payment mode",
		)?.value,
		amount: +(
			+hallBookingReceipt.fields
				?.find((f) => f.field.name === "Amount")
				?.value?.toString() ?? 0
		).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"38mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા.: ૦૧-૦૪-૧૯૬૭</Text>
								<Text color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box lineHeight="1.2" color={"blue.700"} fontWeight="500">
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={24} color={"blue.700"} fontWeight="500" pt="1">
							શ્રી મુળજીભાઈ બાવનજીભાઇ રાણપરીયા પાર્ટી – પ્લોટ
						</Box>
						<Box fontSize={14} color="gray.700" lineHeight={1} pt="1">
							સેટેલાઇટ પાર્ક, રણજીતસાગર રોડ, જામનગર
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={2}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				વાડી ભાડા ડિપોઝીટની પહોંચ
			</Box>
			<Flex justify={"space-between"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">પહોંચ નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.hallBookingReceiptNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName)}
					</BoldUnderlined>{" "}
					રહે.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactAddress)}
					</BoldUnderlined>{" "}
					તમોએ તારીખ{" "}
					<BoldUnderlined>
						{fields.startDate} થી {fields.endDate}
					</BoldUnderlined>{" "}
					સુધી <BoldUnderlined>{fields.location.name}</BoldUnderlined> ભાડે
					રાખેલ છે. જેના ડિપોઝિટના રૂ.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amount.toFixed(2)?.toString())}/-
					</BoldUnderlined>{" "}
					અંકે <BoldUnderlined>{toINRWords(fields.amount)}</BoldUnderlined> આજ
					રોજ {fields.paymentMode} થી મળેલ છે જેની આ પહોંચ આપવામાં આવે છે.
				</Text>
			</Box>
			<Box mt="10">
				<Flex justify={"space-between"}>
					<Box textAlign={"center"} mt="2">
						<Text>{[...Array(22).keys()].map(() => "_ ")}</Text>
						<Text as="span" fontWeight={"600"}>
							વાડીના તમામ નિયમો વાંચી સમજી સહી કરેલ છે
						</Text>
						<Text fontSize={14}>(નિયમો પાછળ આપેલ છે)</Text>
					</Box>
					<Box textAlign={"center"}>
						<Text fontWeight={"600"}>મેનેજર</Text>
						<Text fontWeight={"600"} lineHeight="1.3" pt="1">
							શ્રી વાસા વીરા લેઉવા પટેલ વાડી <br />
							જામનગર
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const PartyPlotBhadaDepositReceiptPrintView: React.FC<{
	hallBookingReceipt?: IHallBookingReceipt
}> = ({ hallBookingReceipt }) => {
	if (!hallBookingReceipt) return null

	var fields = {
		hallBookingReceiptNo: hallBookingReceipt.hallBookingReceiptNo,
		date: moment(hallBookingReceipt.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			hallBookingReceipt.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		contactAddress: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Contact address",
		)?.value,
		startDate: formatDate(
			hallBookingReceipt.fields?.find((f) => f.field.name === "Start date")?.value,
			{ gujarati: true },
		),
		endDate: formatDate(
			hallBookingReceipt.fields?.find((f) => f.field.name === "End date")?.value,
			{ gujarati: true },
		),
		location: hallBookingReceipt.fields?.find((f) => f.field.name === "Lobby / Area")
			?.value,
		description: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Description",
		)?.value,
		paymentMode: hallBookingReceipt.fields?.find(
			(f) => f.field.name === "Payment mode",
		)?.value,
		amount: +(
			+hallBookingReceipt.fields
				?.find((f) => f.field.name === "Amount")
				?.value?.toString() ?? 0
		).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા.: ૦૧-૦૪-૧૯૬૭</Text>
								<Text color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box lineHeight="1.2" color={"blue.700"} fontWeight="500">
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={24} color={"blue.700"} fontWeight="500" pt="1">
							શ્રી મુળજીભાઈ બાવનજીભાઇ રાણપરીયા પાર્ટી – પ્લોટ
						</Box>
						<Box fontSize={14} color="gray.700" lineHeight={1} pt="1">
							સેટેલાઇટ પાર્ક, રણજીતસાગર રોડ, જામનગર
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={2}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				પાર્ટી–પ્લોટ ભાડા ડિપોઝીટની પહોંચ
			</Box>
			<Flex justify={"space-between"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">પહોંચ નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.hallBookingReceiptNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName)}
					</BoldUnderlined>{" "}
					રહે.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactAddress)}
					</BoldUnderlined>{" "}
					તમોએ તારીખ{" "}
					<BoldUnderlined>
						{fields.startDate} થી {fields.endDate}
					</BoldUnderlined>{" "}
					સુધી <BoldUnderlined>{fields.location.name}</BoldUnderlined> ભાડે
					રાખેલ છે. જેના ડિપોઝિટના રૂ.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amount.toFixed(2)?.toString())}/-
					</BoldUnderlined>{" "}
					અંકે <BoldUnderlined>{toINRWords(fields.amount)}</BoldUnderlined> આજ
					રોજ {fields.paymentMode} થી મળેલ છે જેની આ પહોંચ આપવામાં આવે છે.
				</Text>
			</Box>
			<Box mt="10">
				<Flex justify={"space-between"}>
					<Box textAlign={"center"} mt="2">
						<Text>{[...Array(22).keys()].map(() => "_ ")}</Text>
						<Text as="span" fontWeight={"600"}>
							વાડીના તમામ નિયમો વાંચી સમજી સહી કરેલ છે
						</Text>
						<Text fontSize={14}>(નિયમો પાછળ આપેલ છે)</Text>
					</Box>
					<Box textAlign={"center"}>
						<Text fontWeight={"600"}>મેનેજર</Text>
						<Text fontWeight={"600"} lineHeight="1.3" pt="1">
							શ્રી મુળજીભાઈ બાવનજીભાઇ રાણપરીયા <br />
							પાર્ટી–પ્લોટ
						</Text>
					</Box>
				</Flex>
			</Box>
			{/* `<Box mt="6">
				<Text noOfLines={2} lineHeight="1.8">
					નોંધ: {[...Array(100).keys()].map(() => "_ ")}
				</Text>
			</Box>` */}
		</Box>
	)
}

export const LifetimeMemberFeesReceiptPrintView: React.FC<{ income?: IIncome }> = ({
	income,
}) => {
	if (!income) return null

	var fields = {
		incomeNo: income.incomeNo,
		date: moment(income.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			income.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		contactAddress: income.fields?.find((f) => f.field.name === "Contact address")
			?.value,
		paymentMode: income.fields?.find((f) => f.field.name === "Payment mode")?.value,
		amount: +(
			+income.fields?.find((f) => f.field.name === "Amount")?.value?.toString() ?? 0
		).toFixed(2),
		memberNo: income.fields?.find((f) => f.field.name === "Member no")?.value,
		mobileNo: income.fields?.find((f) => f.field.name === "Contact")?.value.mobile,
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"36mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા. : ૦૧-૦૪-૧૯૬૭</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box>
								<Text color={"blue.700"} fontWeight="600" fontSize={24}>
									કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ
								</Text>
							</Box>
						</Box>
						<Box fontSize={14} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={6}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				પહોંચ
			</Box>
			<Box fontWeight={"bold"}>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Text as="span">પહોંચ નંબર : </Text>
							<Text as="span">
								{getGujaratiString(fields.incomeNo?.toString())}
							</Text>
						</Box>
						<Box>
							<Text as="span">સભાસદ નંબર : </Text>
							<Text as="span">
								{getGujaratiString(fields.memberNo?.toString())}
							</Text>
						</Box>
						<Box>
							<Text as="span">મોબાઈલ નંબર : </Text>
							<Text as="span">
								{getGujaratiString(fields.mobileNo?.toString())}
							</Text>
						</Box>
					</Box>
					<Box>
						<Box>
							<Text as="span">તારીખ : </Text>
							<Text as="span">
								{getGujaratiString(fields.date?.toString())}
							</Text>
						</Box>
					</Box>
				</Flex>
			</Box>
			<Box mt="6">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName?.toString())}
					</BoldUnderlined>{" "}
					રહે.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactAddress?.toString())}
					</BoldUnderlined>{" "}
					તરફથી આજીવન સભ્ય ફી ના રૂ.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amount?.toString())}
					</BoldUnderlined>{" "}
					અંકે <BoldUnderlined>{toINRWords(fields.amount)}</BoldUnderlined>{" "}
					{getGujaratiString(fields.paymentMode?.toString())} થી મળેલ છે. જે
					આભારસહ સ્વીકારવામાં આવે છે.
				</Text>
			</Box>
			<Box mt="10">
				<Flex justify={"space-around"} textAlign={"center"}>
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"bold"}>
							નાણાં આપનારની સહી
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Text fontWeight={"bold"} lineHeight="1.3" pt="1">
							નાણાં લેનારની સહી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const OtherIncomeRecepitPrintView: React.FC<{ income?: IIncome }> = ({
	income,
}) => {
	if (!income) return null

	var fields = {
		incomeNo: income.incomeNo,
		date: moment(income.date).format("DD-MM-YYYY"),
		contact: getFullName(
			income.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: income.fields?.find((f) => f.field.name === "Description")?.value,
		amount: +(
			+income.fields?.find((f) => f.field.name === "Amount")?.value?.toString() ?? 0
		).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા. : ૦૧-૦૪-૧૯૬૭</Text>
								<Text color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box lineHeight="1.2" color={"blue.700"} fontWeight="500">
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={24} color={"blue.700"} fontWeight="500" pt="1.5">
							માતૃશ્રી રતનબેન માધવજી લેઉવા પટેલ કન્યા છાત્રાલય
						</Box>
						<Box fontSize={16} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">પહોંચ નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.incomeNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					શ્રી,{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contact?.toString())}
						{[...Array(99 - fields.contact?.toString().length).keys()].map(
							() => (
								<>&nbsp;</>
							),
						)}
					</BoldUnderlined>{" "}
					ખાતે જમા
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="20%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={3}>
								{getGujaratiString(fields.description?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			<Box mt="5">
				<Flex justify={"space-around"} align="flex-end">
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"600"}>
							હિસાબનીશ
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Box pb={9}>
							<Text textAlign={"right"}>ઉપર મુજબની આવક લેવામાં છે.</Text>
						</Box>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							મંત્રી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const OtherPaymentRecepitPrintView: React.FC<{ income?: IIncome }> = ({
	income,
}) => {
	if (!income) return null

	var fields = {
		incomeNo: income.incomeNo,
		date: moment(income.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			income.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: income.fields?.find((f) => f.field.name === "Description")?.value,
		paymentMode: income.fields?.find((f) => f.field.name === "Payment mode")?.value,
		amount: +(
			+income.fields?.find((f) => f.field.name === "Amount")?.value?.toString() ?? 0
		).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા. : ૦૧-૦૪-૧૯૬૭</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
						</Box>
						<Box fontSize={24} color={"blue.700"} fontWeight="500">
							શ્રી વાસા વીરા લેઉવા પટેલ વાડી
						</Box>
						<Box fontSize={16} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={6}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				પહોંચ
			</Box>
			<Flex justify={"space-between"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">પહોંચ નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.incomeNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="6">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName?.toString())}
					</BoldUnderlined>{" "}
					તરફથી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.description?.toString())}{" "}
					</BoldUnderlined>
					પેટે રૂપિયા{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amount.toFixed(2)?.toString())} (અંકે
						{toINRWords(fields.amount)})
					</BoldUnderlined>{" "}
					{getGujaratiString(fields.paymentMode?.toString())} થી મળેલ છે. જે
					આભારસહ સ્વીકારવામાં આવે છે.
				</Text>
			</Box>
			<Box mt={2}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="20%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={1}>
								{getGujaratiString(fields.description?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
						</Tr>
					</Tbody>
					<Tfoot>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ રૂ.
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
								fontWeight={"bold"}
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
			<Box mt="10">
				<Flex justify={"space-around"} textAlign={"center"}>
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"bold"}>
							નાણાં આપનારની સહી
						</Text>
					</Box>
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"bold"}>
							નાણાં લેનારની સહી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const ShopRentReceiptPrintView: React.FC<{ income?: IIncome }> = ({ income }) => {
	if (!income) return null

	var fields = {
		incomeNo: income.incomeNo,
		date: moment(income.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			income.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		contactAddress: income.fields?.find((f) => f.field.name === "Contact address")
			?.value,
		shop: income.fields?.find((f) => f.field.name === "Shop")?.value,
		year: income.fields?.find((f) => f.field.name === "Year")?.value,
		amountPerMonth: income.fields?.find((f) => f.field.name === "Amount per month")
			?.value,
		paymentMode: income.fields?.find((f) => f.field.name === "Payment mode")?.value,
		amount: +(+income.fields
			?.find((f) => f.field.name === "Amount")
			?.value?.toString()).toFixed(2),
	}

	let tempAmount = Math.round(fields.amount / 1.18).toFixed(2)
	let sgst = Math.round(+tempAmount * 0.09).toFixed(2)
	let cgst = Math.round(+tempAmount * 0.09).toFixed(2)
	let amount = fields.amount - +sgst - +cgst

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				{/* <Flex borderBottom={"2px solid #ddd"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા. : ૦૧-૦૪-૧૯૬૭</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box>
								<Text color={"blue.700"} fontWeight="600" fontSize={24}>
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
							</Box>
						</Box>
						<Box fontSize={16} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫ |ફોન નં. : ૦૨૮૮-૨૫૬૩૪૪૨
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={0}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				દુકાન ભાડાની પહોંચ
			</Box>
			<Box fontWeight={"bold"}>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Text as="span"> પહોંચ નંબર : </Text>
							<Text as="span">
								{getGujaratiString(fields.incomeNo?.toString())}
							</Text>
						</Box>
					</Box>
					<Box>
						<Box>
							<Text as="span">તારીખ : </Text>
							<Text as="span">
								{getGujaratiString(fields.date?.toString())}
							</Text>
						</Box>
					</Box>
				</Flex>
			</Box>
			<Box mt="4">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName?.toString())}
					</BoldUnderlined>{" "}
					રહે.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactAddress?.toString())}
					</BoldUnderlined>{" "}
					તમોએ અમારી સંસ્થાની દુકાન નં.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.shop?.blockNo?.toString())}
					</BoldUnderlined>{" "}
					ભાડે રાખેલ છે. તેનું પ્રતિ માસ રૂ.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amountPerMonth?.toString())} /-
					</BoldUnderlined>{" "}
					લેખે વર્ષ{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.year?.toString())}
					</BoldUnderlined>{" "}
					ના ભાડાના રૂ.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.amount?.toString())}
					</BoldUnderlined>{" "}
					અંકે રૂ.{" "}
					<BoldUnderlined>
						{toINRWords(+(+fields.amount).toFixed(0))}
					</BoldUnderlined>{" "}
					{getGujaratiString(fields.paymentMode?.toString())} થી મળેલ છે જેની આ
					પહોંચ આપવામાં આવે છે.
				</Text>
			</Box>
			{/* Receipt Items */}
			<Box mt="1">
				<Table
					style={{
						padding: "0px",
						border: "2px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								width="20%"
								textAlign={"center"}
							>
								ક્રમ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign="center"
							>
								વિગત
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								width="30%"
								textAlign="center"
							>
								રૂપિયા
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૧
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={0} pl={2}>
								ભાડાની રકમ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
							>
								{getGujaratiString(amount.toFixed(2)?.toString())}
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૨
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={0} pl={2}>
								એસ.જી.એસ.ટી.
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
							>
								{getGujaratiString(sgst?.toString())}
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૩
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={0} pl={2}>
								સી.જી.એસ.ટી.
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
							>
								{getGujaratiString(cgst?.toString())}
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								fontWeight={"bold"}
								padding={0}
								pr={2}
								isNumeric
								colSpan={2}
							>
								કુલ રકમ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								fontWeight={"bold"}
								isNumeric
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			{/* Receipt Signtuar */}
			<Box mt="5">
				<Flex justify={"space-between"}>
					<Box></Box>
					<Box textAlign={"center"}>
						<Text fontWeight={"600"}>મેનેજર</Text>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા <br />
							સમાજ – જામનગર
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const TrustFundReceiptPrintView: React.FC<{ income?: IIncome }> = ({ income }) => {
	if (!income) return null

	var fields = {
		incomeNo: income.incomeNo,
		date: moment(income.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			income.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		contactAddress: income.fields?.find((f) => f.field.name === "Contact address")
			?.value,
		description: income.fields?.find((f) => f.field.name === "Description")?.value,
		paymentMode: income.fields?.find((f) => f.field.name === "Payment mode")?.value,
		buildingFundAmount:
			+income.fields?.find((f) => f.field.name === "Building fund amount")?.value ??
			0,
		trustFundAmount:
			+income.fields?.find((f) => f.field.name === "Trust fund amount")?.value ?? 0,

		bankNote: income.fields?.find((f) => f.field.name === "Bank note")?.value,
	}

	let amount = (fields.buildingFundAmount + fields.trustFundAmount).toFixed(2)

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"36mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા. : ૦૧-૦૪-૧૯૬૭</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
							<Box>
								<Text color={"blue.700"} fontWeight="600" fontSize={24}>
									કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ
								</Text>
							</Box>
						</Box>
						<Box fontSize={16} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Box
				pt={1}
				fontWeight={"bold"}
				fontSize={"lg"}
				textAlign="center"
				textDecoration={"underline"}
			>
				પહોંચ
			</Box>
			<Box fontWeight={"bold"}>
				<Flex justify={"space-between"}>
					<Box>
						<Box>
							<Text as="span"> પહોંચ નંબર : </Text>
							<Text as="span">
								{getGujaratiString(fields.incomeNo?.toString())}
							</Text>
						</Box>
					</Box>
					<Box>
						<Box>
							<Text as="span">તારીખ : </Text>
							<Text as="span">
								{getGujaratiString(fields.date?.toString())}
							</Text>
						</Box>
					</Box>
				</Flex>
			</Box>
			<Box mt="2">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રી{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName?.toString())}
					</BoldUnderlined>{" "}
					રહે.{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactAddress?.toString())}
					</BoldUnderlined>{" "}
					આપના તરફથી નીચેની વિગતે અંકે રૂપિયા{" "}
					<BoldUnderlined>{toINRWords(+(+amount).toFixed(0))}</BoldUnderlined>{" "}
					<Text as="span">{fields.paymentMode}</Text>
					{fields.bankNote ? (
						<Text as="span">({fields.bankNote || "-"})</Text>
					) : null}{" "}
					થી મળેલ છે. જે આભારસહ સ્વીકારવામાં આવે છે.
				</Text>
			</Box>
			<Box mt={1}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="45%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={1}>
								ટ્રસ્ટ ફંડ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
								textAlign="center"
							>
								{getGujaratiString(
									fields.trustFundAmount.toFixed(2)?.toString(),
								)}
							</Td>
						</Tr>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={1}>
								બિલ્ડીંગ ફંડ
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								isNumeric
								textAlign="center"
							>
								{getGujaratiString(
									fields.buildingFundAmount.toFixed(2)?.toString(),
								)}
							</Td>
						</Tr>
					</Tbody>
					<Tfoot>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								textAlign="right"
								pr={1}
								fontWeight={"bold"}
							>
								કુલ રૂ.
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={1}
								fontWeight={"bold"}
								isNumeric
							>
								{getGujaratiString(amount?.toString())}
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
			<Box mt="12">
				<Flex justify={"space-around"} textAlign={"center"}>
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"bold"}>
							નાણાં આપનારની સહી
						</Text>
					</Box>
					<Box textAlign={"center"} mt="2">
						<Text as={"span"} fontWeight={"bold"}>
							નાણાં લેનારની સહી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const HostelVoucherPrintView: React.FC<{ expense?: IExpense }> = ({ expense }) => {
	if (!expense) return null

	var fields = {
		expenseNo: expense.expenseNo,
		date: moment(expense.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			expense.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: expense.fields?.find((f) => f.field.name === "Description")?.value,
		amount: +(+expense.fields
			?.find((f) => f.field.name === "Amount")
			?.value?.toString()).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box mb={1} height={"36mm"}>
				{/* <Flex borderBottom={"2px solid #444"} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-around"}>
								<Text fontSize={20} color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
							</Flex>
							<Box
								lineHeight="1.2"
								fontSize={16}
								color={"blue.700"}
								fontWeight="500"
							>
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={20} color={"black"} fontWeight="500" pt="1.5">
							માતૃશ્રી રતનબેન માધવજી લેઉવા પટેલ કન્યા છાત્રાલય
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">વાઉચર નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.expenseNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					શ્રી,{" "}
					<BoldUnderlined>
						{fields.contactName}
						{[
							...Array(99 - fields.contactName?.toString().length).keys(),
						].map(() => (
							<>&nbsp;</>
						))}
					</BoldUnderlined>{" "}
					ખર્ચ ખાતે ઉધાર
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={3.5}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="15%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="25%"
								fontSize={"sm"}
								textAlign="center"
							>
								નાણા લેનારની સહી
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={3}>
								{fields.description}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							></Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			<Box mt="5">
				<Flex justify={"space-around"} align="flex-end">
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"600"}>
							હિસાબનીશ
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Box pb={9}>
							<Text textAlign={"right"}>
								ઉપર મુજબનું ખર્ચ મંજુર કરવામાં આવે છે.
							</Text>
						</Box>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							મંત્રી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const PartyPlotVoucherPrintView: React.FC<{ expense?: IExpense }> = ({
	expense,
}) => {
	if (!expense) return null

	var fields = {
		expenseNo: expense.expenseNo,
		date: moment(expense.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			expense.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: expense.fields?.find((f) => f.field.name === "Description")?.value,
		amount: +(+expense.fields
			?.find((f) => f.field.name === "Amount")
			?.value?.toString()).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box mb={1}>
				<Flex borderBottom={"2px solid #444"} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-around"}>
								<Text fontSize={20} color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
							</Flex>
							<Box
								lineHeight="1.2"
								fontSize={16}
								color={"blue.700"}
								fontWeight="500"
							>
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={20} color={"black"} fontWeight="500" pt="1.5">
							શ્રી મુળજીભાઈ બાવનજીભાઇ રાણપરીયા પાર્ટી પ્લોટ
						</Box>
					</Box>
				</Flex>
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"} fontSize={"sm"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">વાઉચર નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.expenseNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					શ્રી,{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName?.toString())}
						{[
							...Array(99 - fields.contactName?.toString().length).keys(),
						].map(() => (
							<>&nbsp;</>
						))}
					</BoldUnderlined>{" "}
					ખર્ચ ખાતે ઉધાર
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={3.5}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="15%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="25%"
								fontSize={"sm"}
								textAlign="center"
							>
								નાણા લેનારની સહી
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={3}>
								{fields.description}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							></Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			<Box mt="5">
				<Flex justify={"space-around"} align="flex-end">
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"600"}>
							હિસાબનીશ
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Box pb={9}>
							<Text textAlign={"right"}>
								ઉપર મુજબનું ખર્ચ મંજુર કરવામાં આવે છે.
							</Text>
						</Box>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							મંત્રી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const VaadiVoucherPrintView: React.FC<{ expense?: IExpense }> = ({ expense }) => {
	if (!expense) return null

	var fields = {
		expenseNo: expense.expenseNo,
		date: moment(expense.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			expense.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: expense.fields?.find((f) => f.field.name === "Description")?.value,
		amount: +(+expense.fields
			?.find((f) => f.field.name === "Amount")
			?.value?.toString()).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box mb={1}>
				<Flex borderBottom={"2px solid #444"} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-around"}>
								<Text fontSize={20} color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
							</Flex>
							<Box
								lineHeight="1.2"
								fontSize={16}
								color={"blue.700"}
								fontWeight="500"
							>
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={20} color={"black"} fontWeight="500" pt="1.5">
							શ્રી વાસા વીરા લેઉવા પટેલ વાડી
						</Box>
					</Box>
				</Flex>
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"} fontSize={"sm"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">વાઉચર નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.expenseNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					શ્રી,{" "}
					<BoldUnderlined>
						{fields.contactName}
						{[
							...Array(99 - fields.contactName?.toString().length).keys(),
						].map(() => (
							<>&nbsp;</>
						))}
					</BoldUnderlined>{" "}
					ખર્ચ ખાતે ઉધાર
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={3.5}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="15%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="25%"
								fontSize={"sm"}
								textAlign="center"
							>
								નાણા લેનારની સહી
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={3}>
								{fields.description}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							></Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			<Box mt="5">
				<Flex justify={"space-around"} align="flex-end">
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"600"}>
							હિસાબનીશ
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Box pb={9}>
							<Text textAlign={"right"}>
								ઉપર મુજબનું ખર્ચ મંજુર કરવામાં આવે છે.
							</Text>
						</Box>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							મંત્રી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const SamajVoucherPrintView: React.FC<{ expense?: IExpense }> = ({ expense }) => {
	if (!expense) return null

	var fields = {
		expenseNo: expense.expenseNo,
		date: moment(expense.date).format("DD-MM-YYYY"),
		contactName: getFullName(
			expense.fields?.find((f) => f.field.name === "Contact")?.value,
		),
		description: expense.fields?.find((f) => f.field.name === "Description")?.value,
		amount: +(+expense.fields
			?.find((f) => f.field.name === "Amount")
			?.value?.toString()).toFixed(2),
	}

	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box mb={1} height={"36mm"}>
				{/* <Flex borderBottom={"2px solid #444"} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-around"}>
								<Text fontSize={20} color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ – જામનગર
								</Text>
							</Flex>
							<Box
								lineHeight="1.2"
								fontSize={16}
								color={"blue.700"}
								fontWeight="500"
							>
								સંચાલિત
							</Box>
						</Box>
						<Box fontSize={20} color={"black"} fontWeight="500" pt="0">
							શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"} fontSize={"sm"}>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">વાઉચર નંબર : </Text>
						<Text as="span">
							{getGujaratiString(fields.expenseNo?.toString())}
						</Text>
					</Box>
				</Box>
				<Box>
					<Box fontWeight={"bold"}>
						<Text as="span">તારીખ : </Text>
						<Text as="span">
							{getGujaratiString(fields.date?.toString())}
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					શ્રી,{" "}
					<BoldUnderlined>
						{getGujaratiString(fields.contactName)}
						{[
							...Array(99 - fields.contactName?.toString().length).keys(),
						].map(() => (
							<>&nbsp;</>
						))}
					</BoldUnderlined>{" "}
					ખર્ચ ખાતે ઉધાર
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={3.5}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								fontSize={"sm"}
								textAlign={"center"}
							>
								વિગત
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="15%"
								fontSize={"sm"}
								textAlign="center"
							>
								રૂપિયા
							</Th>
							<Th
								padding={3}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								width="25%"
								fontSize={"sm"}
								textAlign="center"
							>
								નાણા લેનારની સહી
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td style={{ border: "1px solid #555" }} padding={3}>
								{fields.description}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							>
								{getGujaratiString(fields.amount.toFixed(2)?.toString())}
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={3}
								textAlign="center"
							></Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
			<Box mt="5">
				<Flex justify={"space-around"} align="flex-end">
					<Box textAlign={"center"} mt="2">
						<Text as="span" fontWeight={"600"}>
							હિસાબનીશ
						</Text>
					</Box>
					<Box textAlign={"center"}>
						<Box pb={9}>
							<Text textAlign={"right"}>
								ઉપર મુજબનું ખર્ચ મંજુર કરવામાં આવે છે.
							</Text>
						</Box>
						<Text fontWeight={"600"} lineHeight="1.3" pt="2">
							મંત્રી
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

export const VaadiRentBillPrintView: React.FC = () => {
	return (
		<Box m={4}>
			{/* Receipt Header */}
			<Box height={"48mm"}>
				{/* <Flex borderBottom={"2px solid #444"} pb={2} align="center">
					<Box textAlign={"center"} flex={1}>
						<Box>
							<Flex justify={"space-between"}>
								<Text fontSize={14}>સ્થાપના તા: ૦૧-૦૪-૧૯૬૭</Text>
								<Text color={"blue.700"} fontWeight="500">
									શ્રી કેશવજી અરજણ લેઉવા પટેલ સેવા સમાજ - જામનગર
									<br /> સંચાલિત
								</Text>
								<Text fontSize={14}>પ. ટ્રસ્ટ રજી. નં. એ-૧૧૩૯</Text>
							</Flex>
						</Box>
						<Box fontSize={24} color={"blue.700"} fontWeight="500" pt="1">
							શ્રી વાસા વીરા લેઉવા પટેલ વાડી
						</Box>
						<Box fontSize={16} color="gray.700" lineHeight={1} pt="2">
							૧, શ્રી નિવાસ કોલોની, સરદાર પટેલ ચોક, રણજીતનગર, જામનગર -
							૩૬૧૦૦૫
						</Box>
					</Box>
				</Flex> */}
			</Box>
			{/* Receipt Body */}
			<Flex justify={"space-between"}>
				<Box>
					<Box>
						<Text as="span" fontWeight={"bold"}>
							નંબર :{" "}
						</Text>
						<Text as="span" fontWeight={"bold"}>
							૧
						</Text>
					</Box>
				</Box>
				<Box>
					<Box
						fontWeight={"bold"}
						fontSize={"lg"}
						textAlign="center"
						borderBottom={"2px solid #ccc"}
					>
						માલ – સામાન વપરાશની વિગત
					</Box>
				</Box>
				<Box>
					<Box>
						<Text as="span" fontWeight={"bold"}>
							તારીખ :{" "}
						</Text>
						<Text as="span" fontWeight={"bold"}>
							૧૫-૦૫-૨૦૨૨
						</Text>
					</Box>
				</Box>
			</Flex>
			<Box mt="4">
				<Text lineHeight={1.8}>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; શ્રીમાન,{" "}
					<BoldUnderlined>ઓધવજીભાઈ કે. હિરપરા</BoldUnderlined> આપશ્રીના{" "}
					<BoldUnderlined>દીકરીના લગ્ન</BoldUnderlined> શુભ પ્રસંગે તા.{" "}
					<BoldUnderlined>૧૪-૦૨-૨૦૨૧ થી ૧૫-૦૨-૨૦૨૧</BoldUnderlined> થી દિવસ{" "}
					<BoldUnderlined>૨</BoldUnderlined> માટે નીચેની વિગતે વાડી તથા વાસણ અને
					અન્ય સેવાઓનો વપરાશ થયેલ છે.
				</Text>
			</Box>
			<Box mt={4}>
				<Table
					style={{
						padding: "0px",
						border: "1px solid black",
					}}
				>
					<Thead>
						<Tr>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="10%"
								textAlign={"center"}
							>
								ક્રમ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								textAlign="center"
							>
								વિગત
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="5%"
								textAlign="center"
							>
								સંખ્યા
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="10%"
								textAlign="center"
							>
								ભાવ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="10%"
								textAlign="center"
							>
								દિવસ
							</Th>
							<Th
								padding={1}
								style={{
									textTransform: "none",
									border: "1px solid #555",
								}}
								color="black"
								backgroundColor={"gray.100"}
								fontSize={"sm"}
								width="15%"
								textAlign="center"
							>
								રૂપિયા
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={2}
								textAlign="center"
							>
								૧
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={2}>
								થાળી / ગ્લાસ - ૧૫ / ચમચા - ૫ / ભાતિયા - ૨ / ડોયા - ૩ / પા.
								વેલણ - ૧૦ / ગરણી - ૧
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૩૬
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૧.૦૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૨
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૭૨.૦૦
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૨
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={2}>
								ચમચી - ૨૦૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૨૦૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૦.૧૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૨
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૪૦.૦૦
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૩
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={2}>
								ડોલ - ૮ / કાથરોટ - ૧૦ / બાઉલ - ૧૫ / ખમણી - ૧ / જગ - ૧
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૩૫
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૩.૦૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૨
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૨૧૦.૦૦
							</Td>
						</Tr>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								textAlign="center"
							>
								૪
							</Td>
							<Td style={{ border: "1px solid #555" }} padding={2}>
								મી. ટોપ - ૭ / ટીન ટોપ મોટા - ૨ / એ. કોઠી - ૧ / એ. ટોપ - ૩
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૧૪
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૪૦.૦૦
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								pl={2}
								textAlign="center"
							>
								૨
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
							>
								૧૧૨૦.૦૦
							</Td>
						</Tr>
					</Tbody>
					<Tfoot>
						<Tr>
							<Td
								style={{ border: "1px solid #555" }}
								padding={2}
								colSpan={2}
							>
								<Flex justify={"space-between"} textAlign={"center"}>
									<Box>
										<Text as={"span"} fontWeight={"600"} pl={2}>
											વાસણ ભાડું :{" "}
										</Text>
										<Text as={"span"}>૨૩૧૮.૦૦</Text>
									</Box>
									<Box>
										<Text as={"span"} fontWeight={"600"}>
											પાગરણ ભાડું :{" "}
										</Text>
										<Text as={"span"} pr={8}>
											૧૧૬૦.૦૦
										</Text>
									</Box>
								</Flex>
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={2}
								isNumeric
								colSpan={3}
								pr={2}
								fontWeight={"600"}
							>
								કુલ સરવાળો
							</Td>
							<Td
								style={{ border: "1px solid #555" }}
								padding={0}
								isNumeric
								pr={2}
								fontWeight={"bold"}
							>
								૧૨૭૦૭.૦૦
							</Td>
						</Tr>
					</Tfoot>
				</Table>
			</Box>
			<Box mt={2}>
				<Text as={"span"} fontWeight={"600"} pl={2}>
					બિલની રકમ શબ્દોમાં :{" "}
				</Text>
				<Text as={"span"}>બાર હજાર સાત સો સાત રૂપિયા </Text>
			</Box>
			<Box mt={10}>
				<Flex justify={"space-between"}>
					<Box textAlign={"center"} mt="2">
						<Text>{[...Array(22).keys()].map(() => "_ ")}</Text>
						<Text as="span" fontWeight={"600"}>
							ભાડે રાખનારની સહી
						</Text>
					</Box>
					<Box textAlign={"center"} mt="2">
						<Text>{[...Array(22).keys()].map(() => "_ ")}</Text>
						<Text as="span" fontWeight={"600"}>
							મેનેજર
						</Text>
					</Box>
				</Flex>
			</Box>
		</Box>
	)
}

const ComponentHeading: React.FC = ({ children }) => {
	return (
		<Text
			fontSize={28}
			fontWeight="semibold"
			textAlign={"center"}
			color="white"
			backgroundColor={"gray.800"}
		>
			{children}
		</Text>
	)
}

const BoldUnderlined: React.FC = ({ children }) => {
	return (
		<Text as="span" fontWeight={"600"} textDecoration={"underline"}>
			{children}
		</Text>
	)
}

// const StrikeThrough: React.FC = ({ children }) => {
// 	return (
// 		<Text as="span" textDecoration={"line-through"}>
// 			{children}
// 		</Text>
// 	)
// }

const GujaratiText: React.FC = ({ children }) => {
	return (
		<Text fontFamily={"Noto Sans Gujarati"} fontSize={16}>
			{children}
		</Text>
	)
}
