import { shopAddApi } from "../../api/shop/shopAddApi"

type PayloadShape = Parameters<typeof shopAddApi>[0]

export async function shopAddService(payload: PayloadShape, token: string) {
	payload.rent = +payload.rent
	payload.gstPercentage = +payload.gstPercentage

	return await shopAddApi(payload, token)
}
