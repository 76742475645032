import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, SetStateAction } from "react"
import { FormDynamicField } from "src/components/shared/FormDynamicField"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IHallBookingReceipt, IHallBookingReceiptCategory } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IHallBookingReceiptUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	hallBookingReceipt: IHallBookingReceipt
	hallBookingReceiptCategoryList: IHallBookingReceiptCategory[]
	handleSubmit: FormikOnSubmit<IHallBookingReceiptUpdateFormFields>
	selectedHallBookingReceiptCategory?: IHallBookingReceiptCategory
	setSelectedHallBookingReceiptCategory: Dispatch<
		SetStateAction<IHallBookingReceiptCategory | undefined>
	>
}

export const HallBookingReceiptUpdateDrawerFormView: FC<Props> = ({
	hallBookingReceipt,
	handleSubmit,
	hallBookingReceiptCategoryList,
	selectedHallBookingReceiptCategory,
	setSelectedHallBookingReceiptCategory,
	...rest
}) => (
	<Formik<IHallBookingReceiptUpdateFormFields>
		initialValues={{
			categoryId: selectedHallBookingReceiptCategory?.id,
			date: hallBookingReceipt.date,
			incomeNo: hallBookingReceipt.hallBookingReceiptNo,
			fields:
				selectedHallBookingReceiptCategory?.fields
					?.sort((a, b) => a.position - b.position)
					?.map((field) => {
						const value = hallBookingReceipt.fields?.find(
							(f) => f.field.id === field.id,
						)?.value
						return {
							fieldId: field.id,
							value,
						}
					}) ?? [],
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const incomeCategoryOptions = hallBookingReceiptCategoryList.map(
				(hallBookingReceiptCategory) => ({
					label: hallBookingReceiptCategory.name,
					value: hallBookingReceiptCategory.id,
				}),
			)
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Hall Booking Receipt"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* IncomeCategory */}
							<FormControl>
								<InputLabel label="HallBookingCategory" />
								<ReactSelect
									name="categoryId"
									value={incomeCategoryOptions.find(
										(option) => option.value === values.categoryId,
									)}
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
										setSelectedHallBookingReceiptCategory(
											hallBookingReceiptCategoryList.find(
												(category) =>
													category.id ===
													(newValue as SelectOption).value,
											),
										)
									}}
									options={incomeCategoryOptions}
									isSearchable
								/>
							</FormControl>
							{/* HallBooking No. */}
							<FormControl>
								<InputLabel label="HallBooking No." />
								<Input
									name="hallBookingNo"
									maxLength={50}
									value={values.incomeNo}
									required
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="incomeNo"
								/>
							</FormControl>
						</Flex>
						<Flex gridRowGap={2} direction="column">
							{selectedHallBookingReceiptCategory?.fields
								?.sort((a, b) => a.position - b.position)
								?.map((field, i) => {
									return (
										<FormDynamicField
											key={field.id}
											field={field}
											value={values.fields?.[i]}
											controlName={`fields[${i}]`}
											handleChange={handleChange}
											setFieldValue={setFieldValue}
										/>
									)
								})}
						</Flex>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
