import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, Dispatch, FC, SetStateAction } from "react"
import { FormDynamicField } from "src/components/shared/FormDynamicField"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IExpense, IExpenseCategory } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IExpenseUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	expense: IExpense
	expenseCategoryList: IExpenseCategory[]
	handleSubmit: FormikOnSubmit<IExpenseUpdateFormFields>
	selectedExpenseCategory?: IExpenseCategory
	setSelectedExpenseCategory: Dispatch<SetStateAction<IExpenseCategory | undefined>>
}

export const ExpenseUpdateDrawerFormView: FC<Props> = ({
	expense,
	handleSubmit,
	expenseCategoryList,
	selectedExpenseCategory,
	setSelectedExpenseCategory,
	...rest
}) => (
	<Formik<IExpenseUpdateFormFields>
		initialValues={{
			categoryId: selectedExpenseCategory?.id,
			date: expense.date,
			expenseNo: expense.expenseNo,
			fields:
				selectedExpenseCategory?.fields
					?.sort((a, b) => a.position - b.position)
					?.map((field) => {
						const value = expense.fields?.find(
							(f) => f.field.id === field.id,
						)?.value
						return {
							fieldId: field.id,
							value,
						}
					}) ?? [],
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange, setFieldValue }) => {
			const expenseCategoryOptions = expenseCategoryList.map((expenseCategory) => ({
				label: expenseCategory.name,
				value: expenseCategory.id,
			}))
			return (
				<DrawerForm
					size="sm"
					headerLabel="Update Expense"
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"2xl"} marginX={"auto"} gridGap={2}>
						<Flex gridColumnGap={2}>
							{/* ExpenseCategory */}
							<FormControl>
								<InputLabel label="ExpenseCategory" />
								<ReactSelect
									name="categoryId"
									value={expenseCategoryOptions.find(
										(option) => option.value === values.categoryId,
									)}
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
										setSelectedExpenseCategory(
											expenseCategoryList.find(
												(category) =>
													category.id ===
													(newValue as SelectOption).value,
											),
										)
									}}
									options={expenseCategoryOptions}
									isSearchable
								/>
							</FormControl>
							{/* Expense No. */}
							<FormControl>
								<InputLabel label="Expense No." />
								<Input
									name="expenseNo"
									maxLength={50}
									value={values.expenseNo}
									required
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="expenseNo"
								/>
							</FormControl>
						</Flex>
						<Flex gridRowGap={2} direction="column">
							{selectedExpenseCategory?.fields
								?.sort((a, b) => a.position - b.position)
								?.map((field, i) => {
									return (
										<FormDynamicField
											key={field.id}
											field={field}
											value={values.fields?.[i]}
											controlName={`fields[${i}]`}
											handleChange={handleChange}
											setFieldValue={setFieldValue}
										/>
									)
								})}
						</Flex>
					</Stack>
				</DrawerForm>
			)
		}}
	</Formik>
)
