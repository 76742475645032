import { ComponentProps, FC, useCallback, useReducer } from "react"
import { IExpenseCategory } from "src/domain/entities"
import { expenseCategoryAddService } from "src/domain/services/expenseCategory/expenseCategoryAddService"
import { useAuth } from "src/hooks"
import { FieldType } from "src/utils/enums"
import { FormikOnSubmit } from "src/utils/types"
import { IExpenseCategoryAddFormFields, ExpenseCategoryAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ExpenseCategoryAddDrawerFormView>,
		"handleSubmit" | "fields" | "dispatchFields"
	> {
	onSuccess: (expenseCategory: IExpenseCategory) => void
}

export const ExpenseCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IExpenseCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				values.fields = values.fields.map((field, i) => ({
					name: field.name,
					type: field.type,
					position: i + 1,
				}))

				const expenseCategory = await expenseCategoryAddService(values, token)
				onSuccess(expenseCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	const reducer = (
		state: {
			name: string
			type: FieldType
			position: number
		}[],
		action: {
			type: string
			index: number
			payload: {
				name: string
				type: FieldType
				position: number
			}
		},
	) => {
		switch (action.type) {
			case "add":
				return [...state, action.payload]
			case "update":
				return state.map((item, i) => {
					if (i === action.index) return action.payload
					return item
				})
			case "remove":
				if (state.length === 1) return state
				return state.filter((_, i) => i !== action.index)
			default:
				return state
		}
	}
	const [fields, dispatchFields] = useReducer(reducer, [
		{
			name: "",
			type: FieldType.STRING,
			position: 1,
		},
	])

	return (
		<ExpenseCategoryAddDrawerFormView
			handleSubmit={handleSubmit}
			fields={fields}
			dispatchFields={dispatchFields}
			{...rest}
		/>
	)
}
