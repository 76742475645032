import { useCallback, useState } from "react"
import { IIncome } from "src/domain/entities"
import { incomeListService } from "src/domain/services/income/incomeListService"
import { useAuth } from ".."

export function useIncomeListService(): {
	incomeList: IIncome[]
	isLoading: boolean
	fetchIncomeList: (
		filter?: Parameters<typeof incomeListService>[0],
	) => Promise<IIncome[]>
} {
	const { token } = useAuth()
	const [incomeList, setIncomeList] = useState<IIncome[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchIncomeList = useCallback(
		async (filter: Parameters<typeof incomeListService>[0] = {}) => {
			setIsLoading(true)
			filter = {
				...filter,
				searchFields: [
					"Contact.firstName",
					"Contact.middleName",
					"Contact.lastName",
				],
				searchCategoryId: "bf0dbed2-f9d7-4aed-acc5-837e33488814",
			}
			const data = await incomeListService(filter, token)
			setIncomeList(data)
			setIsLoading(false)
			return data
		},
		[token],
	)

	return { incomeList, isLoading, fetchIncomeList }
}
