import { ComponentProps, FC, useCallback } from "react"
import { IItemCategory } from "src/domain/entities"
import { itemCategoryUpdateService } from "src/domain/services/itemCategory/itemCategoryUpdateService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IItemCategoryUpdateFormFields, ItemCategoryUpdateDrawerFormView } from "."

type Props = Omit<
	ComponentProps<typeof ItemCategoryUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: (itemCategory: IItemCategory) => void
}

export const ItemCategoryUpdateDrawerFormController: FC<Props> = ({
	itemCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IItemCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedItemCategory = await itemCategoryUpdateService(
					{ id: itemCategory.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedItemCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, itemCategory.id],
	)

	return (
		<ItemCategoryUpdateDrawerFormView
			itemCategory={itemCategory}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
