import { hallBookingReceiptDeleteApi } from "../../api/hallBookingReceipt/hallBookingReceiptDeleteApi"

type PayloadShape = Parameters<typeof hallBookingReceiptDeleteApi>[0]

export async function hallBookingReceiptDeleteService(
	payload: PayloadShape,
	token: string,
) {
	return await hallBookingReceiptDeleteApi(payload, token)
}
