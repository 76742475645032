import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

export async function hallBookingReceiptDeleteApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/income/delete/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	await requester.sendRequest(payload, token)
}
