import { IReceipt } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		receiptNo?: number
		receiptDate?: number
		totalAmount?: number
		paidAmount?: number
		studentId?: string
		receiptItems?: {
			itemId: string
			amount: number
		}[]
	}
}

export async function receiptUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/receipt/update/v1"
	const requester = new ApiRequester<RequestDataShape, IReceipt>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
