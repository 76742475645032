import { Box, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { ITenureAddFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<ITenureAddFormFields>
}

export const TenureAddDrawerFormView: FC<Props> = ({ handleSubmit, ...rest }) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<ITenureAddFormFields>
			initialValues={{
				term: {
					en: "",
					gu: "",
				},
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("term.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Tenure"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Term En */}
							<FormControl>
								<InputLabel label="Term En" />
								<Input
									name="term.en"
									placeholder="Term En"
									maxLength={50}
									required
									autoFocus
									value={values.term.en}
									onChange={handleNameChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="term.en"
								/>
							</FormControl>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("term.gu", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							{/* Term Gu */}
							<FormControl>
								<InputLabel label="Term Gu" />
								<Input
									name="term.gu"
									placeholder="Term Gu"
									maxLength={50}
									required
									value={values.term.gu}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="term.gu"
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
