import { IStudent } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	standardId?: string
	search?: string
	fetch?: { standard: boolean }
	pagination?: {
		page: number
		limit: number
	}
}

export async function studentListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/student/list/v1"
	const requester = new ApiRequester<RequestDataShape, IStudent[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
