import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	categoryId: string
}

interface ResponseDataShape {
	incomeNo: number
}

export async function incomeNumberGetApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/income/number/get"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	return await requester.sendRequest(payload, token)
}
