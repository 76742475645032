import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import { DashboardWrapper } from "../wrappers"

export const HomePage: FC = () => {
	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Box padding={2} borderBottom="1px" borderBottomColor="gray.300">
					<Flex justify={"space-between"}>
						<Box>
							<Box fontSize="̦2xl" fontWeight={"bold"} lineHeight={"1.2"}>
								Dashboard
							</Box>
							<Box color="gray.600">See overview of SKALPS here.</Box>
						</Box>
					</Flex>
				</Box>
				<Box padding={2}>Welcome Home !</Box>
			</Box>
		</DashboardWrapper>
	)
}
