import { IStandard } from "../../entities"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: string
		semester?: string
	}
}

export async function standardUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/standard/update/v1"
	const requester = new ApiRequester<RequestDataShape, IStandard>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
