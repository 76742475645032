import { ApiRequester } from "../ApiRequester"

interface ResponseDataShape {
	receiptNo: number
}

export async function receiptNumberGetApi(token: string) {
	const endpoint = "/receipt/number/get"
	const requester = new ApiRequester<{}, ResponseDataShape>(endpoint)
	return await requester.sendRequest({}, token)
}
