import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react"
import { FC, useRef } from "react"
import { BsPrinterFill } from "react-icons/bs"
import ReactToPrint from "react-to-print"
import { IHallBookingReceipt } from "src/domain/entities"
import { HallBookingReceiptPrintView } from "./HallBookingReceiptPrintView"

interface Props {
	hallBookingReceipt: IHallBookingReceipt
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
}

export const HallReceiptPreviewDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	hallBookingReceipt,
}) => {
	const onClose = () => setIsOpen(false)

	const printComponentRef = useRef<HTMLDivElement>(null)

	return (
		<>
			<Modal onClose={onClose} isOpen={isOpen} isCentered size={"3xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>HallReceipt Preview</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<HallBookingReceiptPrintView
							hallBookingReceipt={hallBookingReceipt}
						/>
					</ModalBody>
					<ModalFooter>
						<Flex gridColumnGap={2}>
							<Button onClick={onClose}>Close</Button>
							<div ref={printComponentRef} className="printable">
								<HallBookingReceiptPrintView
									hallBookingReceipt={hallBookingReceipt}
								/>
							</div>
							<ReactToPrint
								content={() => printComponentRef.current}
								copyStyles={true}
								trigger={() => (
									<Button
										colorScheme={"green"}
										leftIcon={<BsPrinterFill />}
									>
										Print
									</Button>
								)}
							/>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
