import { ComponentProps, FC, useCallback } from "react"
import { IContactCategory } from "src/domain/entities"
import { contactCategoryAddService } from "src/domain/services/contactCategory/contactCategoryAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IContactCategoryAddFormFields, ContactCategoryAddDrawerFormView } from "."

interface Props
	extends Omit<
		ComponentProps<typeof ContactCategoryAddDrawerFormView>,
		"handleSubmit"
	> {
	onSuccess: (contactCategory: IContactCategory) => void
}

export const ContactCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IContactCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const contactCategory = await contactCategoryAddService(values, token)
				onSuccess(contactCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <ContactCategoryAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
